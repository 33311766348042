import axios from 'axios';

const client = axios.create({
  timeout: 3000,
  headers: { 'X-SAMS-CLIENT-KEY': 'aaaaa' },
});

client.interceptors.request.use(
  (config) => {
    //
    if (localStorage.getItem('tokenSet')) {
      const tokenSet = JSON.parse(localStorage.getItem('tokenSet'));

      config.headers.Authorization = `${tokenSet.tokenType} ${tokenSet.accessToken}`;
    }
    return config;
  },
  (error) => {
    // 요청 에러 처리를 작성합니다.
    console.log(error);
    return Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //401 에러일 경우 로컬 스토리지 정리
    const error401 = '401';
    if (error.toString().indexOf(error401) != -1) {
      localStorage.setItem('tokenSet', '');
      localStorage.setItem('accountId', '');

      // 로그인 페이지가 아닌 경우 로그인 페이지로 이동
      if (window.location.pathname !== '/') {
        window.location.replace('/');
      }
    }
    return Promise.reject(error);
  },
);

export default client;
