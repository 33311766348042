import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';
//
export const findCourseList = ({ offset, limit, search }) => {
  const { totalCountYn, openingYear, openingTerm, courseNameKeyword, teacherName, userRole, userAccountId } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    openingYear: openingYear || undefined,
    openingTerm: openingTerm || undefined,
    courseNameKeyword: courseNameKeyword || undefined,
    teacherName: teacherName || undefined,
    userRole: userRole || undefined,
    userAccountId: userAccountId || undefined,
  });
  return client.get(`${api}/courses?${queryString}`);
};

export const findCourse = (courseId) => {
  if (courseId) {
    return client.get(`${api}/courses/${courseId}`);
  } else {
    return { data: {} };
  }
};

export const createCourse = ({ courseName, categoryName, courseSchedule, quota, totalLectures }) => {
  return client.post(`${api}/courses`, {
    courseName,
    categoryName,
    courseSchedule,
    quota,
    totalLectures,
  });
};

export const updateCourse = ({ courseId, courseName, categoryName, courseSchedule, quota, totalLectures }) => {
  return client.put(`${api}/courses/${courseId}`, {
    courseName,
    categoryName,
    courseSchedule,
    quota,
    totalLectures,
  });
};

export const assignTeacher = ({ courseId, teacherAccountId }) => {
  return client.put(`${api}/courses/${courseId}/teacher`, {
    teacherAccountId,
  });
};

export const deleteCourse = (courseId) => client.delete(`${api}/courses/${courseId}`);

export const confirmedCourse = ({ courseId, status }) => {
  return client.put(`${api}/courses/${courseId}/status`, {
    status,
  });
};
//
export const findLectureList = ({ offset, limit, courseId }) => {
  const queryString = qs.stringify({
    offset,
    limit,
    courseId,
  });
  return client.get(`${api}/lectureSchedules?${queryString}`);
};

export const findLecture = (lectureId) => {
  if (lectureId) {
    return client.get(`${api}/lectureSchedules/${lectureId}`);
  } else {
    return { data: {} };
  }
};

export const createLecture = ({ courseId, lectureDate, lectureWeek, lectureTime }) => {
  return client.post(`${api}/lectureSchedules`, {
    courseId,
    lectureDate,
    lectureWeek,
    lectureTime,
  });
};

export const updateLecture = ({
  lectureId,
  lectureDate,
  lectureWeek,
  lectureTime,
  cancelled,
  supplementaryLecture,
}) => {
  return client.put(`${api}/lectureSchedules/${lectureId}`, {
    lectureDate,
    lectureWeek,
    lectureTime,
    cancelled,
    supplementaryLecture: supplementaryLecture.lectureDate ? supplementaryLecture : null,
  });
};

export const deleteLecture = (lectureId) => {
  return client.delete(`${api}/lectureSchedules/${lectureId}`);
};
