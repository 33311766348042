import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import RollBookTabs from '../../containers/rollBook/RollBookTabs';
import RollBookCourseList from '../../containers/rollBook/RollBookCourseList';

const RollBookPage = () => {
  return (
    <PageWrap title={'출석부 관리'}>
      <RollBookCourseList size={7} />
      <RollBookTabs size={5}></RollBookTabs>
    </PageWrap>
  );
};

export default RollBookPage;
