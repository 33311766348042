import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTable, usePagination, useRowSelect } from 'react-table';
import { Table } from 'reactstrap';

import ReactPaginate from 'react-paginate';

const Styles = styled.div`
  //테이블
  table {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    th {
      text-align: center;
      background-color: #fafafa;
    }
    .c {
      text-align: center;
    }
    .selectionBox {
      text-align: center;
      width: 50px;
    }
    tbody {
      height: 490px !important;
    }
    th {
      height: 45px;
      padding: 0 5px;
      line-height: 45px;
    }
    td {
      height: 40px;
      padding: 0 5px;
      line-height: 40px;
    }
  }
  //페이징
  .pagination {
    display: flex;
    justify-content: center;

    .page-item.active .page-link {
      color: #fff;
      background-color: #666666;
      border-color: #666666;
    }
    .page-item {
      width: 42px;
      text-align: center;
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  },
);

// Create a default prop getter
const defaultPropGetter = () => ({});

const PagingDataTable = ({
  columns,
  data,
  useSelectionBox = false,
  pagination = false,
  handlePageClick,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  pageIndex,
  pageCount,
  selectRowIndex,
  selectRowDataCallBackFn,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      if (useSelectionBox) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            className: 'selectionBox',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </>
            ),
            Cell: ({ row }) => (
              <>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ]);
      }
    },
  );

  useEffect(() => {
    if (selectRowDataCallBackFn) {
      selectRowDataCallBackFn(selectedFlatRows.map((d) => d.original));
      console.log(selectedFlatRows.map((d) => d.original));
    }
  }, [selectedFlatRows]);

  return (
    <Styles>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: column.style,
                    },
                    getColumnProps(column),
                    getHeaderProps(column),
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: {
                            ...cell.column.style,
                            backgroundColor:
                              i === selectRowIndex ? '#e0e0e0' : '#ffffff',
                          },
                        },
                        getColumnProps(cell.column),
                        getCellProps(cell),
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      {pagination ? (
        <ReactPaginate
          pageCount={pageCount}
          forcePage={pageIndex}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          previousLabel={'<'}
          nextLabel={'>'}
          previousClassName={'page-item previous'}
          nextClassName={'page-item next'}
          previousLinkClassName={'page-link'}
          nextLinkClassName={'page-link'}
          breakLabel={'…'}
          breakClassName={'break-me'}
          pageClassName={'page-item'}
          pageLinkClassName={'page-link'}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          breakClassName={'page-item'}
          breakLinkClassName={'page-link'}
        />
      ) : null}
    </Styles>
  );
};

export default PagingDataTable;
