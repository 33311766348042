import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as kioskAPI from '../lib/api/kiosk';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const CHANGE_FIELD = 'kiosk/CHANGE_FIELD';

const [
  FIND_KIOSK,
  FIND_KIOSK_SUCCESS,
  FIND_KIOSK_FAILURE,
] = createRequestActionTypes('kiosk/FIND_KIOSK');

const [
  CREATE_KIOSK,
  CREATE_KIOSK_SUCCESS,
  CREATE_KIOSK_FAILURE,
] = createRequestActionTypes('kiosk/CREATE_KIOSK');

const [
  DELETE_KIOSK,
  DELETE_KIOSK_SUCCESS,
  DELETE_KIOSK_FAILURE,
] = createRequestActionTypes('kiosk/DELETE_KIOSK');

const [
  UPDATE_KIOSK,
  UPDATE_KIOSK_SUCCESS,
  UPDATE_KIOSK_FAILURE,
] = createRequestActionTypes('kiosk/UPDATE_KIOSK');

const CLEAR_KIOSK = 'kiosk/CLEAR_KIOSK'; // kiosk 데이터 비우기
const SET_CHANGED_TIME = 'kiosk/SET_CHANGED_TIME'; // 상세 데이타 변경

export const changeField = createAction(CHANGE_FIELD, (kiosk) => kiosk);

export const findKiosk = createAction(
  FIND_KIOSK,
  (kioskDeviceId) => kioskDeviceId,
);
export const createKiosk = createAction(CREATE_KIOSK, (kiosk) => kiosk);
export const deleteKiosk = createAction(
  DELETE_KIOSK,
  (kioskDeviceId) => kioskDeviceId,
);
export const editKiosk = createAction(UPDATE_KIOSK, (kiosk) => {
  return {
    kioskDeviceId: kiosk.kioskDeviceId,
    managedName: kiosk.managedName,
    password: kiosk.password,
    locked: kiosk.locked,
    kioskNotice: { ...kiosk.kioskNotice },
  };
});
export const clearKiosk = createAction(CLEAR_KIOSK);
export const setChangedTime = createAction(SET_CHANGED_TIME);

// saga 생성
const findKioskSaga = createRequestSaga(FIND_KIOSK, kioskAPI.findKiosk);
const createKioskSaga = createRequestSaga(CREATE_KIOSK, kioskAPI.createKiosk);
const updateKioskSaga = createRequestSaga(UPDATE_KIOSK, kioskAPI.updateKiosk);
const deleteKioskSaga = createRequestSaga(DELETE_KIOSK, kioskAPI.deleteKiosk);

export function* kioskSaga() {
  yield takeLatest(FIND_KIOSK, findKioskSaga);
  yield takeLatest(CREATE_KIOSK, createKioskSaga);
  yield takeLatest(UPDATE_KIOSK, updateKioskSaga);
  yield takeLatest(DELETE_KIOSK, deleteKioskSaga);
}

const initialState = {
  kioskDeviceId: '',
  managedName: '',
  modelName: '',
  serialNo: '',
  heartbeatTime: null,
  uptime: null,
  password: '',
  locked: false,
  kioskNotice: {
    subject: '',
    content: '',
    applied: false,
  },
  error: null,
  changedTime: new Date(),
};

const kiosk = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_KIOSK_SUCCESS]: (state, { payload: kiosk }) => ({
      ...state,
      ...kiosk,
    }),

    [FIND_KIOSK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CREATE_KIOSK_SUCCESS]: (state, { payload: data }) => {
      return { ...state, kioskDeviceId: data.kioskDeviceId };
    },

    [CREATE_KIOSK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [UPDATE_KIOSK_SUCCESS]: (state, { payload: data }) => {
      return { ...state };
    },

    [UPDATE_KIOSK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [DELETE_KIOSK_SUCCESS]: (state, { payload: data }) => ({
      ...state,
    }),

    [DELETE_KIOSK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [CLEAR_KIOSK]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default kiosk;
