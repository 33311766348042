import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseAPI from '../lib/api/course';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const [
  FIND_COURSE_SELECT_LIST,
  FIND_COURSE_SELECT_LIST_SUCCESS,
  FIND_COURSE_SELECT_LIST_FAILURE,
] = createRequestActionTypes('coursesSelect/FIND_COURSE_LIST');

const CHANGE_FIELD = 'coursesSelect/CHANGE_FIELD';
const CLEAR_COURSE_SELECT_LIST = createRequestActionTypes(
  'coursesSelect/CLEAR_COURSE_SELECT_LIST',
);

//const SET_SELETED_DATAS = 'coursesSelect/SET_SELETED_DATAS';

export const findCourseSelectList = createAction(
  FIND_COURSE_SELECT_LIST,
  (offset, limit, search) => {
    return { offset, limit, search };
  },
);

export const changeField = createAction(CHANGE_FIELD, (courses) => courses);
export const clearCourseSelectList = createAction(CLEAR_COURSE_SELECT_LIST);
//export const setSelectedDatas = createAction(SET_SELETED_DATAS, (data) => data);

// saga 생성
const listCoursesSelectSaga = createRequestSaga(
  FIND_COURSE_SELECT_LIST,
  courseAPI.findCourseList,
);

export function* coursesSelectSaga() {
  yield takeLatest(FIND_COURSE_SELECT_LIST, listCoursesSelectSaga);
}

const initialState = {
  courses: [],
  error: null,
  offset: 0,
  limit: 1000,
  search: {
    openingYear: new Date().getFullYear(),
    openingTerm: 'FirstHalf',
    totalCountYn: 'N',
  },
  selectedDatas: { courseId: '' },
};

const coursesSelect = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_COURSE_SELECT_LIST_SUCCESS]: (state, { payload: courses }) => {
      let selectCourseId = '';
      if (courses.length > 0) {
        selectCourseId = courses[0].courseId;
      }
      return {
        ...state,
        courses: courses,
        selectedDatas: { courseId: selectCourseId },
      };
    },

    [FIND_COURSE_SELECT_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_COURSE_SELECT_LIST]: () => initialState,
  },
  initialState,
);

export default coursesSelect;
