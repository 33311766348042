import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as userAPI from '../lib/api/user';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import createStateSaga from '../lib/createRequestSaga';
//

const [FIND_USER_LIST, FIND_USER_LIST_SUCCESS, FIND_USER_LIST_FAILURE] = createRequestActionTypes(
  'users/FIND_USER_LIST',
);

const CHANGE_FIELD = 'users/CHANGE_FIELD';
const CLEAR_USER_LIST = createRequestActionTypes('users/CLEAR_USER_LIST');

const SET_OFFSET = 'users/SET_OFFSET';
const SET_INIT_SEARCH_VALUE = 'users/SET_INIT_SEARCH_VALUE';
const SET_SELETED_ROW_DATAS = 'users/SET_SELETED_ROW_DATAS';

export const findUserList = createAction(FIND_USER_LIST, (offset, limit, search) => {
  return { offset, limit, search };
});

export const changeField = createAction(CHANGE_FIELD, (users) => users);
export const clearUserList = createAction(CLEAR_USER_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setInitSearchValue = createAction(SET_INIT_SEARCH_VALUE);
export const setSelectedRowDatas = createAction(SET_SELETED_ROW_DATAS, (row) => row);

// saga 생성
const listUsersSaga = createRequestSaga(FIND_USER_LIST, userAPI.findUserList);

export function* usersSaga() {
  yield takeLatest(FIND_USER_LIST, listUsersSaga);
}

const initialState = {
  users: [],
  error: null,
  offset: 0,
  limit: 10,
  totalCount: 0,
  search: {
    userName: '',
    loginId: '',
    role: '',
    status: '',
    phoneNo: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const users = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_USER_LIST_SUCCESS]: (state, { payload: users, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (users.length !== state.limit) {
        let addRowCount = state.limit - users.length;
        for (let i = 0; i < addRowCount; i++) {
          users.push({});
        }
      }

      return { ...state, users: users, selectedRowDatas: {} };
    },

    [FIND_USER_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_USER_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),
    [SET_INIT_SEARCH_VALUE]: (state, { payload: initSearchValue }) => {
      return { ...state, search: { ...state.search, ...initSearchValue } };
    },
    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          userAccountId: row.values['accountId'],
          userName: row.values['userDetail.userName'],
          birthDate: row.values['userDetail.birthDate'],
          gender: row.values['userDetail.gender'],
        },
      };
    },
  },
  initialState,
);

export default users;
