import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';
//
export const findPostList = ({ offset, limit, search }) => {
  const { totalCountYn, boardId, boardIds, writerName, subjectKeyword } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    boardId: boardId || undefined,
    boardIds: boardIds ? boardIds.join(',') : undefined,
    writerName: writerName || undefined,
    subjectKeyword: subjectKeyword || undefined,
  });
  if (!boardId && !boardIds?.length) {
    throw new Error('No boardId provided');
  }
  return client.get(`${api}/posts?${queryString}`);
};

export const findPost = (postId) => {
  //
  if (postId) {
    return client.get(`${api}/posts/${postId}`);
  } else {
    return {};
  }
};

export const findCommentList = ({ offset, limit, search }) => {
  const { totalCountYn, postId } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    postId: postId,
  });
  return client.get(`${api}/comments?${queryString}`);
};

export const findBoardList = ({ offset, limit, search }) => {
  const { totalCountYn, openingYear, openingTerm } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    openingYear: openingYear,
    openingTerm: openingTerm,
  });
  return client.get(`${api}/courseBoards?${queryString}`);
};
