const getSerachOpeningYearOptions = () => {
  const thisYear = new Date().getFullYear();
  let openingYearOptions = [];
  for (let i = 0; i <= thisYear + 1 - 2020; i++) {
    openingYearOptions.push(2020 + i);
  }
  return openingYearOptions;
};

const getCreateOpeningYearOptions = () => {
  const thisYear = new Date().getFullYear();
  let openingYearOptions = [];
  openingYearOptions.push(thisYear);
  openingYearOptions.push(thisYear + 1);

  return openingYearOptions;
};

const getCurrentYear = () => {
  return new Date().getFullYear();
};

const getCurrentTerm = () => {
  const month = new Date().getMonth() + 1;
  if (month <= 6) {
    return 'FirstHalf';
  } else {
    return 'SecondHalf';
  }
};

const fileDownload = (res) => {
  const filename = decodeURI(
    res.headers['content-disposition']
      .substr(res.headers['content-disposition'].indexOf('filename=') + 9)
      .replace(';', ''),
  );
  const url = window.URL.createObjectURL(new Blob([res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
};

const isNumber = (num) => {
  if (num !== undefined && num !== null) { // TODO:
    return true;
  }
  return false;
}

export { getSerachOpeningYearOptions, getCreateOpeningYearOptions, getCurrentYear, getCurrentTerm, fileDownload, isNumber };
