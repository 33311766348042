import { call, put } from 'redux-saga/effects';
import {
  startLoading,
  finishLoading,
  failureFinishLoading,
  successFinishLoading,
} from '../modules/loading';
import { putResolve } from '@redux-saga/core/effects';

export const createRequestActionTypes = (type) => {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;
  return [type, SUCCESS, FAILURE];
};

export default function createRequestSaga(type, request) {
  const SUCCESS = `${type}_SUCCESS`;
  const FAILURE = `${type}_FAILURE`;

  return function* (action) {
    yield put(startLoading(type)); // 로딩 시작
    try {
      const response = yield call(request, action.payload);
      yield putResolve({
        type: SUCCESS,
        payload: response.data,
        meta: response,
      });
      yield put(successFinishLoading(type)); // 로딩 끝
    } catch (e) {
      yield putResolve({
        type: FAILURE,
        payload: e,
        error: true,
      });
      yield put(failureFinishLoading(type)); // 로딩 끝
    }
  };
}
