import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseAPI from '../lib/api/course';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const [
  FIND_LECTURE_LIST,
  FIND_LECTURE_LIST_SUCCESS,
  FIND_LECTURE_LIST_FAILURE,
] = createRequestActionTypes('lectures/FIND_LECTURE_LIST');

const CHANGE_FIELD = 'lectures/CHANGE_FIELD';
const CLEAR_LECTURE_LIST = createRequestActionTypes(
  'lectures/CLEAR_LECTURE_LIST',
);

const SET_SELETED_ROW_DATAS = 'lectures/SET_SELETED_ROW_DATAS';

export const findLectureList = createAction(
  FIND_LECTURE_LIST,
  (offset, limit, courseId) => {
    return { offset, limit, courseId };
  },
);

export const clearLectureList = createAction(CLEAR_LECTURE_LIST);
export const setSelectedRowDatas = createAction(
  SET_SELETED_ROW_DATAS,
  (row) => row,
);

// saga 생성
const listLecturesSaga = createRequestSaga(
  FIND_LECTURE_LIST,
  courseAPI.findLectureList,
);

export function* lecturesSaga() {
  yield takeLatest(FIND_LECTURE_LIST, listLecturesSaga);
}

const initialState = {
  lectures: [],
  error: null,
  offset: 0,
  limit: 1000,
  totalCount: 0,
  search: {
    openingYear: new Date().getFullYear(),
    openingTerm: '',
    courseNameKeyword: '',
    teacherName: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const lectures = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_LECTURE_LIST_SUCCESS]: (state, { payload: lectures }) => {
      return {
        ...state,
        lectures: lectures,
        totalCount: lectures.length,
        selectedRowDatas: {},
      };
    },

    [FIND_LECTURE_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_LECTURE_LIST]: () => initialState,

    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: { lectureId: row.values['lectureId'] },
      };
    },
  },
  initialState,
);

export default lectures;
