import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as enrollmentAPI from '../lib/api/enrollment';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import { format } from 'date-fns';
//

const CHANGE_FIELD = 'enrollment/CHANGE_FIELD';

const [
  FIND_ENROLLMENT,
  FIND_ENROLLMENT_SUCCESS,
  FIND_ENROLLMENT_FAILURE,
] = createRequestActionTypes('enrollment/FIND_ENROLLMENT');
const [
  CREATE_ENROLLMENT,
  CREATE_ENROLLMENT_SUCCESS,
  CREATE_ENROLLMENT_FAILURE,
] = createRequestActionTypes('enrollment/CREATE_ENROLLMENT');
const [
  DELETE_ENROLLMENT,
  DELETE_ENROLLMENT_SUCCESS,
  DELETE_ENROLLMENT_FAILURE,
] = createRequestActionTypes('enrollment/DELETE_ENROLLMENT');
const [
  UPDATE_ENROLLMENT,
  UPDATE_ENROLLMENT_SUCCESS,
  UPDATE_ENROLLMENT_FAILURE,
] = createRequestActionTypes('enrollment/UPDATE_ENROLLMENT');

const CLEAR_ENROLLMENT = 'enrollment/CLEAR_ENROLLMENT'; // enrollment 데이터 비우기
const SET_CHANGED_TIME = 'enrollment/SET_CHANGED_TIME'; // 상세 데이타 변경
const SET_COURSE_ID = 'enrollment/SET_COURSE_ID'; //신규등록시 courseId 저장용도
const SET_STUDENT = 'enrollment/SET_STUDENT'; // 수강생 선택

export const changeField = createAction(
  CHANGE_FIELD,
  (enrollment) => enrollment,
);
export const findEnrollment = createAction(
  FIND_ENROLLMENT,
  (studentId) => studentId,
);
export const createEnrollment = createAction(
  CREATE_ENROLLMENT,
  (enrollment) => enrollment,
);
export const deleteEnrollment = createAction(
  DELETE_ENROLLMENT,
  (studentId) => studentId,
);
export const editEnrollment = createAction(UPDATE_ENROLLMENT, (enrollment) => {
  return {
    ...enrollment,
  };
});

export const clearEnrollment = createAction(CLEAR_ENROLLMENT);
export const setChangedTime = createAction(SET_CHANGED_TIME);
export const setCourseId = createAction(SET_COURSE_ID);
export const setStudent = createAction(SET_STUDENT, (data) => data);

// saga 생성
const findEnrollmentSaga = createRequestSaga(
  FIND_ENROLLMENT,
  enrollmentAPI.findEnrollment,
);
const createEnrollmentSaga = createRequestSaga(
  CREATE_ENROLLMENT,
  enrollmentAPI.createEnrollment,
);
const updateEnrollmentSaga = createRequestSaga(
  UPDATE_ENROLLMENT,
  enrollmentAPI.updateEnrollment,
);
const deleteEnrollmentSaga = createRequestSaga(
  DELETE_ENROLLMENT,
  enrollmentAPI.deleteEnrollment,
);

export function* enrollmentSaga() {
  yield takeLatest(FIND_ENROLLMENT, findEnrollmentSaga);
  yield takeLatest(CREATE_ENROLLMENT, createEnrollmentSaga);
  yield takeLatest(UPDATE_ENROLLMENT, updateEnrollmentSaga);
  yield takeLatest(DELETE_ENROLLMENT, deleteEnrollmentSaga);
}

const initialState = {
  studentId: '',
  userAccountId: '',
  courseId: '',
  userName: '',
  birthDate: '',
  gender: '',
  registrationDate: '',
  registrationPath: '',
  changedTime: new Date(),
};

const enrollment = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_ENROLLMENT_SUCCESS]: (state, { payload: enrollment }) => {
      return { ...state, ...enrollment };
    },

    [FIND_ENROLLMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CREATE_ENROLLMENT_SUCCESS]: (state, { payload: data }) => {
      return { ...state, studentId: data.value };
    },

    [CREATE_ENROLLMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [UPDATE_ENROLLMENT_SUCCESS]: (state, { payload: data }) => {
      return {
        ...state,
      };
    },

    [UPDATE_ENROLLMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [DELETE_ENROLLMENT_SUCCESS]: (state, { payload: data }) => ({
      ...state,
      enrollmentId: '',
    }),

    [DELETE_ENROLLMENT_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [SET_COURSE_ID]: (state, { payload: courseId }) => ({
      ...state,
      courseId: courseId,
    }),

    [SET_STUDENT]: (state, { payload: data }) => {
      const { userAccountId, userName, birthDate, gender } = data;

      return { ...state, userAccountId, userName, birthDate, gender };
    },

    [CLEAR_ENROLLMENT]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default enrollment;
