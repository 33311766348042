import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createKiosk,
  editKiosk,
  deleteKiosk,
  clearKiosk,
  changeField,
  findKiosk,
  setChangedTime,
} from '../../modules/kiosk';
import { Col } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { fromJS } from 'immutable';
import { useAlert } from 'react-alert';
import ContentWrap from '../../components/layout/ContentWrap';
import { clearLoading } from '../../modules/loading';

const KioskForm = ({ history, size }) => {
  const dispatch = useDispatch();
  const { kiosk, kiosks, error, createKioskLoading, deleteKioskLoading, updateKioskLoading } = useSelector(
    ({ kiosk, kiosks, error, loading }) => ({
      kiosk: kiosk,
      kiosks: kiosks,
      error: kiosk.error,
      createKioskLoading: loading['kiosk/CREATE_KIOSK'],
      deleteKioskLoading: loading['kiosk/DELETE_KIOSK'],
      updateKioskLoading: loading['kiosk/UPDATE_KIOSK'],
    }),
  );

  const alert = useAlert();
  //NEW_TYPE : 신규(초기화, 신규)
  //DETAIL_TYPE:상세(삭제, 수정)
  //EDIT_TYPE: 수정(취소, 저장)
  const [formType, setFormType] = useState('NEW_TYPE');

  useEffect(() => {
    return () => {
      dispatch(clearKiosk());
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (kiosks.selectedRowDatas.kioskDeviceId) {
      dispatch(findKiosk(kiosks.selectedRowDatas.kioskDeviceId));
      setFormType('DETAIL_TYPE');
    } else {
      dispatch(clearKiosk());
      setFormType('NEW_TYPE');
    }
  }, [kiosks.selectedRowDatas]);

  useEffect(() => {
    if (createKioskLoading && createKioskLoading.completed) {
      if (kiosk.kioskDeviceId) {
        alert.success('키오스크를 신규 등록 하였습니다.');
        setFormType('DETAIL_TYPE');
        dispatch(setChangedTime(new Date()));
      } else {
        alert.error('키오스크 등록에 실패하였습니다.');
      }

      dispatch(clearLoading(createKioskLoading.type));
    }
  }, [createKioskLoading]);

  useEffect(() => {
    if (updateKioskLoading && updateKioskLoading.completed) {
      alert.success('키오스크정보를 수정 하였습니다.');
      setFormType('DETAIL_TYPE');
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(updateKioskLoading.type));
    }
  }, [updateKioskLoading]);

  useEffect(() => {
    if (deleteKioskLoading && deleteKioskLoading.completed) {
      alert.success('키오스크을 삭제 하였습니다.');
      dispatch(clearKiosk());
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(deleteKioskLoading.type));
    }
  }, [deleteKioskLoading]);

  useEffect(() => {
    console.log(formType);
  }, [formType]);

  const clearBtn = {
    label: '초기화',
    type: 'secondary',
    onClickFn: () => {
      dispatch(clearKiosk());
    },
  };
  const createBtn = {
    label: '신규등록',
    type: 'success',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('등록하시겠습니까?')) {
        checkForm(0, async () => {
          dispatch(await createKiosk(kiosk));
        });
      }
    },
  };
  const deleteBtn = {
    label: '삭제',
    type: 'danger',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('삭제하시겠습니까?')) {
        dispatch(deleteKiosk(kiosk.kioskDeviceId));
      }
    },
  };
  const editBtn = {
    label: '수정',
    type: 'info',
    onClickFn: (e) => {
      e.preventDefault();
      setFormType('EDIT_TYPE');
    },
  };
  const cancelBtn = {
    label: '취소',
    type: 'secondary',
    onClickFn: (e) => {
      e.preventDefault();
      setFormType('DETAIL_TYPE');
      dispatch(findKiosk(kiosks.selectedRowDatas.kioskDeviceId));
    },
  };
  const saveBtn = {
    label: '저장',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('저장하시겠습니까?')) {
        checkForm(1, async () => {
          dispatch(await editKiosk(kiosk));
        });
      }
    },
  };

  const btnData = {
    isHistoryBack: false,
    NEW_TYPE: [clearBtn, createBtn],
    DETAIL_TYPE: [deleteBtn, editBtn],
    EDIT_TYPE: [cancelBtn, saveBtn],
  };

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '키오스크등록',
        onClickFn: (e) => {
          e.preventDefault();
          dispatch(clearKiosk());
          setFormType('NEW_TYPE');
        },
      },
    ],
  };

  const checkForm = (type, callbackFn) => {
    //
    if (!kiosk.managedName) {
      alert.error('명칭은 필수값 입니다.');
      return false;
    }

    if (!kiosk.modelName) {
      alert.error('모델명은 필수값 입니다.');
      return false;
    }

    if (!kiosk.serialNo) {
      alert.error('시리얼번호는 필수값 입니다.');
      return false;
    }

    if (!kiosk.password) {
      alert.error('비밀번호는 필수값 입니다.');
      return false;
    }

    if (!kiosk.password) {
      alert.error('비밀번호는 필수값 입니다.');
      return false;
    }

    callbackFn();
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newKiosk = fromJS(kiosk).setIn(names, value);

    dispatch(changeField(newKiosk.toJS()));
  };

  return (
    <ContentWrap
      size={size}
      title={
        formType === 'NEW_TYPE'
          ? '키오스크 등록'
          : formType === 'DETAIL_TYPE'
          ? '키오스크 상세'
          : formType === 'EDIT_TYPE'
          ? '키오스크 수정'
          : null
      }
      buttonDatas={formType !== 'NEW_TYPE' ? topButtonData : null}
    >
      <FormWrap ClassName={'formWrap'} form={kiosk}>
        <FormGroup row>
          <Label for="managedName" sm={3}>
            명칭
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="managedName"
              id="managedName"
              placeholder="명칭을 입력하세요."
              value={kiosk.managedName}
              onChange={onChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="modelName" sm={3}>
            모델명
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'NEW_TYPE' ? null : 'disabled'}
              type="text"
              name="modelName"
              id="modelName"
              placeholder="모델명을 입력하세요."
              value={kiosk.modelName}
              onChange={onChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="serialNo" sm={3}>
            시리얼번호
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'NEW_TYPE' ? null : 'disabled'}
              type="text"
              name="serialNo"
              id="serialNo"
              placeholder="시리얼번호을 입력하세요."
              value={kiosk.serialNo}
              onChange={onChange}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="password" sm={3}>
            비밀번호
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="password"
              name="password"
              id="password"
              placeholder="비밀번호를 입력하세요."
              value={kiosk.password}
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        {formType !== 'NEW_TYPE' ? (
          <>
            <FormGroup row>
              <Label for="locked" sm={3}>
                화면잠금
              </Label>
              <Col sm={9}>
                <Input
                  disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
                  type="select"
                  name="locked"
                  id="locked"
                  value={kiosk.locked}
                  onChange={onChange}
                >
                  <option value={true}>잠금 활성화</option>
                  <option value={false}>잠금 비활성화</option>
                </Input>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label for="noticeSubject" sm={3}>
                공지사항
              </Label>
              <Col sm={9}>
                <Input
                  disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
                  type="text"
                  name="kioskNotice.subject"
                  id="noticeSubject"
                  placeholder="공지사항 제목을 입력하세요."
                  value={kiosk.kioskNotice.subject}
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ size: 9, offset: 3 }}>
                <Input
                  disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
                  type="textarea"
                  name="kioskNotice.content"
                  id="noticeContent"
                  rows={5}
                  placeholder="공지사항 내용을 입력하세요."
                  value={kiosk.kioskNotice.content}
                  onChange={onChange}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={{ size: 9, offset: 3 }}>
                <Input
                  disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
                  type="select"
                  name="kioskNotice.applied"
                  id="noticeApplied"
                  value={kiosk.kioskNotice.applied}
                  onChange={onChange}
                >
                  <option value={true}>공지사항 적용</option>
                  <option value={false}>공지사항 미적용</option>
                </Input>
              </Col>
            </FormGroup>
          </>
        ) : null}
      </FormWrap>
      <FooterButtonGroup
        buttonData={{
          isHistoryBack: btnData.isHistoryBack,
          data: btnData[formType],
        }}
        history={history}
      ></FooterButtonGroup>
    </ContentWrap>
  );
};

export default KioskForm;
