import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { saveTimeRecord, setChangedTime, changeField, findTimeRecord, clearTimeRecord } from '../../modules/timeRecord';
import { Col } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { fromJS } from 'immutable';
import { useAlert } from 'react-alert';
import { colonStyleTime, formatDate, formatTimeInput, getWeek } from '../../lib/utils/format';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { clearLoading } from '../../modules/loading';
import {isNumber} from "../../lib/utils/util";

const TimeRecordForm = ({ callBackFn }) => {
  const dispatch = useDispatch();
  const { timeCard, timeRecord, courses, error, findTimeRecordLoading, saveTimeRecordLoading } = useSelector(
    ({ timeCard, timeRecord, courses, loading }) => ({
      timeCard: timeCard,
      timeRecord: timeRecord,
      courses: courses,
      error: timeRecord.error,
      findTimeRecordLoading: loading['timeRecord/FIND_TIME_RECORD'],
      saveTimeRecordLoading: loading['timeRecord/SAVE_TIME_RECORD'],
    }),
  );
  const alert = useAlert();

  const [courseId, setCourseId] = useState(null);
  const [lectureId, setLectureId] = useState(null);
  const [lectureDate, setLectureDate] = useState(null);
  const [plannedStartTime, setPlannedStartTime] = useState('00:00');
  const [plannedEndTime, setPlannedEndTime] = useState('00:00');
  const [actualStartTime, setActualStartTime] = useState('');
  const [actualEndTime, setActualEndTime] = useState('');
  const [actualTime, setActualTime] = useState({});

  useEffect(() => {
    return () => {
      initTimeRecord();
    };
  }, [dispatch]);

  const initTimeRecord = () => {
    dispatch(clearTimeRecord());
  };

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (timeCard.selectedRowDatas) {
      console.log('timeCard.selectedRowDatas', timeCard.selectedRowDatas);
      const courseId = courses.selectedRowDatas.courseId;
      const lectureId = timeCard.selectedRowDatas.lectureId;
      setCourseId(courseId);
      setLectureId(lectureId);
      dispatch(findTimeRecord(courseId, lectureId));
    }
  }, [timeCard.selectedRowDatas]);

  useEffect(() => {
    const { startHour, startMinute, endHour, endMinute } = timeRecord.plannedTime;

    setPlannedStartTime(colonStyleTime(startHour, startMinute));
    setPlannedEndTime(colonStyleTime(endHour, endMinute));
  }, [timeRecord.plannedTime]);

  useEffect(() => {
    const { startHour, startMinute, endHour, endMinute } = timeRecord.actualTime || {};

    setActualStartTime(colonStyleTime(startHour, startMinute));
    setActualEndTime(colonStyleTime(endHour, endMinute));
  }, [timeRecord.actualTime]);

  useEffect(() => {
    if (findTimeRecordLoading && findTimeRecordLoading.completed) {
      setLectureDate(formatDate(timeRecord.lectureDate));
      setPlannedStartTime(colonStyleTime(timeRecord.plannedTime.startHour, timeRecord.plannedTime.startMinute));
      if (timeRecord.actualTime) {
        setActualTime({
          startHour: timeRecord.actualTime.startHour,
          startMinute: timeRecord.actualTime.startMinute,
          endHour: timeRecord.actualTime.endHour,
          endMinute: timeRecord.actualTime.endMinute,
        });
      } else {
        setActualTime({});
      }
      dispatch(clearLoading(findTimeRecordLoading.type));
    }
  }, [findTimeRecordLoading]);

  useEffect(() => {
    if (saveTimeRecordLoading && saveTimeRecordLoading.completed) {
      alert.success('강사 출퇴근 정보를 수정하였습니다.');
      callBackFn();
      dispatch(setChangedTime(new Date()));
      dispatch(clearLoading(saveTimeRecordLoading.type));
    }
  }, [saveTimeRecordLoading]);

  useEffect(() => {
    if (lectureDate) {
      changeFields({
        name: 'lectureWeek',
        value: getWeek(lectureDate, 'week'),
      });
    }
  }, [lectureDate]);

  const saveBtn = {
    label: '저장',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      let _actualTime = null;
      let checkoutTimeExists = isNumber(actualTime.endHour) && isNumber(actualTime.endMinute);
      let checkinTimeExists = isNumber(actualTime.startHour) && isNumber(actualTime.startMinute);

      if(checkoutTimeExists && !checkinTimeExists){
        window.alert('입실시간을 입력하세요.');
        return;
      }

      if (window.confirm('출퇴근 정보를 저장하시겠습니까?') && checkForm()) {
        if (checkinTimeExists) {
          _actualTime = actualTime;
        }

        const newTimeRecord = {
          courseId: courseId,
          lectureId: lectureId,
          actualTime: _actualTime
        };

        console.log(newTimeRecord);
        dispatch(await saveTimeRecord(newTimeRecord));
      }
    },
  };

  const checkForm = () => {
    // TODO:

    return true;
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    changeFields(e.target);
  };

  const changeFields = ({ name, value }) => {
    let names = name.split('.');
    let newTimeRecord = fromJS(timeRecord).setIn(names, value);
    dispatch(changeField(newTimeRecord.toJS()));
  };

  const onActualTimeBlur = (time, hourPropName, minutePropName) => {
    const value = time.replace(/[^0-9]/g, '');
    let hour;
    let minutes;
    if (value) {
      hour = 0;
      minutes = 0;
    }
    if (value.length >= 2) {
      hour = parseInt(value.substr(0, 2), 10);
      if (value.length > 2) {
        minutes = parseInt(value.substr(2), 10);
      }
    }

    setActualTime(fromJS(actualTime).setIn([hourPropName], hour).setIn([minutePropName], minutes).toJS());
  };

  return (
    <>
      <FormWrap ClassName={'formWrap'} form={timeRecord}>
        <FormGroup row>
          <Label sm={3}>강의 일자</Label>
          <Col sm={4}>
            <Input type="text" disabled={true} value={lectureDate} />
          </Col>
          <Col sm={5}>
            <Input
              type="select"
              disabled="disabled"
              name={'lectureWeek'}
              value={timeRecord.lectureWeek}
              onChange={onChange}
            >
              <option value="Monday">월요일</option>
              <option value="Tuesday">화요일</option>
              <option value="Wednesday">수요일</option>
              <option value="Thursday">목요일</option>
              <option value="Friday">금요일</option>
              <option value="Saturday">토요일</option>
              <option value="Sunday">일요일</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>강의 시간</Label>
          <Col sm={4}>
            <Input type="text" disabled={true} value={plannedStartTime} />
          </Col>
          <Label sm={1}>~</Label>
          <Col sm={4}>
            <Input type="text" disabled={true} value={plannedEndTime} />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>입퇴실 시간</Label>
          <Col sm={4}>
            <Input
              type="text"
              value={actualStartTime}
              onBlur={(e) => {
                onActualTimeBlur(e.target.value, 'startHour', 'startMinute');
              }}
              onChange={(e) => {
                setActualStartTime(formatTimeInput(e.target.value, actualStartTime));
              }}
            />
          </Col>
          <Label sm={1}>~</Label>
          <Col sm={4}>
            <Input
              type="text"
              value={actualEndTime}
              onBlur={(e) => {
                onActualTimeBlur(e.target.value, 'endHour', 'endMinute');
              }}
              onChange={(e) => {
                setActualEndTime(formatTimeInput(e.target.value, actualEndTime));
              }}
            />
          </Col>
        </FormGroup>
      </FormWrap>

      <FooterButtonGroup
        buttonData={{
          isHistoryBack: false,
          data: [saveBtn],
        }}
      />
    </>
  );
};

export default TimeRecordForm;
