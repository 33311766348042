import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import { Helmet } from 'react-helmet-async';
import Styled from 'styled-components';
import UserPage from './pages/user/UserPage';
import LecturePage from './pages/course/LecturePage';
import CoursePage from './pages/course/CoursePage';
import EnrollmentPage from './pages/enrollment/EnrollmentPage';
import EnrollmentExcelPage from './pages/enrollment/EnrollmentExcelPage';
import RollBookPage from './pages/rollBook/RollBookPage';
import TimeCardPage from './pages/timeCard/TimeCardPage';
import KioskPage from './pages/kiosk/KioskPage';
import HeaderMenu from './containers/layout/HeaderMenu';
import LeftMenu from './containers/layout/LeftMenu';
import CourseBoardPage from './pages/board/CourseBoardPage';
import EnrollmentHistoryPage from './pages/enrollment/EnrollmentHistoryPage';

const ContentArea = Styled.div`
    padding-left: 200px;
    width: 100%;
    overflow:auto;
    height: calc(100% - 56px);
`;

const App = () => {
  const wrappedRoutes = () => {
    const User = () => (
      <Switch>
        <Route path="/main/user/list" component={UserPage} />
      </Switch>
    );

    const Course = () => (
      <Switch>
        <Route path="/main/course/list" component={CoursePage} />
        <Route path="/main/course/lecture" component={LecturePage} />
      </Switch>
    );
    const Enrollment = () => (
      <Switch>
        <Route path="/main/enrollment/list" component={EnrollmentPage} />
        <Route path="/main/enrollment/excel" component={EnrollmentExcelPage} />
        <Route path="/main/enrollment/history" component={EnrollmentHistoryPage} />
      </Switch>
    );
    const RollBook = () => (
      <Switch>
        <Route path="/main/rollBook/rollBook" component={RollBookPage} />
        <Route path="/main/rollBook/timeCard" component={TimeCardPage} />
      </Switch>
    );
    const KioskDevice = () => (
      <Switch>
        <Route path="/main/kiosk/list" component={KioskPage} />
      </Switch>
    );
    const CourseBoard = () => (
      <Switch>
        <Route path="/main/courseBoard/list" component={CourseBoardPage} />
      </Switch>
    );

    return (
      <>
        <HeaderMenu />
        <LeftMenu />
        <ContentArea>
          <Switch>
            <Route path="/main/user" component={User} />
            <Route path="/main/course" component={Course} />
            <Route path="/main/enrollment" component={Enrollment} />
            <Route path="/main/rollBook" component={RollBook} />
            <Route path="/main/kiosk" component={KioskDevice} />
            <Route path="/main/courseBoard" component={CourseBoard} />
          </Switch>
        </ContentArea>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>SAMS Admin</title>
      </Helmet>

      <Router>
        <Switch>
          <Route exact component={LoginPage} path={['/login', '/']} />
          <Route path="/main/*" component={wrappedRoutes} />
        </Switch>
      </Router>
    </>
  );
};
export default App;
