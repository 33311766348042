import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap';
import * as classnames from 'classnames';
import ContentWrap from '../../components/layout/ContentWrap';
import RollBookList from './RollBookList';
//탭 모듈을 새로 만들지 않고 탭안의 모듈을 이용
import { rollBookDownload } from '../../modules/rollBooks';
import RollBook from './RollBook';
import { formatDate } from '../../lib/utils/format';

const RollBookTabs = ({ size }) => {
  const dispatch = useDispatch();
  const { rollBookCourses, rollBooks, error, loading } = useSelector(
    ({ rollBookCourses, rollBooks, error, loading }) => ({
      rollBookCourses: rollBookCourses,
      rollBooks: rollBooks,
    }),
  );
  const [activeTab, setActiveTab] = useState('1');
  const [courseId, setCourseId] = useState('');
  const [lectureDate, setLectureDate] = useState('');
  const [rollBookTabDisabled, setRollBookTabDisabled] = useState(true);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    return () => {
      initRollBookTab();
    };
  }, [dispatch]);

  useEffect(() => {
    setLectureDate('');
    setRollBookTabDisabled(true);
    if (rollBookCourses.selectedRowDatas.courseId) {
      setActiveTab('1');
      setCourseId(rollBookCourses.selectedRowDatas.courseId);
    }
  }, [rollBookCourses.selectedRowDatas]);

  useEffect(() => {
    if (rollBooks.selectedRowDatas.lectureDate) {
      setLectureDate(rollBooks.selectedRowDatas.lectureDate);
      setRollBookTabDisabled(false);
    } else {
      setLectureDate('');
      setRollBookTabDisabled(true);
    }
  }, [rollBooks.selectedRowDatas]);

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '수기 출석부 다운로드',
        onClickFn: (e) => {
          e.preventDefault();
          dispatch(rollBookDownload(courseId));
        },
      },
    ],
  };

  useEffect(() => {
    if (rollBooks.selectedRowDatas.lectureId) {
      setActiveTab('2');
    }
  }, [rollBooks.selectedRowDatas]);

  const initRollBookTab = () => {
    // dispatch(clearRollBookTab());
  };

  return (
    <ContentWrap
      size={size}
      title={'출석부'}
      buttonDatas={rollBookCourses.selectedRowDatas.courseId ? topButtonData : null}
    >
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
              setRollBookTabDisabled(true);
              setLectureDate('');
            }}
          >
            일별통계
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            disabled={rollBookTabDisabled}
            onClick={() => {
              toggle('2');
            }}
          >
            출결관리 {lectureDate ? '(' + formatDate(lectureDate) + ')' : null}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <RollBookList />
        </TabPane>
        <TabPane tabId="2">
          <RollBook />
        </TabPane>
      </TabContent>
    </ContentWrap>
  );
};

export default RollBookTabs;
