import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Styled from 'styled-components';
import ModalButtonGroup from '../buttonGroup/ModalButtonGroup';

const ModalPop = Styled(Modal)`
    max-width:${(props) => props.width || '1000'}px;
`;

const ModalPopup = ({
  inOpen,
  toggleFn,
  title,
  children,
  buttonData,
  width,
  history,
  hiddenBottomArea,
}) => {
  return (
    <ModalPop isOpen={inOpen} toggle={toggleFn} width={width}>
      <ModalHeader toggle={toggleFn}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      {hiddenBottomArea ? null : (
        <ModalFooter>
          <ModalButtonGroup
            toggleFn={toggleFn}
            buttonData={buttonData}
            history={history}
          />
        </ModalFooter>
      )}
    </ModalPop>
  );
};

export default ModalPopup;
