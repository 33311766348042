import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearCourse, findCourse, confirmedCourse, unconfirmedCourse } from '../../modules/course';
import { Col, InputGroup } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { Button, FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { useAlert } from 'react-alert';
import ContentWrap from '../../components/layout/ContentWrap';
import { getCreateOpeningYearOptions } from '../../lib/utils/util';
import { colonStyleTime } from '../../lib/utils/format';
import { clearLoading } from '../../modules/loading';
const CourseScheduleForm = ({ history, size }) => {
  const dispatch = useDispatch();
  const { course, coursesSelect, lecture, confirmCourseLoading, unconfirmCourseLoading, error } = useSelector(
    ({ course, coursesSelect, lecture, error, loading }) => ({
      course: course,
      coursesSelect: coursesSelect,
      lecture: lecture,
      error: course.error,
      confirmCourseLoading: loading['course/CONFIRMED_COURSE'],
      unconfirmCourseLoading: loading['course/UNCONFIRMED_COURSE'],
    }),
  );
  const alert = useAlert();

  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');

  useEffect(() => {
    return () => {
      initCourse();
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    const { startHour, startMinute, endHour, endMinute } = course.courseSchedule.openingTime;

    if (startHour !== null) {
      setStartTime(colonStyleTime(startHour, startMinute));
    }

    if (endHour !== null) {
      setEndTime(colonStyleTime(endHour, endMinute));
    }
  }, [course.courseSchedule.openingTime]);

  useEffect(() => {
    if (coursesSelect.selectedDatas.courseId) {
      dispatch(findCourse(coursesSelect.selectedDatas.courseId));
    } else {
      initCourse();
    }
  }, [coursesSelect, lecture.changedTime]);

  useEffect(() => {
    if (confirmCourseLoading && confirmCourseLoading.completed) {
      if (course.status === 'Confirmed') {
        alert.success('강의 일정이 확정 되었습니다.');

        dispatch(clearLoading(confirmCourseLoading.type));
      }
    }
  }, [confirmCourseLoading]);

  useEffect(() => {
    if (unconfirmCourseLoading && unconfirmCourseLoading.completed) {
      if (course.status === 'Unconfirmed') {
        alert.success('강의 일정 확정이 취소 되었습니다.');

        dispatch(clearLoading(unconfirmCourseLoading.type));
      }
    }
  }, [unconfirmCourseLoading]);

  const initCourse = () => {
    dispatch(clearCourse());
    setStartTime(0 + ':' + 0);
    setEndTime(0 + ':' + 0);
  };

  //일정 확인 저장
  const confirmCourseSchedule = () => {
    if (window.confirm('강의 일정을 확정 하시겠습니까?')) {
      dispatch(confirmedCourse(course.courseId));
    }
  };
  //일정 확인 취소
  const cancelCourseSchedule = () => {
    if (window.confirm('강의 일정 확정을 취소 하시겠습니까?')) {
      dispatch(unconfirmedCourse(course.courseId));
    }
  };
  return (
    <>
      {coursesSelect && coursesSelect.selectedDatas.courseId ? (
        <ContentWrap size={size} title={'강좌상세'} minHeight={'0'}>
          <FormWrap ClassName={'formWrap'} form={course}>
            <FormGroup row>
              <Label for="openingYear" sm={3}>
                개설년도
              </Label>
              <Col sm={9}>
                <Input disabled="disabled" type="select" value={course.courseSchedule.openingYear}>
                  <option value=""></option>
                  {Object.values(getCreateOpeningYearOptions()).map((option, index) => {
                    return (
                      <option key={'FORM_openingYear' + index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="openingYear" sm={3}>
                개설시기
              </Label>
              <Col sm={9}>
                <Input disabled="disabled" type="select" value={course.courseSchedule.openingTerm}>
                  <option value=""></option>
                  <option value={'FirstHalf'}>상반기</option>;<option value={'SecondHalf'}>하반기</option>;
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="categoryName" sm={3}>
                분류
              </Label>
              <Col sm={9}>
                <Input disabled="disabled" type="text" value={course.categoryName} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="courseName" sm={3}>
                강좌명
              </Label>
              <Col sm={9}>
                <Input disabled="disabled" type="text" value={course.courseName} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3}>담당강사</Label>
              <Col sm={9}>
                <Input type="text" value={course.teacherName} disabled="disabled" />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3}>기간</Label>
              <Col sm={4}>
                <Input disabled="disabled" type="text" value={course.courseSchedule.datePeriod.startDate} />
              </Col>
              <Label sm={1}>~</Label>
              <Col sm={4}>
                <Input disabled="disabled" type="text" value={course.courseSchedule.datePeriod.endDate} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3}>시간</Label>
              <Col sm={3}>
                <Input type="select" disabled="disabled" value={course.courseSchedule.openingWeek}>
                  <option value=""></option>
                  <option value="Monday">월</option>
                  <option value="Tuesday">화</option>
                  <option value="Wednesday">수</option>
                  <option value="Thursday">목</option>
                  <option value="Friday">금</option>
                  <option value="Saturday">토</option>
                  <option value="Sunday">일</option>
                </Input>
              </Col>
              <Col sm={3}>
                <Input type="text" disabled="disabled" value={startTime} />
              </Col>
              <Col sm={3}>
                <Input type="text" disabled="disabled" value={endTime} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="categoryName" sm={3}>
                총 차시
              </Label>
              <Col sm={3}>
                <Input disabled="disabled" type="text" value={course.courseSchedule.totalLectures} />
              </Col>
              <Label for="categoryName" sm={3}>
                수강가능인원
              </Label>
              <Col sm={3}>
                <Input type="text" disabled="disabled" value={course.quota.maximumStudents} />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3}>강의 일정</Label>
              <Col sm={9}>
                <InputGroup>
                  {course.status === 'Unconfirmed' ? (
                    <Button onClick={confirmCourseSchedule} color={'success'}>
                      확정
                    </Button>
                  ) : (
                    <Button onClick={cancelCourseSchedule} color={'danger'}>
                      확정 취소
                    </Button>
                  )}
                </InputGroup>
              </Col>
            </FormGroup>
          </FormWrap>
        </ContentWrap>
      ) : (
        <></>
      )}
    </>
  );
};

export default CourseScheduleForm;
