import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createUser,
  editUser,
  deleteUser,
  setAvailableId,
  clearUser,
  idCheck,
  passwordReset,
  changeField,
  findUser,
  setChangedTime,
} from '../../modules/user';
import { Col, InputGroupAddon, InputGroup } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { Button, FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { fromJS } from 'immutable';
import { useAlert } from 'react-alert';
import ContentWrap from '../../components/layout/ContentWrap';
import { clearLoading } from '../../modules/loading';

const UserForm = ({ history, size }) => {
  const dispatch = useDispatch();
  const {
    user,
    users,
    auth,
    error,
    idCheckLoading,
    createUserLoading,
    deleteUserLoading,
    updateUserLoading,
    passwordResetLoading,
  } = useSelector(({ user, users, auth, error, loading }) => ({
    user: user,
    users: users,
    auth: auth,
    error: user.error,
    idCheckLoading: loading['user/ID_CHECK'],
    createUserLoading: loading['user/CREATE_USER'],
    deleteUserLoading: loading['user/DELETE_USER'],
    updateUserLoading: loading['user/UPDATE_USER'],
    passwordResetLoading: loading['user/PASSWORD_RESET'],
  }));
  const alert = useAlert();
  //NEW_TYPE : 신규(초기화,신규)
  //DETAIL_TYPE:상세(삭제, 비밀번호 초기화, 수정)
  //EDIT_TYPE: 수정(취소,서장)
  const [formType, setFormType] = useState('NEW_TYPE');

  useEffect(() => {
    return () => {
      dispatch(clearUser());
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    //dispatch(clearUser());
    if (users.selectedRowDatas.userAccountId) {
      dispatch(findUser(users.selectedRowDatas.userAccountId));
      setFormType('DETAIL_TYPE');
    } else {
      dispatch(clearUser());
      setFormType('NEW_TYPE');
    }
  }, [users.selectedRowDatas]);

  useEffect(() => {
    if (idCheckLoading && idCheckLoading.completed) {
      if (user.isAvailable) {
        alert.success('사용가능한 아이디 입니다.');
        dispatch(setAvailableId(user.userCredential.loginId));
      } else {
        alert.error('이미 사용중인 아이디 입니다.');
        dispatch(setAvailableId(''));
      }
      dispatch(clearLoading(idCheckLoading.type));
    }
  }, [idCheckLoading]);

  useEffect(() => {
    if (createUserLoading && createUserLoading.completed) {
      if (user.accountId) {
        alert.success('회원을 신규 등록 하였습니다.');
        dispatch(clearUser());
        dispatch(setChangedTime(new Date()));
      } else {
        alert.error('회원 등록에 실패하였습니다.');
      }
      dispatch(clearLoading(createUserLoading.type));
    }
  }, [createUserLoading]);

  useEffect(() => {
    if (updateUserLoading && updateUserLoading.completed) {
      alert.success('회원정보를 수정 하였습니다.');
      setFormType('DETAIL_TYPE');
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(updateUserLoading.type));
    }
  }, [updateUserLoading]);

  useEffect(() => {
    if (deleteUserLoading && deleteUserLoading.completed) {
      alert.success('회원을 삭제 하였습니다.');
      dispatch(clearUser());
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(deleteUserLoading.type));
    }
  }, [deleteUserLoading]);

  useEffect(() => {
    if (passwordResetLoading && passwordResetLoading.completed) {
      alert.success('비밀번호 초기화 메세지를 발송했습니다.');
      dispatch(findUser(user.accountId));
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(passwordResetLoading.type));
    }
  }, [passwordResetLoading]);

  useEffect(() => {
    console.log(formType);
  }, [formType]);

  const clearBtn = {
    label: '초기화',
    type: 'secondary',
    onClickFn: () => {
      dispatch(clearUser());
    },
  };
  const createBtn = {
    label: '신규등록',
    type: 'success',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('등록하시겠습니까?')) {
        checkForm(0, async () => {
          dispatch(await createUser(user));
        });
      }
    },
  };
  const deleteBtn = {
    label: '삭제',
    type: 'danger',
    onClickFn: (e) => {
      e.preventDefault();

      if (auth.loginUser.accountId === user.accountId) {
        alert.error('로그인된 사용자의 계정은 삭제할 수 없습니다.');
        return;
      }

      if (window.confirm('삭제하시겠습니까?')) {
        dispatch(deleteUser(user.accountId));
      }
    },
  };
  const resetPasswordBtn = {
    label: '비밀번호 초기화',
    type: 'warning',
    onClickFn: (e) => {
      e.preventDefault();
      if (
        window.confirm('비밀번호 초기화를 하시겠습니까?\n해당 사용자의 휴대전화로 초기화 비밀번호 메세지가 전송됩니다.')
      ) {
        dispatch(passwordReset(user));
      }
    },
  };
  const editBtn = {
    label: '수정',
    type: 'info',
    onClickFn: (e) => {
      e.preventDefault();
      setFormType('EDIT_TYPE');
    },
  };
  const cancelBtn = {
    label: '취소',
    type: 'secondary',
    onClickFn: (e) => {
      e.preventDefault();
      setFormType('DETAIL_TYPE');
      dispatch(findUser(user.accountId));
    },
  };
  const saveBtn = {
    label: '저장',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('저장하시겠습니까?')) {
        checkForm(1, async () => {
          dispatch(await editUser(user));
        });
      }
    },
  };

  const btnData = {
    isHistoryBack: false,
    NEW_TYPE: [clearBtn, createBtn],
    DETAIL_TYPE: [deleteBtn, resetPasswordBtn, editBtn],
    EDIT_TYPE: [cancelBtn, saveBtn],
  };

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '사용자등록',
        onClickFn: (e) => {
          e.preventDefault();
          dispatch(clearUser());
          setFormType('NEW_TYPE');
        },
      },
    ],
  };

  const checkForm = (type, callbackFn) => {
    //type 0 - 신규 1 - 수정
    if (type === 0 && (!user.availableId || user.availableId != user.userCredential.loginId)) {
      alert.error('ID 중복확인을 해주세요.');
      return false;
    }
    if (!user.userDetail.userName) {
      alert.error('이름은 필수값 입니다.');
      return false;
    }
    if (!user.userDetail.phoneNo) {
      alert.error('휴대전화는 필수값 입니다.');
      return false;
    }
    if (!user.role) {
      alert.error('역할은 필수값 입니다.');
      return false;
    }

    callbackFn();
  };

  const onIdCheck = async () => {
    dispatch(await idCheck(user.userCredential.loginId));
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const name = e.target.name;
    let value;
    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    let names = name.split('.');
    const newUser = fromJS(user).setIn(names, value);

    dispatch(changeField(newUser.toJS()));
  };

  return (
    <ContentWrap
      size={size}
      title={
        formType === 'NEW_TYPE'
          ? '사용자 등록'
          : formType === 'DETAIL_TYPE'
          ? '사용자 상세'
          : formType === 'EDIT_TYPE'
          ? '사용자 수정'
          : null
      }
      buttonDatas={formType !== 'NEW_TYPE' ? topButtonData : null}
    >
      <FormWrap ClassName={'formWrap'} form={user}>
        <FormGroup row>
          <Label for="loginId" sm={3}>
            ID
          </Label>
          <Col sm={9}>
            <InputGroup>
              <Input
                disabled={formType !== 'NEW_TYPE' ? 'disabled' : null}
                type="text"
                name="userCredential.loginId"
                id="loginId"
                placeholder="아이디를 입력하세요."
                value={user.userCredential.loginId}
                onChange={onChange}
              />
              {formType === 'NEW_TYPE' ? (
                <InputGroupAddon addonType="append">
                  <Button onClick={onIdCheck}>중복 확인</Button>
                </InputGroupAddon>
              ) : null}
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="userName" sm={3}>
            이름
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="userDetail.userName"
              id="userName"
              placeholder="이름을 입력하세요."
              value={user.userDetail.userName}
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="gender" sm={3}>
            성별
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="select"
              name="userDetail.gender"
              id="gender"
              value={user.userDetail.gender}
              onChange={onChange}
            >
              <option value={'Man'}>남자</option>
              <option value={'Woman'}>여자</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="birthDate" sm={3}>
            생년월일
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="test"
              name="userDetail.birthDate"
              id="birthDate"
              placeholder="ex) 1900-01-01"
              value={user.userDetail.birthDate}
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="phoneNo" sm={3}>
            휴대전화
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="userDetail.phoneNo"
              id="phoneNo"
              placeholder="ex) 01000000000"
              value={user.userDetail.phoneNo}
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="role" sm={3}>
            역할
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType !== 'NEW_TYPE' ? 'disabled' : null}
              type="select"
              name="role"
              id="role"
              value={user.role}
              onChange={onChange}
            >
              <option value={'Student'}>학생</option>
              <option value={'Teacher'}>강사</option>
              <option value={'Administrator'}>관리자</option>
            </Input>
          </Col>
        </FormGroup>
        {formType !== 'NEW_TYPE' ? (
          <FormGroup row>
            <Label for="status" sm={3}>
              상태
            </Label>
            <Col sm={9}>
              <InputGroup>
                <Input
                  disabled={formType !== 'NEW_TYPE' ? 'disabled' : null}
                  type="select"
                  name="status"
                  id="status"
                  value={user.status}
                  onChange={onChange}
                >
                  <option value={'Active'}>활성</option>
                  <option value={'Stop'}>비활성</option>
                  <option value={'PasswordReset'}>비밀번호초기화</option>
                </Input>
              </InputGroup>
            </Col>
          </FormGroup>
        ) : null}
        {formType === 'NEW_TYPE' ? (
          <FormGroup row>
            <Label for="role" sm={3}>
              등록 알림
            </Label>
            <Col sm={9}>
              <Label check sm className="l">
                <Input
                  disabled={formType !== 'NEW_TYPE' ? 'disabled' : null}
                  type="checkbox"
                  name="smsNotification"
                  value={user.smsNotification}
                  checked={user.smsNotification}
                  onChange={onChange}
                />
                사용자의 휴대전화로 등록 안내 문자메시지를 발송합니다.
              </Label>
            </Col>
          </FormGroup>
        ) : null}
      </FormWrap>
      <FooterButtonGroup
        buttonData={{
          isHistoryBack: btnData.isHistoryBack,
          data: btnData[formType],
        }}
        history={history}
      ></FooterButtonGroup>
    </ContentWrap>
  );
};

export default UserForm;
