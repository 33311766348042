import React from 'react';
import { Input } from 'reactstrap';

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Input
      innerRef={ref}
      type={props.type}
      name={props.name}
      id={props.id}
      disabled={props.disabled}
      value={props.value !== null ? props.value : ''}
      className={props.className}
      cssModule={props.cssModule}
      onChange={props.onChange}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      checked={props.checked}
      tag={props.tag}
      invalid={props.invalid}
      children={props.children}
      size={props.size}
      bsSize={props.bsSize}
      valid={props.valid}
      plaintext={props.plaintext}
      addon={props.addon}
      rows={props.rows}
    />
  );
});

export default CustomInput;
