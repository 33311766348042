import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseBoardAPI from '../lib/api/courseBoard';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
//

const CHANGE_FIELD = 'post/CHANGE_FIELD';

const [FIND_POST, FIND_POST_SUCCESS, FIND_POST_FAILURE] = createRequestActionTypes('post/FIND_POST');

const CLEAR_POST = 'post/CLEAR_POST';
const SET_CHANGED_TIME = 'post/SET_CHANGED_TIME'; // 상세 데이타 변경

export const changeField = createAction(CHANGE_FIELD, (post) => post);

export const findPost = createAction(FIND_POST, (postId) => postId);
export const clearPost = createAction(CLEAR_POST);
export const setChangedTime = createAction(SET_CHANGED_TIME);

// saga 생성
const findPostSaga = createRequestSaga(FIND_POST, courseBoardAPI.findPost);

export function* postSaga() {
  yield takeLatest(FIND_POST, findPostSaga);
}

const initialState = {
  postId: '',
  subject: '',
  content: '',
  serialNo: '',
  writer: {
    accountId: '',
    userName: '',
  },
  postedAt: null,
  notice: false,
  draft: false,
  boardId: '',
  error: null,
  changedTime: new Date(),
};

const post = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_POST_SUCCESS]: (state, { payload: post }) => ({
      ...state,
      ...post,
    }),

    [FIND_POST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [CLEAR_POST]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default post;
