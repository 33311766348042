import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseAPI from '../lib/api/course';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
//

const [FIND_COURSE_LIST, FIND_COURSE_LIST_SUCCESS, FIND_COURSE_LIST_FAILURE] = createRequestActionTypes(
  'enrollmentCourses/FIND_COURSE_LIST',
);

const CHANGE_FIELD = 'enrollmentCourses/CHANGE_FIELD';
const CLEAR_COURSE_LIST = createRequestActionTypes('enrollmentCourses/CLEAR_COURSE_LIST');

const SET_OFFSET = 'enrollmentCourses/SET_OFFSET';

export const findEnrollmentCourseList = createAction(FIND_COURSE_LIST, (offset, limit, userAccountId) => {
  return {
    offset,
    limit,
    search: {
      userRole: 'Student',
      userAccountId: userAccountId,
      totalCountYn: 'Y',
    },
  };
});

export const changeField = createAction(CHANGE_FIELD, (courses) => courses);
export const clearCourseList = createAction(CLEAR_COURSE_LIST);
export const setOffset = createAction(SET_OFFSET);

// saga 생성
const listCoursesSaga = createRequestSaga(FIND_COURSE_LIST, courseAPI.findCourseList);

export function* enrollmentCoursesSaga() {
  yield takeLatest(FIND_COURSE_LIST, listCoursesSaga);
}

const initialState = {
  courses: [],
  error: null,
  offset: 0,
  limit: 12,
  totalCount: 0,
  selectedRowDatas: {},
};

const enrollmentCourses = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_COURSE_LIST_SUCCESS]: (state, { payload: courses, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (courses.length !== state.limit) {
        let addRowCount = state.limit - courses.length;
        for (let i = 0; i < addRowCount; i++) {
          courses.push({});
        }
      }

      return { ...state, courses: courses, selectedRowDatas: {} };
    },

    [FIND_COURSE_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_COURSE_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),
  },
  initialState,
);

export default enrollmentCourses;
