import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as rollBookAPI from '../lib/api/rollBook';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
const [
  FIND_ROLL_BOOK_COURSE_LIST,
  FIND_ROLL_BOOK_COURSE_LIST_SUCCESS,
  FIND_ROLL_BOOK_COURSE_LIST_FAILURE,
] = createRequestActionTypes('rollBookCourses/FIND_ROLL_BOOK_COURSE_LIST');

const CHANGE_FIELD = 'rollBookCourses/CHANGE_FIELD';
const CLEAR_ROLL_BOOK_COURSE_LIST = createRequestActionTypes('rollBookCourses/CLEAR_ROLL_BOOK_COURSE_LIST');

const SET_OFFSET = 'rollBookCourses/SET_OFFSET';
const SET_SELETED_ROW_DATAS = 'rollBookCourses/SET_SELETED_ROW_DATAS';

export const findRollBookCourseList = createAction(FIND_ROLL_BOOK_COURSE_LIST, (offset, limit, search) => {
  return { offset, limit, search };
});

export const changeField = createAction(CHANGE_FIELD, (rollBookCourses) => rollBookCourses);
export const clearRollBookCourseList = createAction(CLEAR_ROLL_BOOK_COURSE_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setSelectedRowDatas = createAction(SET_SELETED_ROW_DATAS, (row) => row);

// saga 생성
const listRollBookCoursesSaga = createRequestSaga(FIND_ROLL_BOOK_COURSE_LIST, rollBookAPI.findRollBookCourseList);

export function* rollBookCoursesSaga() {
  yield takeLatest(FIND_ROLL_BOOK_COURSE_LIST, listRollBookCoursesSaga);
}

const initialState = {
  rollBookCourses: [],
  error: null,
  offset: 0,
  limit: 10,
  totalCount: 0,
  search: {
    openingYear: new Date().getFullYear(),
    openingTerm: '',
    courseNameKeyword: '',
    teacherName: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const rollBookCourses = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_ROLL_BOOK_COURSE_LIST_SUCCESS]: (state, { payload: rollBookCourses, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (rollBookCourses.length !== state.limit) {
        let addRowCount = state.limit - rollBookCourses.length;
        for (let i = 0; i < addRowCount; i++) {
          rollBookCourses.push({});
        }
      }
      const { courseId } = state.selectedRowDatas;
      const selectedRowDatas = {};
      if (rollBookCourses.some((r) => r.courseId === courseId)) {
          selectedRowDatas.courseId = courseId;
      }

      return {
        ...state,
        rollBookCourses: rollBookCourses,
        selectedRowDatas,
      };
    },

    [FIND_ROLL_BOOK_COURSE_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_ROLL_BOOK_COURSE_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),
    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          courseId: row.values['courseId'],
        },
      };
    },
  },
  initialState,
);

export default rollBookCourses;
