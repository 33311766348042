import client from '../utils/client';

const api = '/api/a/v1';
//
export const findTimeRecordsByCourseId = (courseId) => {
  if (courseId) {
    return client.get(`${api}/courses/${courseId}/timeCard/timeRecords`);
  } else {
    return {};
  }
};

export const saveTimeRecord = (timeRecord) => {
  //
  const { courseId, lectureId } = timeRecord;
  return client.put(
    `${api}/courses/${courseId}/timeCard/timeRecords/${lectureId}`,
    timeRecord,
  );
};

export const findTimeRecord = ({ courseId, lectureId }) => {
  //
  if (courseId && lectureId) {
    return client.get(
      `${api}/courses/${courseId}/timeCard/timeRecords/${lectureId}`,
    );
  } else {
    return { data: {} };
  }
};
