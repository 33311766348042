import React, { useEffect, useState } from 'react';
import { Navbar, NavbarBrand, Nav, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../modules/auth';
import { useAlert } from 'react-alert';
import LoginUserCheck from '../../components/auth/LoginUserCheck';
import ModalPopup from '../../components/layout/ModalPopup';
import NewPasswordForm from '../auth/NewPasswordForm';
import Preferences from '../../components/preferences/Preferences';
//로그인 후 헤더 메뉴에서 로그인 유저 정보를 확인한다.

const HeaderMenu = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { auth, logoutLoading } = useSelector(({ auth, loading }) => ({
    auth: auth,
    logoutLoading: loading['auth/LOGOUT'],
  }));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const [preferencesModal, setPreferencesModal] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);

  const onLogout = () => {
    dispatch(logout());
  };

  const onChangePassword = () => setPasswordChangeModal(!passwordChangeModal);
  const onPreferences = () => setPreferencesModal(!preferencesModal);

  useEffect(() => {
    if (logoutLoading && logoutLoading.completed) {
      window.location.replace('/');
    }
  }, [logoutLoading]);

  return (
    <>
      <LoginUserCheck />
      <Navbar dark={true} className={'bg-dark'}>
        <NavbarBrand>SAMS Admin</NavbarBrand>
        <Nav navbar className="pull-right">
          <Dropdown direction="down" nav isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle nav caret>
              {auth.loginUser ? auth.loginUser.userDetail.userName : '관리자'}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={onChangePassword}>비밀번호 변경</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={onPreferences}>환경설정</DropdownItem>
              <DropdownItem divider />
              <DropdownItem onClick={onLogout}>로그아웃</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Navbar>

      <ModalPopup
        title="비밀번호 변경"
        inOpen={passwordChangeModal}
        toggleFn={() => {
          setPasswordChangeModal(!passwordChangeModal);
        }}
        width={500}
        hiddenBottomArea={true}
      >
        <NewPasswordForm callBackFn={onLogout} />
      </ModalPopup>
      <ModalPopup
        title="시스템 환경설정"
        inOpen={preferencesModal}
        toggleFn={() => {
          setPreferencesModal(!preferencesModal);
        }}
        width={1000}
        hiddenBottomArea={true}
      >
        <Preferences />
      </ModalPopup>
    </>
  );
};

export default HeaderMenu;
