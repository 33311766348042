import React from 'react';
import { Form } from 'reactstrap';
import PropertyForm from './PropertyForm';
import Styled from 'styled-components';

const PropertyFormWrapper = Styled(Form)`
  margin-top: 20px;
  padding: 12px;
  width:100%;
  border: 1px solid #dfdfdf;
  background-color: #fafafa;
  border-radius: 6px;
  
  .row{
    min-height:42px;
    align-items: center;
  
    label{
      text-align: center;
      font-weight: bold;
    }
  }
`;

const PropertyList = ({ namespace, serviceId, properties }) => {
  return (
    <>
      {properties.map((property, i) => (
        <PropertyFormWrapper key={i}>
          <PropertyForm namespace={namespace} serviceId={serviceId} property={property} />
        </PropertyFormWrapper>
      ))}
    </>
  );
};

export default PropertyList;
