import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginUser } from '../../modules/auth';

const LoginUserCheck = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ auth }) => ({
    auth: auth,
  }));

  useEffect(() => {
    if (!auth.loginUser && localStorage.getItem('accountId')) {
      dispatch(setLoginUser(localStorage.getItem('accountId')));
    }
  }, []);

  return <></>;
};

export default LoginUserCheck;
