import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import auth, { authSaga } from './auth';
import loading from './loading';
import user, { userSaga } from './user';
import users, { usersSaga } from './users';
import course, { courseSaga } from './course';
import courses, { coursesSaga } from './courses';
import coursesSelect, { coursesSelectSaga } from './coursesSelect';
import lectures, { lecturesSaga } from './lectures';
import lecture, { lectureSaga } from './lecture';
import enrollment, { enrollmentSaga } from './enrollment';
import enrollments, { enrollmentsSaga } from './enrollments';
import enrollmentExcel, { enrollmentExcelSaga } from './enrollmentExcel';
import kiosk, { kioskSaga } from './kiosk';
import kiosks, { kiosksSaga } from './kiosks';
import rollBooks, { rollBooksSaga } from './rollBooks';
import rollBook, { rollBookSaga } from './rollBook';
import rollBookCourses, { rollBookCoursesSaga } from './rollBookcourses';
import timeCard, { timeCardSaga } from './timeCard';
import timeRecord, { timeRecordSaga } from './timeRecord';
import posts, { postsSaga } from './posts';
import post, { postSaga } from './post';
import comments, { commentsSaga } from './comments';
import enrollmentUsers, { enrollmentUsersSaga } from './enrollmentUsers';
import enrollmentCourses, {enrollmentCoursesSaga} from './enrollmentCourses';

const rootReducer = combineReducers({
  auth,
  loading,
  user,
  users,
  course,
  courses,
  coursesSelect,
  lecture,
  lectures,
  enrollment,
  enrollments,
  enrollmentExcel,
  kiosk,
  kiosks,
  rollBook,
  rollBooks,
  rollBookCourses,
  timeCard,
  timeRecord,
  posts,
  post,
  comments,
  enrollmentUsers,
  enrollmentCourses,
});

export function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    usersSaga(),
    courseSaga(),
    coursesSaga(),
    coursesSelectSaga(),
    lectureSaga(),
    lecturesSaga(),
    enrollmentSaga(),
    enrollmentsSaga(),
    enrollmentExcelSaga(),
    kioskSaga(),
    kiosksSaga(),
    rollBookSaga(),
    rollBooksSaga(),
    rollBookCoursesSaga(),
    timeCardSaga(),
    timeRecordSaga(),
    postsSaga(),
    postSaga(),
    commentsSaga(),
    enrollmentUsersSaga(),
    enrollmentCoursesSaga(),
  ]);
}

export default rootReducer;
