import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';

//
export const findUserList = ({ offset, limit, search }) => {
  const { totalCountYn, userName, loginId, role, status, phoneNo } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    userName: userName || undefined,
    loginId: loginId || undefined,
    role: role || undefined,
    status: status || undefined,
    phoneNo: phoneNo || undefined,
  });
  return client.get(`${api}/userAccounts?${queryString}`);
};
export const findUser = (accountId) => {
  if (accountId) {
    return client.get(`${api}/userAccounts/${accountId}`);
  } else {
    return { data: {} };
  }
};

export const createUser = (userdata) => {
  return client.post(`${api}/userAccounts`, userdata);
};

export const updateUser = ({ accountId, phoneNo, userName, gender, birthDate, role, state }) =>
  client.patch(`${api}/userAccounts/${accountId}/userDetail`, {
    phoneNo,
    userName,
    gender,
    birthDate,
    role,
    state,
  });

export const deleteUser = (accountId) => client.delete(`${api}/userAccounts/${accountId}/remove`);

export const idCheck = (loginId) => {
  const queryString = qs.stringify({
    loginId: loginId,
  });
  return client.get(`${api}/userMisc/idCheck?${queryString}`).then();
};

export const changePassword = ({ accountId, oldPassword, newPassword }) =>
  client.put(`${api}/userAccounts/${accountId}/password`, {
    oldPassword,
    newPassword,
  });

export const passwordReset = ({ loginId, userName, gender, birthDate, phoneNo }) =>
  client.post(`${api}/userMisc/resetPassword`, {
    loginId,
    userName,
    birthDate,
    gender,
    phoneNo,
  });
