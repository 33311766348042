import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import CourseBoardSelectList from '../../containers/board/CourseBoardSelectList';
import PostList from '../../containers/board/PostList';
import PostDetailTabs from '../../containers/board/PostDetailTabs';

const CourseBoardPage = () => {
  return (
    <PageWrap title={'강좌별 게시판'}>
      <PostList size={8} />
      <PostDetailTabs size={4} />
    </PageWrap>
  );
};

export default CourseBoardPage;
