import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearUserList,
  findUserList,
  setOffset,
  changeField,
  setSelectedRowDatas,
} from '../../modules/enrollmentUsers';
import { genderKr, formatPhoneNo, formatDate } from '../../lib/utils/format';
import SearchWrap from '../../components/layout/SearchWrap';
import { Col, FormGroup, Label, Button } from 'reactstrap';
import Input from '../../components/common/Input';
import Row from 'reactstrap/es/Row';
import { fromJS } from 'immutable';
const EnrollmentUserList = ({ size, minHeight }) => {
  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { enrollmentUsers, error, loading } = useSelector(({ enrollmentUsers, user, error, loading }) => ({
    enrollmentUsers: enrollmentUsers,
    error: error,
    loading: loading['enrollmentUsers/FIND_USER_LIST'],
  }));
  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'loginId',
        style: {
          paddingLeft: '10px',
          width: '150px',
        },
      },
      {
        Header: '이름',
        accessor: 'userDetail.userName',
        className: 'c',
      },
      {
        Header: '생년월일',
        accessor: 'userDetail.birthDate',
        className: 'c',
        style: {
          width: '100px',
        },
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: '성별',
        accessor: 'userDetail.gender',
        className: 'c',
        style: {
          width: '50px',
        },
        Cell: (props) => <div> {genderKr(props.value)} </div>,
      },
      {
        Header: '휴대전화',
        accessor: 'userDetail.phoneNo',
        className: 'c',
        style: {
          width: '125px',
        },
        Cell: (props) => <div> {formatPhoneNo(props.value)} </div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearUserList());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(findUserList(enrollmentUsers.offset, enrollmentUsers.limit, enrollmentUsers.search));
  }, []);

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(findUserList(selected * enrollmentUsers.limit, enrollmentUsers.limit, enrollmentUsers.search));
      dispatch(setOffset(selected));
    }
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.original.accountId) {
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(enrollmentUsers).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
  };

  return (
    <ContentWrap size={size} title={'수강생 목록'} minHeight={minHeight}>
      <SearchWrap>
        <Row>
          <Col sm={10}>
            <FormGroup row className={'mb10'}>
              <Label for="loginIdSV" sm={2}>
                사용자ID
              </Label>
              <Col sm={4}>
                <Input
                  type="text"
                  name="search.loginId"
                  id="loginIdSV"
                  value={enrollmentUsers.search.loginId}
                  onChange={onChange}
                  placeholder="ID"
                />
              </Col>
              <Label for="phoneNoSV" sm={2}>
                휴대전화
              </Label>
              <Col sm={4}>
                <Input
                  type="text"
                  name="search.phoneNo"
                  id="phoneNoSV"
                  value={enrollmentUsers.search.phoneNo}
                  onChange={onChange}
                  placeholder="휴대전화"
                />
              </Col>
            </FormGroup>
            <FormGroup row className={'mb10'}>
              <Label for="userNameSV" sm={2}>
                이름
              </Label>
              <Col sm={4}>
                <Input
                  type="text"
                  name="search.userName"
                  id="userNameSV"
                  value={enrollmentUsers.search.userName}
                  onChange={onChange}
                  placeholder="이름"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={2} className={'button-col'}>
            <Button
              color={'success'}
              onClick={async (e) => {
                e.preventDefault();
                await setSelectPageIndex(0);
                await dispatch(setOffset(0));
                await dispatch(findUserList(0, enrollmentUsers.limit, enrollmentUsers.search));
              }}
            >
              검색
            </Button>
          </Col>
        </Row>
      </SearchWrap>
      <PagingDataTable
        columns={columns}
        data={enrollmentUsers.users}
        pagination={true}
        handlePageClick={handlePageClick}
        getRowProps={onRowClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(enrollmentUsers.totalCount / enrollmentUsers.limit)}
        limit={enrollmentUsers.limit}
      />
    </ContentWrap>
  );
};

export default EnrollmentUserList;
