import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseAPI from '../lib/api/course';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import { convertCourseSchedule } from '../lib/utils/format';
//

const [
  FIND_COURSE_LIST,
  FIND_COURSE_LIST_SUCCESS,
  FIND_COURSE_LIST_FAILURE,
] = createRequestActionTypes('courses/FIND_COURSE_LIST');

const CHANGE_FIELD = 'courses/CHANGE_FIELD';
const CLEAR_COURSE_LIST = createRequestActionTypes('courses/CLEAR_COURSE_LIST');

const SET_OFFSET = 'courses/SET_OFFSET';
const SET_SELETED_ROW_DATAS = 'courses/SET_SELETED_ROW_DATAS';

export const findCourseList = createAction(
  FIND_COURSE_LIST,
  (offset, limit, search) => {
    return { offset, limit, search };
  },
);

export const changeField = createAction(CHANGE_FIELD, (courses) => courses);
export const clearCourseList = createAction(CLEAR_COURSE_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setSelectedRowDatas = createAction(
  SET_SELETED_ROW_DATAS,
  (row) => row,
);

// saga 생성
const listCoursesSaga = createRequestSaga(
  FIND_COURSE_LIST,
  courseAPI.findCourseList,
);

export function* coursesSaga() {
  yield takeLatest(FIND_COURSE_LIST, listCoursesSaga);
}

const initialState = {
  courses: [],
  error: null,
  offset: 0,
  limit: 10,
  totalCount: 0,
  search: {
    openingYear: new Date().getFullYear(),
    openingTerm: '',
    courseNameKeyword: '',
    teacherName: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const courses = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_COURSE_LIST_SUCCESS]: (state, { payload: courses, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (courses.length !== state.limit) {
        let addRowCount = state.limit - courses.length;
        for (let i = 0; i < addRowCount; i++) {
          courses.push({});
        }
      }

      return { ...state, courses: courses, selectedRowDatas: {} };
    },

    [FIND_COURSE_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_COURSE_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),
    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          courseId: row.values['courseId'],
          courseName: row.values['courseName'],
          courseSchedule: convertCourseSchedule(
            row.values['courseSchedule'],
            true,
          ),
          teacherName: row.values['teacherName'],
        },
      };
    },
  },
  initialState,
);

export default courses;
