import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseAPI from '../lib/api/course';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const CHANGE_FIELD = 'course/CHANGE_FIELD';

const [
  FIND_COURSE,
  FIND_COURSE_SUCCESS,
  FIND_COURSE_FAILURE,
] = createRequestActionTypes('course/FIND_COURSE');
const [
  CREATE_COURSE,
  CREATE_COURSE_SUCCESS,
  CREATE_COURSE_FAILURE,
] = createRequestActionTypes('course/CREATE_COURSE');
const [
  DELETE_COURSE,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSE_FAILURE,
] = createRequestActionTypes('course/DELETE_COURSE');
const [
  UPDATE_COURSE,
  UPDATE_COURSE_SUCCESS,
  UPDATE_COURSE_FAILURE,
] = createRequestActionTypes('course/UPDATE_COURSE');
const [
  CONFIRMED_COURSE,
  CONFIRMED_COURSE_SUCCESS,
  CONFIRMED_COURSE_FAILURE,
] = createRequestActionTypes('course/CONFIRMED_COURSE');
const [
  UNCONFIRMED_COURSE,
  UNCONFIRMED_COURSE_SUCCESS,
  UNCONFIRMED_COURSE_FAILURE,
] = createRequestActionTypes('course/UNCONFIRMED_COURSE');
const [
  ASSIGN_TEACHER,
  ASSIGN_TEACHER_SUCCESS,
  ASSIGN_TEACHER_FAILURE,
] = createRequestActionTypes('course/ASSIGN_TEACHER');

const CLEAR_COURSE = 'course/CLEAR_COURSE'; // course 데이터 비우기
const SET_AVAILABLE_ID = 'course/SET_AVAILABLE_ID'; // 사용가능 아이디 등록
const SET_CHANGED_TIME = 'course/SET_CHANGED_TIME'; // 상세 데이타 변경
const SET_TEACHER = 'course/SET_TEACHER'; // 담당강사 선택

export const changeField = createAction(CHANGE_FIELD, (course) => course);
export const setTeacher = createAction(SET_TEACHER, (data) => data);

export const findCourse = createAction(FIND_COURSE, (courseId) => courseId);
export const createCourse = createAction(CREATE_COURSE, (course) => course);
export const deleteCourse = createAction(DELETE_COURSE, (courseId) => courseId);
export const editCourse = createAction(UPDATE_COURSE, (course) => {
  return {
    ...course,
    courseId: course.courseId,
    role: course.role,
    state: course.state,
  };
});
export const confirmedCourse = createAction(CONFIRMED_COURSE, (courseId) => {
  return { courseId, status: 'Confirmed' };
});
export const unconfirmedCourse = createAction(
  UNCONFIRMED_COURSE,
  (courseId) => {
    return { courseId, status: 'Unconfirmed' };
  },
);

export const assignTeacher = createAction(
  ASSIGN_TEACHER,
  ({ courseId, teacherAccountId }) => {
    return { courseId, teacherAccountId };
  },
);

export const clearCourse = createAction(CLEAR_COURSE);
export const setChangedTime = createAction(SET_CHANGED_TIME);
// saga 생성
const findCourseSaga = createRequestSaga(FIND_COURSE, courseAPI.findCourse);
const createCourseSaga = createRequestSaga(
  CREATE_COURSE,
  courseAPI.createCourse,
);
const updateCourseSaga = createRequestSaga(
  UPDATE_COURSE,
  courseAPI.updateCourse,
);
const deleteCourseSaga = createRequestSaga(
  DELETE_COURSE,
  courseAPI.deleteCourse,
);
const confirmedCourseSaga = createRequestSaga(
  CONFIRMED_COURSE,
  courseAPI.confirmedCourse,
);
const unconfirmedCourseSaga = createRequestSaga(
  UNCONFIRMED_COURSE,
  courseAPI.confirmedCourse,
);
const assignTeacherSaga = createRequestSaga(
  ASSIGN_TEACHER,
  courseAPI.assignTeacher,
);
export function* courseSaga() {
  yield takeLatest(FIND_COURSE, findCourseSaga);
  yield takeLatest(CREATE_COURSE, createCourseSaga);
  yield takeLatest(UPDATE_COURSE, updateCourseSaga);
  yield takeLatest(DELETE_COURSE, deleteCourseSaga);
  yield takeLatest(CONFIRMED_COURSE, confirmedCourseSaga);
  yield takeLatest(UNCONFIRMED_COURSE, unconfirmedCourseSaga);
  yield takeLatest(ASSIGN_TEACHER, assignTeacherSaga);
}

const initialState = {
  courseId: '',
  courseName: '',
  categoryName: '',
  courseSchedule: {
    datePeriod: {
      startDate: '',
      endDate: '',
    },
    openingWeek: 'Monday',
    openingTime: {
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
    },
    openingYear: new Date().getFullYear(),
    openingTerm: 'FirstHalf',
    totalLectures: 0,
  },
  teacherName: '',
  teacherId: '',
  teacherAccountId: '',
  selectedTeacherAccountId: '',
  quota: {
    maximumStudents: 0,
    minimumStudents: 0,
  },
  numberOfStudents: 0,
  opening: false,
  status: 'Unconfirmed',
  changedTime: new Date(),
};

const course = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_COURSE_SUCCESS]: (state, { payload: course }) => {
      return {
        ...initialState,
        ...course,
      };
    },

    [FIND_COURSE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CREATE_COURSE_SUCCESS]: (state, { payload: data }) => {
      return { ...state, courseId: data.courseId };
    },

    [CREATE_COURSE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [UPDATE_COURSE_SUCCESS]: (state, { payload: data }) => {
      return {
        ...state,
      };
    },

    [UPDATE_COURSE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [DELETE_COURSE_SUCCESS]: (state, { payload: data }) => ({
      ...state,
    }),

    [DELETE_COURSE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [CONFIRMED_COURSE_SUCCESS]: (state, { payload: data, meta: mata }) => {
      return {
        ...state,
        status: data.result === true ? 'Confirmed' : 'Unconfirmed',
      };
    },

    [CONFIRMED_COURSE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [UNCONFIRMED_COURSE_SUCCESS]: (state, { payload: data, meta: mata }) => {
      return {
        ...state,
        status: data.result === true ? 'Unconfirmed' : 'Confirmed',
      };
    },

    [UNCONFIRMED_COURSE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [ASSIGN_TEACHER_SUCCESS]: (state, { payload: data }) => {
      return { ...state };
    },

    [ASSIGN_TEACHER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_AVAILABLE_ID]: (state, { payload: availableId }) => ({
      ...state,
      availableId: availableId,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [SET_TEACHER]: (state, { payload: data }) => ({
      ...state,
      teacherName: data.userName,
      selectedTeacherAccountId: data.userAccountId,
    }),

    [CLEAR_COURSE]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default course;
