import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { findPost, clearPost } from '../../modules/post';
import { Col } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { useAlert } from 'react-alert';
import { format } from 'date-fns';

const PostForm = ({ history, size }) => {
  const dispatch = useDispatch();
  const { post, posts, error } = useSelector(({ post, posts, error, loading }) => ({
    post: post,
    posts: posts,
    error: post.error,
  }));

  const alert = useAlert();
  //NEW_TYPE : 신규(초기화, 신규)
  //DETAIL_TYPE:상세(삭제, 수정)
  //EDIT_TYPE: 수정(취소, 저장)
  const [formType, setFormType] = useState('NEW_TYPE');

  useEffect(() => {
    return () => {
      dispatch(clearPost());
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (posts.selectedRowDatas.postId) {
      dispatch(findPost(posts.selectedRowDatas.postId));
      setFormType('DETAIL_TYPE');
    } else {
      dispatch(clearPost());
      setFormType('NEW_TYPE');
    }
  }, [posts.selectedRowDatas]);

  useEffect(() => {
    console.log(formType);
  }, [formType]);

  return (
    <>
      <FormWrap ClassName={'formWrap'} form={post}>
        <FormGroup row>
          <Label for="boardName" sm={3}>
            게시판
          </Label>
          <Col sm={9}>
            <Input disabled={true} type="text" name="boardName" id="boardName" value={post.boardName || ''} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="subject" sm={3}>
            제목
          </Label>
          <Col sm={9}>
            <Input
              disabled={true}
              type="text"
              name="subject"
              id="subject"
              placeholder="제목을 입력하세요."
              value={post.subject}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="subject" sm={3}>
            내용
          </Label>
          <Col sm={9}>
            <Input
              disabled={true}
              type="textarea"
              name="post.content"
              id="content"
              rows={14}
              placeholder="내용을 입력하세요."
              value={post.content}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={{ size: 9, offset: 3 }}>
            <Label check sm className="l">
              <Input disabled={true} type="checkbox" name="notice" value={post.notice} checked={post.notice} />
              공지사항
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="subject" sm={3}>
            작성자명
          </Label>
          <Col sm={9}>
            <Input disabled={true} type="text" name="subject" id="subject" value={post.writer.userName} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="postedAt" sm={3}>
            작성일시
          </Label>
          <Col sm={9}>
            <Input
              disabled={true}
              type="text"
              name="postedAt"
              id="postedAt"
              value={post.postedAt ? format(post.postedAt, 'yyyy-MM-dd HH:mm') : null}
            />
          </Col>
        </FormGroup>
      </FormWrap>
    </>
  );
};

export default PostForm;
