import { createAction, handleActions } from 'redux-actions';

const CLEAR_LOADING = 'loading/CLEAR_LOADING';
const START_LOADING = 'loading/START_LOADING';
const SUCCESS_FINISH_LOADING = 'loading/SUCCESS_FINISH_LOADING';
const FAILURE_FINISH_LOADING = 'loading/FAIL_FINISH_LOADING';

/*
 요청을 위한 액션 타입을 payload로 설정합니다 (예: "sample/GET_POST")
*/

export const clearLoading = createAction(
  CLEAR_LOADING,
  (requestType) => requestType,
);

export const startLoading = createAction(
  START_LOADING,
  (requestType) => requestType,
);

export const successFinishLoading = createAction(
  SUCCESS_FINISH_LOADING,
  (requestType) => requestType,
);
export const failureFinishLoading = createAction(
  FAILURE_FINISH_LOADING,
  (requestType) => requestType,
);

const initialState = {};

const loading = handleActions(
  {
    [CLEAR_LOADING]: (state, action) => {
      return {
        ...state,
        [action.payload]: {
          type: action.payload,
          loading: undefined,
          completed: false,
        },
      };
    },
    [START_LOADING]: (state, action) => {
      return {
        ...state,
        [action.payload]: {
          type: action.payload,
          loading: true,
          completed: false,
        },
      };
    },
    [SUCCESS_FINISH_LOADING]: (state, action) => {
      return {
        ...state,
        [action.payload]: {
          type: action.payload,
          loading: false,
          completed: true,
        },
      };
    },
    [FAILURE_FINISH_LOADING]: (state, action) => {
      return {
        ...state,
        [action.payload]: {
          type: action.payload,
          loading: false,
          completed: false,
        },
      };
    },
  },
  initialState,
);

export default loading;
