import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import * as classnames from 'classnames';
import ContentWrap from '../../components/layout/ContentWrap';
//탭 모듈을 새로 만들지 않고 탭안의 모듈을 이용
import PostForm from './PostForm';
import CommentList from './CommentList';

const RollBookTabs = ({ size }) => {
  const dispatch = useDispatch();
  const { rollBookCourses, rollBooks, error, loading } = useSelector(
    ({ rollBookCourses, rollBooks, error, loading }) => ({
      rollBookCourses: rollBookCourses,
      rollBooks: rollBooks,
    }),
  );
  const [activeTab, setActiveTab] = useState('1');
  const [courseId, setCourseId] = useState('');
  const [lectureDate, setLectureDate] = useState('');
  const [rollBookTabDisabled, setRollBookTabDisabled] = useState(true);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    return () => {
      initRollBookTab();
    };
  }, [dispatch]);

  useEffect(() => {
    setLectureDate('');
    setRollBookTabDisabled(true);
    if (rollBookCourses.selectedRowDatas.courseId) {
      setActiveTab('1');
      setCourseId(rollBookCourses.selectedRowDatas.courseId);
    }
  }, [rollBookCourses.selectedRowDatas]);

  useEffect(() => {
    if (rollBooks.selectedRowDatas.lectureDate) {
      setLectureDate(rollBooks.selectedRowDatas.lectureDate);
      setRollBookTabDisabled(false);
    } else {
      setLectureDate('');
      setRollBookTabDisabled(true);
    }
  }, [rollBooks.selectedRowDatas]);

  useEffect(() => {
    if (rollBooks.selectedRowDatas.lectureId) {
      setActiveTab('2');
    }
  }, [rollBooks.selectedRowDatas]);

  const initRollBookTab = () => {
    // dispatch(clearRollBookTab());
  };

  return (
    <ContentWrap size={size} title={'게시글 상세'}>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
              setRollBookTabDisabled(true);
              setLectureDate('');
            }}
          >
            상세 내용
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            // disabled={rollBookTabDisabled}
            onClick={() => {
              toggle('2');
            }}
          >
            댓글 목록
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <PostForm />
        </TabPane>
        <TabPane tabId="2">
          <CommentList />
        </TabPane>
      </TabContent>
    </ContentWrap>
  );
};

export default RollBookTabs;
