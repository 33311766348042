import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';
//
export const findEnrollmentList = ({ offset, limit, courseId }) => {
  if (courseId) {
    const queryString = qs.stringify({
      offset,
      limit,
      courseId,
    });
    return client.get(`${api}/students?${queryString}`);
  } else {
    return {};
  }
};

export const findEnrollment = (studentId) => {
  if (studentId) {
    return client.get(`${api}/students/${studentId}`);
  } else {
    return { data: {} };
  }
};

export const createEnrollment = ({
  courseId,
  userAccountId,
  registrationDate,
  registrationPath,
}) => {
  return client.post(`${api}/students`, {
    courseId,
    userAccountId,
    registrationDate,
    registrationPath,
  });
};

export const updateEnrollment = ({
  studentId,
  registrationDate,
  registrationPath,
}) => {
  return client.put(`${api}/students/${studentId}`, {
    registrationDate,
    registrationPath,
  });
};

export const deleteEnrollment = (studentId) =>
  client.delete(`${api}/students/${studentId}`);

//
export const templateExcelDownload = ({ openingYear, openingTerm }) => {
  const queryString = qs.stringify({
    openingYear,
    openingTerm,
  });
  return client.get(`${api}/enrollmentForms/template?${queryString}`, {
    responseType: 'blob',
  });
};

export const enrollmentExcelUpload = (formData) => {
  console.log(formData);
  return client.post(`${api}/enrollmentForms`, formData);
};

export const failResultExcelDownload = ({
  invalidFormId,
  openingYear,
  openingTerm,
}) => {
  const queryString = qs.stringify({
    openingYear,
    openingTerm,
  });
  return client.get(`${api}/enrollmentForms/${invalidFormId}?${queryString}`, {
    responseType: 'blob',
  });
};
