import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import EnrollmentExcelForm from '../../containers/enrollment/EnrollmentExcelForm';

const RegistrationByExcelPage = () => {
  return (
    <PageWrap title={'수강생 일괄 등록'}>
      <EnrollmentExcelForm size={12} />
    </PageWrap>
  );
};

export default RegistrationByExcelPage;
