import { createAction, handleActions } from 'redux-actions';
import { call, takeLatest } from 'redux-saga/effects';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';
import * as authAPI from '../lib/api/auth';
import * as userAPI from '../lib/api/user';

const CHANGE_FIELD = 'auth/CHANGE_FIELD';

const [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] = createRequestActionTypes('auth/LOGIN');
const [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAILURE] = createRequestActionTypes('auth/LOGOUT');
const [SET_LOGIN_USER, SET_LOGIN_USER_SUCCESS, SET_LOGIN_USER_FAILURE] = createRequestActionTypes(
  'auth/SET_LOGIN_USER',
);
const [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE] = createRequestActionTypes(
  'auth/CHANGE_PASSWORD',
);

export const changeField = createAction(CHANGE_FIELD, (auth) => auth);

export const login = createAction(LOGIN, (loginId, password) => {
  return { loginId, password };
});
export const logout = createAction(LOGOUT);

export const setLoginUser = createAction(SET_LOGIN_USER, (accountId) => {
  return accountId || '';
});

export const changePassword = createAction(CHANGE_PASSWORD, ({ accountId, oldPassword, newPassword }) => {
  return { accountId, oldPassword, newPassword };
});

// saga 생성
const loginSaga = createRequestSaga(LOGIN, authAPI.login);
const logoutSaga = createRequestSaga(LOGOUT, authAPI.logout);
const setLoginUserSaga = createRequestSaga(SET_LOGIN_USER, userAPI.findUser);
const changePasswordSaga = createRequestSaga(CHANGE_PASSWORD, userAPI.changePassword);

export function* authSaga() {
  yield takeLatest(LOGIN, loginSaga);
  yield takeLatest(LOGOUT, logoutSaga);
  yield takeLatest(SET_LOGIN_USER, setLoginUserSaga);
  yield takeLatest(CHANGE_PASSWORD, changePasswordSaga);
}

const initialState = {
  login: {
    loginId: '',
    password: '',
  },
  auth: null,
  loginUser: null,
  authError: null,
  passwordChanged: false,
};

const auth = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [LOGIN_SUCCESS]: (state, { payload: auth }) => {
      localStorage.setItem('tokenSet', JSON.stringify(auth.tokenSet));
      localStorage.setItem('accountId', auth.accountId);

      setLoginUser();

      return { ...state, authError: null, auth };
    },

    [LOGIN_FAILURE]: (state, { payload: error }) => {
      console.log(error);
      return {
        ...state,
        authError: error,
      };
    },

    [LOGOUT_SUCCESS]: (state, { payload: auth }) => {
      localStorage.removeItem('tokenSet');
      localStorage.removeItem('accountId');
      setLoginUser('');
      return { ...state, authError: null, auth };
    },

    [LOGOUT_FAILURE]: (state, { payload: error }) => {
      localStorage.removeItem('tokenSet');
      localStorage.removeItem('accountId');
      return {
        ...state,
        authError: error,
      };
    },

    [SET_LOGIN_USER_SUCCESS]: (state, { payload: user }) => {
      return { ...state, loginUser: user };
    },
    [SET_LOGIN_USER_FAILURE]: (state, { payload: error }) => {
      console.log(error);
      return {
        ...state,
        authError: error,
      };
    },

    [CHANGE_PASSWORD_SUCCESS]: (state, { payload: data }) => {
      return { ...state, passwordChanged: data.result };
    },
    [CHANGE_PASSWORD_FAILURE]: (state, { payload: error }) => {
      console.log(error);
      return {
        ...state,
        authError: error,
      };
    },
  },
  initialState,
);

export default auth;
