import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormText, Input, Label } from 'reactstrap';
import Styled from 'styled-components';
import * as preferencesAPI from '../../lib/api/preferences';
import { useAlert } from 'react-alert';

const ButtonGroupWrapper = Styled.div`
  margin-top: 10px;
  button {
    margin-right:12px;
  }
`;
const PropertyForm = ({ namespace, serviceId, property }) => {
  const [preferredValue, setPreferredValue] = useState('');
  const alert = useAlert();

  useEffect(() => {
    setPreferredValue(property.preferredValue || '');
  }, []);

  const propertyKey = `${namespace}.${property.definition.key}`;

  const onChange = (e) => {
    setPreferredValue(e.target.value);
  };

  const onRestoreDefault = () => {
    preferencesAPI.removePreferredProperty(serviceId, propertyKey).then((response) => {
      alert.success('환경설정이 초기화되었습니다.');
      setPreferredValue('');
    });
  };

  const onSave = () => {
    preferencesAPI.savePreferredProperty(serviceId, propertyKey, preferredValue).then((response) => {
      alert.success('환경설정이 저장되었습니다.');
    });
  };

  return (
    <>
      <FormGroup>
        <Label for="name">{property.definition.name}</Label>
        <Input type="text" name="name" value={preferredValue} onChange={onChange} />
        {property.definition.description.split('\n').map((line, i) => (
          <FormText key={i}>{line}</FormText>
        ))}
        <FormText>{'기본값 : ' + property.defaultValue}</FormText>
        <ButtonGroupWrapper>
          <Button size="sm" onClick={onRestoreDefault} color={'secondary'}>
            기본값으로 초기화
          </Button>
          <Button size="sm" onClick={onSave} color={'success'}>
            저장
          </Button>
        </ButtonGroupWrapper>
      </FormGroup>
    </>
  );
};

export default PropertyForm;
