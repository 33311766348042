import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as kioskAPI from '../lib/api/kiosk';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import createStateSaga from '../lib/createRequestSaga';
//

const [
  FIND_KIOSK_LIST,
  FIND_KIOSK_LIST_SUCCESS,
  FIND_KIOSK_LIST_FAILURE,
] = createRequestActionTypes('kiosks/FIND_KIOSK_LIST');

const CHANGE_FIELD = 'kiosks/CHANGE_FIELD';
const CLEAR_KIOSK_LIST = createRequestActionTypes('kiosks/CLEAR_KIOSK_LIST');

const SET_OFFSET = 'kiosks/SET_OFFSET';
const SET_INIT_SEARCH_VALUE = 'kiosks/SET_INIT_SEARCH_VALUE';
const SET_SELETED_ROW_DATAS = 'kiosks/SET_SELETED_ROW_DATAS';

export const findList = createAction(
  FIND_KIOSK_LIST,
  (offset, limit, search) => {
    return { offset, limit, search };
  },
);

export const changeField = createAction(CHANGE_FIELD, (kiosks) => kiosks);
export const clearList = createAction(CLEAR_KIOSK_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setInitSearchValue = createAction(SET_INIT_SEARCH_VALUE);
export const setSelectedRowDatas = createAction(
  SET_SELETED_ROW_DATAS,
  (row) => row,
);

// saga 생성
const listKiosksSaga = createRequestSaga(
  FIND_KIOSK_LIST,
  kioskAPI.findKioskList,
);

export function* kiosksSaga() {
  yield takeLatest(FIND_KIOSK_LIST, listKiosksSaga);
}

const initialState = {
  kiosks: [],
  error: null,
  offset: 0,
  limit: 10,
  totalCount: 0,
  search: {
    managedNameKeyword: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const kiosks = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_KIOSK_LIST_SUCCESS]: (state, { payload: kiosks, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (kiosks.length !== state.limit) {
        let addRowCount = state.limit - kiosks.length;
        for (let i = 0; i < addRowCount; i++) {
          kiosks.push({});
        }
      }

      return { ...state, kiosks: kiosks, selectedRowDatas: {} };
    },

    [FIND_KIOSK_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_KIOSK_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),
    [SET_INIT_SEARCH_VALUE]: (state, { payload: initSearchValue }) => {
      return { ...state, search: { ...state.search, ...initSearchValue } };
    },
    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          kioskDeviceId: row.values['kioskDeviceId'],
          managedName: row.values['managedName'],
        },
      };
    },
  },
  initialState,
);

export default kiosks;
