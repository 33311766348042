import React, { useEffect, useState } from 'react';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { clearRollBook, findRollBook, saveCheckinMarking, setChangedTime } from '../../modules/rollBook';
import { attendanceStatusKr, formatDate, genderKr } from '../../lib/utils/format';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { clearLoading } from '../../modules/loading';
import { useAlert } from 'react-alert';

const RollBook = ({}) => {
  const dispatch = useDispatch();
  const { rollBooks, rollBookCourses, rollBook, error, saveCheckinMarkingLoading } = useSelector(
    ({ rollBooks, rollBookCourses, rollBook, error, loading }) => ({
      rollBooks: rollBooks,
      rollBookCourses: rollBookCourses,
      rollBook: rollBook,
      error: rollBook.error,
      saveCheckinMarkingLoading: loading['rollBook/SAVE_CHECKIN_MARKING'],
    }),
  );
  const [selectRowIndex, setSelectRowIndex] = useState(-1);
  const [selectRowData, setSelectRowData] = useState([]);

  const alert = useAlert();

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'studentId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '이름',
        accessor: 'userName',
        className: 'c',
      },
      {
        Header: '생년월일',
        accessor: 'birthDate',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: '성별',
        accessor: 'gender',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div>{props.value ? genderKr(props.value) : null}</div>,
      },
      {
        Header: '상태',
        accessor: 'status',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div>{props.value ? attendanceStatusKr(props.value) : null}</div>,
      },
      {
        Header: '비고',
        accessor: 'comment',
        style: {
          width: '120px',
        },
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearRollBook());
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    dispatch(clearRollBook());
  }, [rollBookCourses.selectedRowDatas]);

  useEffect(() => {
    if (rollBooks.selectedRowDatas && rollBooks.selectedRowDatas.courseId && rollBooks.selectedRowDatas.lectureId) {
      dispatch(findRollBook(rollBooks.selectedRowDatas.courseId, rollBooks.selectedRowDatas.lectureId));
    }
  }, [rollBooks.selectedRowDatas]);

  useEffect(() => {
    if (saveCheckinMarkingLoading && saveCheckinMarkingLoading.completed) {
      alert.success('출석 정보를 변경하였습니다.');
      // dispatch(clearRollBook());
      dispatch(findRollBook(rollBooks.selectedRowDatas.courseId, rollBooks.selectedRowDatas.lectureId));
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(saveCheckinMarkingLoading.type));
    }
  }, [saveCheckinMarkingLoading]);

  const saveStudentCheckinMarking = (type) => {
    let studentIds = [];
    Object.values(selectRowData).map((data) => {
      studentIds.push(data.studentId);
    });

    if (studentIds.length == 0) {
      alert.error('출석 상태를 변경할 수강생을 먼저 선택하세요.');
      return;
    }

    dispatch(
      saveCheckinMarking({
        courseId: rollBooks.selectedRowDatas.courseId,
        lectureId: rollBooks.selectedRowDatas.lectureId,
        attendeeRole: 'Student',
        attendeeIds: studentIds,
        channel: 'AdminWeb',
        attendanceStatus: type,
      }),
    );
  };

  const resetBtn = {
    label: '출석 정보 초기화',
    type: 'info',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('출석 정보를 초기화 하시겠습니까?') && selectRowData) {
        saveStudentCheckinMarking('Reset');
      }
    },
  };

  const absentBtn = {
    label: '결석',
    type: 'danger',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('결석 처리 하시겠습니까?') && selectRowData) {
        saveStudentCheckinMarking('Absent');
      }
    },
  };
  const lateBtn = {
    label: '지각',
    type: 'warning',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('지각 처리 하시겠습니까?') && selectRowData) {
        saveStudentCheckinMarking('Late');
      }
    },
  };
  const presentBtn = {
    label: '출석',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('출석 처리 하시겠습니까?') && selectRowData) {
        saveStudentCheckinMarking('Present');
      }
    },
  };

  const btnData = {
    isHistoryBack: false,
    data: [resetBtn, absentBtn, lateBtn, presentBtn],
  };

  return (
    <>
      {rollBook.rollBook.length > 0 ? (
        <>
          <PagingDataTable
            columns={columns}
            data={rollBook.rollBook}
            selectRowIndex={selectRowIndex}
            pageIndex={0}
            limit={rollBooks.limit}
            useSelectionBox={true}
            selectRowDataCallBackFn={(data) => {
              setSelectRowData(data);
            }}
          />
          <FooterButtonGroup buttonData={btnData}></FooterButtonGroup>
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center', color: '#999', padding: '50px 0' }}>
            <h5>등록된 수강생이 없습니다.</h5>
          </div>
        </>
      )}
    </>
  );
};

export default RollBook;
