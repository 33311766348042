import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import KioskList from '../../containers/kiosk/KioskList';
import KioskForm from '../../containers/kiosk/KioskForm';

const KioskPage = () => {
  return (
    <PageWrap title={'키오스크 목록'}>
      <KioskList size={8} />
      <KioskForm size={4} />
    </PageWrap>
  );
};

export default KioskPage;
