import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import EnrollmentUserList from '../../containers/enrollment/EnrollmentUserList';
import EnrollmentCourseList from '../../containers/enrollment/EnrollmentCourseList';

const EnrollmentHistoryPage = () => {
  return (
    <PageWrap title={'강좌 신청 이력'}>
      <EnrollmentUserList size={5} />
      <EnrollmentCourseList size={7} />
    </PageWrap>
  );
};

export default EnrollmentHistoryPage;
