import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { findBoardList, setSelectedRowDatas, changeField } from '../../modules/posts';
import { useAlert } from 'react-alert';
import Row from 'reactstrap/es/Row';
import { Button, Col, FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { getSerachOpeningYearOptions, getCurrentYear, getCurrentTerm } from '../../lib/utils/util';
import SearchWrap from '../../components/layout/SearchWrap';
import { clearPostList, findPostList, setOffset } from '../../modules/posts';
import { format } from 'date-fns';
import { clearLoading } from '../../modules/loading';
import { fromJS } from 'immutable';

const PostList = ({ size }) => {
  const dispatch = useDispatch();
  const { posts, error, findBoardListLoading } = useSelector(({ posts, error, loading }) => ({
    posts: posts,
    error: error,
    findPostListLoading: loading['posts/FIND_POST_LIST'],
    findBoardListLoading: loading['posts/FIND_BOARD_LIST'],
  }));

  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const [selectRowIndex, setSelectRowIndex] = useState(-1);
  const [courseOpeningYear, setCourseOpeningYear] = useState(getCurrentYear());
  const [courseOpeningTerm, setCourseOpeningTerm] = useState(getCurrentTerm());
  const [courseBoardList, setCourseBoardList] = useState(null);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const alert = useAlert();

  const columns = React.useMemo(
    () => [
      {
        Header: '일시',
        accessor: 'postedAt',
        className: 'c',
        style: {
          width: '150px',
        },
        Cell: (props) => {
          return <div>{props.value ? format(new Date(props.value), 'yyyy-MM-dd HH:mm') : null}</div>;
        },
      },
      {
        Header: '제목',
        accessor: 'subject',
        className: 'l',
      },
      {
        Header: '작성자',
        accessor: 'writer.userName',
        className: 'c',
        style: {
          width: '150px',
        },
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearPostList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (courseOpeningYear && courseOpeningTerm) {
      dispatch(findBoardList(courseOpeningYear, courseOpeningTerm));
    } else {
      setCourseBoardList([]);
      clearBoardId();
    }
  }, [courseOpeningYear, courseOpeningTerm]);

  useEffect(() => {
    if (findBoardListLoading && findBoardListLoading.completed) {
      setCourseBoardList(posts.boards);
      clearBoardId();
      dispatch(clearLoading(findBoardListLoading.type));
    }
  }, [findBoardListLoading]);

  useEffect(() => {
    if (courseBoardList != null) {
      _findPostList(0, posts.limit, posts.search);
    }
  }, [courseBoardList]);

  const clearBoardId = () => {
    dispatch(changeField(fromJS(posts).setIn(['search', 'boardId'], '').toJS()));
  };

  const _findPostList = (offset, limit, search) => {
    let _search = fromJS(search).toJS();
    if (!search.boardId && courseBoardList) {
      _search.boardIds = courseBoardList.map((courseBoard) => courseBoard.boardId);
    }

    dispatch(findPostList(offset, limit, _search));
  };

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      _findPostList(selected * posts.limit, posts.limit, posts.search);
      dispatch(setOffset(selected));
    }
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.original.postId) {
          setSelectRowIndex(row.index);
          dispatch(setSelectedRowDatas(row));
        }
      },
    };
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(posts).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
  };

  return (
    <ContentWrap size={size} title={'게시글 목록'}>
      <SearchWrap>
        <Row>
          <Col sm={11}>
            <FormGroup row className={'mb10'}>
              <Label for="openingYearSV" sm={1}>
                개설년도
              </Label>
              <Col sm={2}>
                <Input
                  type="select"
                  id="openingYearSV"
                  value={courseOpeningYear || ''}
                  onChange={(e) => {
                    setCourseOpeningYear(e.target.value);
                  }}
                >
                  {Object.values(getSerachOpeningYearOptions()).map((option, index) => {
                    return (
                      <option key={'SEARCH_openingYear' + index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Label for="openingTermSV" sm={1}>
                개설시기
              </Label>
              <Col sm={2}>
                <Input
                  type="select"
                  id="openingTermSV"
                  value={courseOpeningTerm}
                  onChange={(e) => {
                    setCourseOpeningTerm(e.target.value);
                  }}
                >
                  <option value={'FirstHalf'}>상반기</option>
                  <option value={'SecondHalf'}>하반기</option>
                </Input>
              </Col>
              <Label for="courseBoardNameSV" sm={1}>
                강좌명
              </Label>
              <Col sm={5}>
                <Input
                  type="select"
                  id="courseBoardNameSV"
                  name="search.boardId"
                  value={posts.search.boardId}
                  onChange={onChange}
                  placeholder="강좌명"
                >
                  <option value={''}>전체</option>
                  {Object.values(posts.boards).map((board, index) => {
                    return (
                      <option key={board.boardId} value={board.boardId}>
                        {board.boardName}
                      </option>
                    );
                  })}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="writerNameSV" sm={1}>
                작성자명
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name="search.writerName"
                  id="writerNameSV"
                  value={posts.search.writerName}
                  onChange={onChange}
                  placeholder="강사명"
                />
              </Col>
              <Label for="subjectKeywordSV" sm={1}>
                제목
              </Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="search.subjectKeyword"
                  id="subjectKeywordSV"
                  value={posts.search.subjectKeyword}
                  onChange={onChange}
                  placeholder="제목"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={1} className={'button-col'}>
            <Button
              color={'success'}
              onClick={async (e) => {
                e.preventDefault();
                await setSelectPageIndex(0);
                await dispatch(setOffset(0));
                await _findPostList(0, posts.limit, posts.search);
              }}
            >
              검색
            </Button>
          </Col>
        </Row>
      </SearchWrap>
      <PagingDataTable
        columns={columns}
        data={posts.posts}
        pagination={true}
        handlePageClick={handlePageClick}
        getRowProps={onRowClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(posts.totalCount / posts.limit)}
        limit={posts.limit}
      />
    </ContentWrap>
  );
};

export default PostList;
