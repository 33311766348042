import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as timeCardAPI from '../lib/api/timeCard';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';

const CHANGE_FIELD = 'timeRecord/CHANGE_FIELD';

const [
  FIND_TIME_RECORD,
  FIND_TIME_RECORD_SUCCESS,
  FIND_TIME_RECORD_FAILURE,
] = createRequestActionTypes('timeRecord/FIND_TIME_RECORD');

const [
  SAVE_TIME_RECORD,
  SAVE_TIME_RECORD_SUCCESS,
  SAVE_TIME_RECORD_FAILURE,
] = createRequestActionTypes('timeRecord/SAVE_TIME_RECORD');

const SET_CHANGED_TIME = 'timeRecord/SET_CHANGED_TIME'; // 상세 데이타 변경
const CLEAR_TIME_RECORD = 'timeRecord/CLEAR_TIME_RECORD';

export const changeField = createAction(CHANGE_FIELD, (lecture) => lecture);

export const findTimeRecord = createAction(
  FIND_TIME_RECORD,
  (courseId, lectureId) => {
    return { courseId, lectureId };
  },
);

export const saveTimeRecord = createAction(
  SAVE_TIME_RECORD,
  (timeRecord) => timeRecord,
);

export const clearTimeRecord = createAction(CLEAR_TIME_RECORD);
export const setChangedTime = createAction(SET_CHANGED_TIME);

// saga 생성
const findTimeRecordSaga = createRequestSaga(
  FIND_TIME_RECORD,
  timeCardAPI.findTimeRecord,
);

const saveTimeRecordSaga = createRequestSaga(
  SAVE_TIME_RECORD,
  timeCardAPI.saveTimeRecord,
);

export function* timeRecordSaga() {
  yield takeLatest(FIND_TIME_RECORD, findTimeRecordSaga);
  yield takeLatest(SAVE_TIME_RECORD, saveTimeRecordSaga);
}

const initialState = {
  //
  lectureId: '',
  lectureDate: '',
  plannedTime: {
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0,
  },
  actualTime: {
    startHour: null,
    startMinute: null,
    endHour: null,
    endMinute: null,
  },
  error: null,
  changedTime: new Date(),
};

const timeRecord = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_TIME_RECORD_SUCCESS]: (state, { payload: timeRecord }) => {
      return { ...initialState, ...timeRecord };
    },

    [FIND_TIME_RECORD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SAVE_TIME_RECORD_SUCCESS]: (state, { payload: result }) => {
      return { ...state, result };
    },

    [SAVE_TIME_RECORD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),
    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),
    [CLEAR_TIME_RECORD]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default timeRecord;
