import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import CourseSelectList from '../../containers/course/CourseSelectList';
import LectureList from '../../containers/course/LectureList';
import CourseScheduleForm from '../../containers/course/CourseScheduleForm';

const LecturePage = () => {
  return (
    <PageWrap title={'수업 일정'}>
      <CourseSelectList size={12} />
      <CourseScheduleForm size={4} />
      <LectureList size={8} />
    </PageWrap>
  );
};

export default LecturePage;
