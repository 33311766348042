import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as timeCardAPI from '../lib/api/timeCard';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';

const [
  FIND_TIME_RECORD_LIST,
  FIND_TIME_RECORD_LIST_SUCCESS,
  FIND_TIME_RECORD_LIST_FAILURE,
] = createRequestActionTypes('timeCard/FIND_TIME_RECORD_LIST');

const CLEAR_TIME_RECORD_LIST = createRequestActionTypes(
  'timeCard/CLEAR_TIME_RECORD_LIST',
);

const SET_SELECTED_ROW_DATAS = createRequestActionTypes(
  'timeCard/SET_SELETED_ROW_DATAS',
);

export const findTimeRecordList = createAction(
  FIND_TIME_RECORD_LIST,
  (courseId) => courseId,
);

export const clearTimeRecordList = createAction(CLEAR_TIME_RECORD_LIST);

export const setSelectedRowDatas = createAction(
  SET_SELECTED_ROW_DATAS,
  (row) => row,
);

// saga 생성
const findTimeRecordListSaga = createRequestSaga(
  FIND_TIME_RECORD_LIST,
  timeCardAPI.findTimeRecordsByCourseId,
);

export function* timeCardSaga() {
  yield takeLatest(FIND_TIME_RECORD_LIST, findTimeRecordListSaga);
}

const initialState = {
  //
  timeRecords: [],
  error: null,
  limit: 1000,
};

const timeCard = handleActions(
  {
    [FIND_TIME_RECORD_LIST_SUCCESS]: (state, { payload: timeRecords }) => {
      return { ...state, timeRecords: timeRecords };
    },

    [FIND_TIME_RECORD_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      timeRecords: [],
      error,
    }),

    [CLEAR_TIME_RECORD_LIST]: () => initialState,

    [SET_SELECTED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          lectureId: row.original.lectureId,
        },
      };
    },
  },

  initialState,
);

export default timeCard;
