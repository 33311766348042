import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as rollBookAPI from '../lib/api/rollBook';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import { fileDownload } from '../lib/utils/util';
//

const [
  FIND_ROLL_BOOK_LIST,
  FIND_ROLL_BOOK_LIST_SUCCESS,
  FIND_ROLL_BOOK_LIST_FAILURE,
] = createRequestActionTypes('rollBooks/FIND_ROLL_BOOK_LIST');
const [
  ROLL_BOOK_DOWNLOAD,
  ROLL_BOOK_DOWNLOAD_SUCCESS,
  ROLL_BOOK_DOWNLOAD_FAILURE,
] = createRequestActionTypes('rollBooks/ROLL_BOOK_DOWNLOAD');

const [
  CLOSE_ATTENDANCE,
  CLOSE_ATTENDANCE_SUCCESS,
  CLOSE_ATTENDANCE_FAILURE,
] = createRequestActionTypes('rollBooks/CLOSE_ATTENDANCE');

const CHANGE_FIELD = 'rollBooks/CHANGE_FIELD';
const CLEAR_ROLL_BOOK_LIST = createRequestActionTypes(
  'rollBooks/CLEAR_ROLL_BOOK_LIST',
);

const SET_OFFSET = 'rollBooks/SET_OFFSET';
const SET_SELETED_ROW_DATAS = 'rollBooks/SET_SELETED_ROW_DATAS';

export const findRollBookList = createAction(
  FIND_ROLL_BOOK_LIST,
  (offset, limit, courseId) => {
    return { offset, limit, courseId };
  },
);
export const rollBookDownload = createAction(ROLL_BOOK_DOWNLOAD, (courseId) => {
  return { courseId };
});

export const closeAttendance = createAction(CLOSE_ATTENDANCE, (courseId) => {
  return { courseId };
});

export const changeField = createAction(CHANGE_FIELD, (rollBooks) => rollBooks);
export const clearRollBookList = createAction(CLEAR_ROLL_BOOK_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setSelectedRowDatas = createAction(
  SET_SELETED_ROW_DATAS,
  (row) => row,
);

// saga 생성
const listRollbooksSaga = createRequestSaga(
  FIND_ROLL_BOOK_LIST,
  rollBookAPI.findRollBookList,
);
const rollBookDownloadSaga = createRequestSaga(
  ROLL_BOOK_DOWNLOAD,
  rollBookAPI.rollBookDownload,
);

const closeAttendanceSaga = createRequestSaga(
  CLOSE_ATTENDANCE,
  rollBookAPI.closeAttendance,
);

export function* rollBooksSaga() {
  yield takeLatest(FIND_ROLL_BOOK_LIST, listRollbooksSaga);
  yield takeLatest(ROLL_BOOK_DOWNLOAD, rollBookDownloadSaga);
  yield takeLatest(CLOSE_ATTENDANCE, closeAttendanceSaga);
}

const initialState = {
  rollBooks: [],
  error: null,
  offset: 0,
  limit: 1000,
  totalCount: 0,
  selectedRowDatas: {},
};

const rollBooks = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_ROLL_BOOK_LIST_SUCCESS]: (state, { payload: rollBooks }) => {
      const { courseId, lectureId, lectureDate } = state.selectedRowDatas;
      const selectedRowDatas = {};
      if (rollBooks.some((r) => r.courseId === courseId && r.lectureId === lectureId && r.lectureDate === lectureDate)) {
          selectedRowDatas.courseId = courseId;
          selectedRowDatas.lectureId = lectureId;
          selectedRowDatas.lectureDate = lectureDate;
      }
      console.log('selectedRowDatas', selectedRowDatas)
      return {
        ...state,
        rollBooks: rollBooks,
        totalCount: rollBooks.length,
        selectedRowDatas,
      };
    },

    [FIND_ROLL_BOOK_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [ROLL_BOOK_DOWNLOAD_SUCCESS]: (state, { meta: res }) => {
      fileDownload(res);
      return { ...state };
    },

    [ROLL_BOOK_DOWNLOAD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLOSE_ATTENDANCE_SUCCESS]: (state, { payload: data }) => {
      return { ...state, result: data.result };
    },

    [CLOSE_ATTENDANCE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_ROLL_BOOK_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),
    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          courseId: row.values['courseId'],
          lectureId: row.values['lectureId'],
          lectureDate: row.values['lectureDate'],
        },
      };
    },
  },
  initialState,
);

export default rollBooks;
