import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Col } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { useAlert } from 'react-alert';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { clearLoading } from '../../modules/loading';
import { changePassword } from '../../modules/auth';

const NewPasswordForm = ({ callBackFn, mode }) => {
  const dispatch = useDispatch();
  const { auth, error, changePasswordLoading } = useSelector(({ auth, loading }) => ({
    auth: auth,
    error: auth.authError,
    changePasswordLoading: loading['auth/CHANGE_PASSWORD'],
  }));
  const alert = useAlert();

  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [newPassword2, setNewPassword2] = useState(null);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (changePasswordLoading && changePasswordLoading.completed) {
      if (auth.passwordChanged) {
        window.alert('비밀번호가 변경되었습니다. 다시 로그인하세요.');
        if (callBackFn) callBackFn();
      } else {
        alert.error('비밀번호를 변경할 수 없습니다. 입력한 정보를 확인 후 다시 시도하세요.');
      }
      dispatch(clearLoading(changePasswordLoading.type));
    }
  }, [changePasswordLoading]);

  const changePasswordBtn = {
    label: '비밀번호 변경',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (mode !== 'reset' && !oldPassword) {
        alert.error('현재 비밀번호를 입력하세요.');
        return;
      }

      if (!newPassword || !newPassword2) {
        alert.error('신규 비밀번호를 입력하세요.');
        return;
      }

      if (newPassword !== newPassword2) {
        alert.error('신규 비밀번호가 일치하지 않습니다.');
        return;
      }

      if (mode === 'reset') {
        if (window.confirm('비밀번호를 등록하시겠습니까?')) {
          const accountId = auth.auth.accountId;
          dispatch(await changePassword({ accountId, newPassword }));
        }
      } else {
        if (window.confirm('비밀번호를 변경하시겠습니까?')) {
          const accountId = auth.loginUser.accountId;
          dispatch(await changePassword({ accountId, oldPassword, newPassword }));
        }
      }
    },
  };

  return (
    <>
      {mode === 'reset' ? <Alert color="info">비밀번호가 초기화되었습니다. 새로운 비밀번호를 등록하세요.</Alert> : null}
      <FormWrap ClassName={'formWrap'}>
        {mode !== 'reset' ? (
          <FormGroup row>
            <Label sm={4}>현재 비밀번호</Label>
            <Col sm={8}>
              <Input type="password" onChange={(e) => setOldPassword(e.target.value)} value={oldPassword} />
            </Col>
          </FormGroup>
        ) : null}
        <FormGroup row>
          <Label sm={4}>신규 비밀번호</Label>
          <Col sm={8}>
            <Input type="password" onChange={(e) => setNewPassword(e.target.value)} value={newPassword} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>비밀번호 확인</Label>
          <Col sm={8}>
            <Input type="password" onChange={(e) => setNewPassword2(e.target.value)} value={newPassword2} />
          </Col>
        </FormGroup>
      </FormWrap>

      <FooterButtonGroup
        buttonData={{
          isHistoryBack: false,
          data: [changePasswordBtn],
        }}
      />
    </>
  );
};

export default NewPasswordForm;
