import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearRollBookCourseList,
  findRollBookCourseList,
  setOffset,
  changeField,
  setSelectedRowDatas,
} from '../../modules/rollBookcourses';
import { openingTermKr, convertCourseSchedule, convertPercent } from '../../lib/utils/format';
import SearchWrap from '../../components/layout/SearchWrap';
import { Col, FormGroup, Label, Button } from 'reactstrap';
import Input from '../../components/common/Input';
import Row from 'reactstrap/es/Row';
import { fromJS } from 'immutable';
import { getSerachOpeningYearOptions } from '../../lib/utils/util';

const RollBookCourseList = ({ size }) => {
  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { rollBookCourses, rollBook, error, loading } = useSelector(
    ({ rollBookCourses, rollBook, error, loading }) => ({
      rollBookCourses: rollBookCourses,
      rollBook: rollBook,
      error: rollBookCourses.error,
      loading: loading['rollBookCourse/FIND_COURSE_LIST'],
    }),
  );
  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'courseId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '연도',
        accessor: 'courseSchedule.openingYear',
        className: 'c',
        style: {
          width: '65px',
        },
      },
      {
        Header: '시기',
        accessor: 'courseSchedule.openingTerm',
        className: 'c',
        style: {
          width: '65px',
        },
        Cell: (props) => <div> {props.value ? openingTermKr(props.value) : null} </div>,
      },
      {
        Header: '강좌명',
        accessor: 'courseName',
      },
      {
        Header: '강의시간',
        accessor: 'courseSchedule',
        className: 'c',
        style: {
          width: '140px',
        },
        Cell: (props) => <div>{props.value ? convertCourseSchedule(props.value, true) : null}</div>,
      },
      {
        Header: '강사',
        accessor: 'teacherName',
        className: 'c',
        style: {
          width: '80px',
        },
      },
      {
        Header: '학생수',
        accessor: 'numberOfStudents',
        className: 'c',
        style: {
          width: '60px',
        },
      },
      {
        Header: '출석률',
        accessor: 'attendanceRate',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => (
          <div>{props.value !== null && props.value !== undefined ? convertPercent(props.value) : null}</div>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearRollBookCourseList());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(findRollBookCourseList(rollBookCourses.offset, rollBookCourses.limit, rollBookCourses.search));
  }, [rollBook.changedTime]);

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(findRollBookCourseList(selected * rollBookCourses.limit, rollBookCourses.limit, rollBookCourses.search));
      dispatch(setOffset(selected));
    }
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.values.courseId) {
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(rollBookCourses).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
  };

  return (
    <ContentWrap size={size} title={'강좌목록'}>
      <SearchWrap>
        <Row>
          <Col sm={10}>
            <FormGroup row className={'mb10'}>
              <Label for="openingYearSV" sm={2}>
                개설년도
              </Label>
              <Col sm={2}>
                <Input
                  type="select"
                  name="search.openingYear"
                  id="openingYearSV"
                  value={rollBookCourses.search.openingYear}
                  onChange={onChange}
                >
                  <option value={''}>선택</option>
                  {Object.values(getSerachOpeningYearOptions()).map((option, index) => {
                    return (
                      <option key={'SEARCH_openingYear' + index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Label for="openingTermSV" sm={2}>
                개설시기
              </Label>
              <Col sm={2}>
                <Input
                  type="select"
                  name="search.openingTerm"
                  id="openingTermSV"
                  value={rollBookCourses.search.openingTerm}
                  onChange={onChange}
                >
                  <option value={''}>선택</option>
                  <option value={'FirstHalf'}>상반기</option>
                  <option value={'SecondHalf'}>하반기</option>
                </Input>
              </Col>
              <Label for="teacherNameSV" sm={2}>
                강사명
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name="search.teacherName"
                  id="teacherNameSV"
                  value={rollBookCourses.search.teacherName}
                  onChange={onChange}
                  placeholder="강사명"
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="courseNameKeywordSV" sm={2}>
                강좌명
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="search.courseNameKeyword"
                  id="courseNameKeywordSV"
                  value={rollBookCourses.search.courseNameKeyword}
                  onChange={onChange}
                  placeholder="강좌명"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={2} className={'button-col'}>
            <Button
              color={'success'}
              onClick={async (e) => {
                e.preventDefault();
                await setSelectPageIndex(0);
                await dispatch(setOffset(0));
                await dispatch(findRollBookCourseList(0, rollBookCourses.limit, rollBookCourses.search));
              }}
            >
              검색
            </Button>
          </Col>
        </Row>
      </SearchWrap>
      <PagingDataTable
        columns={columns}
        data={rollBookCourses.rollBookCourses}
        pagination={true}
        handlePageClick={handlePageClick}
        getRowProps={onRowClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(rollBookCourses.totalCount / rollBookCourses.limit)}
        limit={rollBookCourses.limit}
      />
    </ContentWrap>
  );
};

export default RollBookCourseList;
