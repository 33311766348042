import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearEnrollmentExcel,
  enrollmentExcelUpload,
  failResultExcelDownload,
  templateExcelDownload,
} from '../../modules/enrollmentExcel';
import { Button, Col, FormText } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { useAlert } from 'react-alert';
import ContentWrap from '../../components/layout/ContentWrap';
import { getCreateOpeningYearOptions } from '../../lib/utils/util';
import { fromJS } from 'immutable';
import { changeField } from '../../modules/enrollmentExcel';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { clearLoading } from '../../modules/loading';
import { openingTermKr } from '../../lib/utils/format';
const EnrollmentExcelForm = ({ size, formType }) => {
  const dispatch = useDispatch();
  const { enrollmentExcel, enrollmentExcelUploadLoading, error } = useSelector(
    ({ enrollmentExcel, error, loading }) => ({
      enrollmentExcel: enrollmentExcel,
      error: enrollmentExcel.error,
      enrollmentExcelUploadLoading: loading['enrollmentExcel/ENROLLMENT_EXCEL_UPLOAD'],
    }),
  );
  const alert = useAlert();

  useEffect(() => {
    return () => {
      initEnrollmentExcel();
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (enrollmentExcelUploadLoading && enrollmentExcelUploadLoading.completed) {
      if (enrollmentExcel.result) {
        if (enrollmentExcel.result.uploadSucceeded) {
          if (enrollmentExcel.result.validForm) {
            alert.success('수강신청을 일괄 등록 하였습니다.');
          } else {
            alert.error('업로드 하신 파일의 값이 잘못 되었습니다. 수정 후 등록 바랍니다.');
          }
        } else {
          alert.error('파일 업로드에 실패 하였습니다.');
        }
      }
      dispatch(clearLoading(enrollmentExcelUploadLoading.type));
    }
  }, [enrollmentExcelUploadLoading]);

  const uploadBtn = {
    label: '업로드',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      const termKr = openingTermKr(enrollmentExcel.openingTerm);
      const confirmMessage = `${enrollmentExcel.openingYear}년 ${termKr} 수강생 일괄 등록을 위한 엑셀파일을 업로드합니다.`;
      if (enrollmentExcel.formData && window.confirm(confirmMessage)) {
        dispatch(enrollmentExcelUpload(enrollmentExcel.formData));
      }
    },
  };

  const onTemplateExcelDownloadBtnClick = (e) => {
    e.preventDefault();
    const termKr = openingTermKr(enrollmentExcel.openingTerm);
    const confirmMessage = `${enrollmentExcel.openingYear}년 ${termKr} 수강생 일괄 등록을 위한 템플릿을 다운로드합니다.`;
    if (window.confirm(confirmMessage)) {
      dispatch(templateExcelDownload(enrollmentExcel));
    }
  };

  const onFailResultExcelDownloadBtnClick = (e) => {
    e.preventDefault();

    dispatch(failResultExcelDownload(enrollmentExcel));
  };

  const initEnrollmentExcel = () => {
    dispatch(clearEnrollmentExcel());
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    changeFields(e.target);
  };

  const onFileChange = (e) => {
    const seletedFile = { file: e.target.files[0] };
    if (seletedFile) {
      const formData = new FormData();
      formData.append('file', seletedFile.file, seletedFile.file.name);
      formData.append('openingYear', enrollmentExcel.openingYear);
      formData.append('openingTerm', enrollmentExcel.openingTerm);

      const newEnrollmentExcel = fromJS(enrollmentExcel).setIn(['formData'], formData);
      dispatch(changeField(newEnrollmentExcel.toJS()));
    }
  };

  const changeFields = ({ name, value }) => {
    let names = name.split('.');
    const newEnrollmentExcel = fromJS(enrollmentExcel).setIn(names, value);

    dispatch(changeField(newEnrollmentExcel.toJS()));
  };

  return (
    <ContentWrap minHeight={'0'} size={size} title={''}>
      <FormWrap ClassName={'formWrap'} form={enrollmentExcel}>
        <FormGroup row>
          <Label sm={1}>개설년도</Label>
          <Col sm={2}>
            <Input type="select" name="openingYear" value={enrollmentExcel.openingYear} onChange={onChange}>
              {Object.values(getCreateOpeningYearOptions()).map((option, index) => {
                return (
                  <option key={'FORM_openingYear' + index} value={option}>
                    {option}
                  </option>
                );
              })}
            </Input>
          </Col>
          <Label sm={1}>개설시기</Label>
          <Col sm={2}>
            <Input type="select" name="openingTerm" value={enrollmentExcel.openingTerm} onChange={onChange}>
              <option value={'FirstHalf'}>상반기</option>;<option value={'SecondHalf'}>하반기</option>;
            </Input>
          </Col>
          <Col sm={3}>
            <Button color={'info'} onClick={onTemplateExcelDownloadBtnClick}>
              템플릿 파일 다운로드
            </Button>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={1}>업로드</Label>
          <Col sm={11}>
            <Input type="file" onChange={onFileChange} />
            <FormText color="muted">
              템플릿을 받으신 후 수강 신청 목록을 작성 하시고 업로드 해 주시기 바랍니다.
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={1}>업로드 결과</Label>
          {enrollmentExcel.result ? (
            <>
              <Col sm={1}>
                {enrollmentExcel.result.validForm ? (
                  <span className={'text-success'}>성공</span>
                ) : (
                  <span className={'text-danger'}>실패</span>
                )}
              </Col>
              {enrollmentExcel.result.invalidFormId ? (
                <Col sm={9}>
                  <Button color={'warning'} onClick={onFailResultExcelDownloadBtnClick}>
                    내용 확인 (다운로드)
                  </Button>
                </Col>
              ) : null}
            </>
          ) : null}
        </FormGroup>
      </FormWrap>

      <FooterButtonGroup buttonData={{ data: [uploadBtn] }}></FooterButtonGroup>
    </ContentWrap>
  );
};

export default EnrollmentExcelForm;
