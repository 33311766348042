import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { clearList, findList, setOffset, setInitSearchValue, setSelectedRowDatas } from '../../modules/kiosks';
import moment from "moment";

const KioskList = ({ size, initSearchValue, hiddenTitle, minHeight }) => {
  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { kiosks, kiosk, error, loading } = useSelector(({ kiosks, kiosk, error, loading }) => ({
    kiosks: kiosks,
    kiosk: kiosk,
    error: error,
    loading: loading['kiosk/FIND_KIOSK_LIST'],
  }));
  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'kioskDeviceId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '키오스크 명칭',
        accessor: 'managedName',
        className: 'c',
      },
      {
        Header: '모델명',
        accessor: 'modelName',
        className: 'c',
      },
      {
        Header: '시리얼번호',
        accessor: 'serialNo',
        className: 'c',
      },
      {
        Header: '상태',
        accessor: 'heartbeatTime',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => props.value
            ?  <div title={moment(new Date(props.value)).fromNow()}>
                {((props.value || 0) > (new Date().getTime() - (5 * 60000))) ? '정상' : '-'}
               </div>
            : '',
      },
      {
        Header: '공지사항',
        accessor: 'noticeApplied',
        className: 'c',
        style: {
          width: '150px',
        },
        Cell: (props) => <div>{props.value ? '적용됨' : '-'}</div>,
      },
    ],
    [],
  );

  useEffect(() => {
    if (initSearchValue) {
      dispatch(setInitSearchValue(initSearchValue));
      dispatch(
        findList(kiosks.offset, kiosks.limit, {
          ...kiosks.search,
          ...initSearchValue,
        }),
      );
    } else {
      dispatch(findList(kiosks.offset, kiosks.limit, kiosks.search));
    }

    return () => {
      dispatch(clearList());
    };
  }, [dispatch, kiosk.changedTime]);

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '새로고침',
        onClickFn: async (e) => {
          e.preventDefault();
          await setSelectPageIndex(0);
          await dispatch(setOffset(0));
          await dispatch(findList(0, kiosks.limit, kiosks.search));
        },
      },
    ],
  };

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(findList(selected * kiosks.limit, kiosks.limit, kiosks.search));
      dispatch(setOffset(selected));
    }
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.values.kioskDeviceId) {
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  return (
    <ContentWrap
      size={size}
      title={hiddenTitle ? '' : '키오스크 목록'}
      minHeight={minHeight}
      buttonDatas={topButtonData}
    >
      <PagingDataTable
        columns={columns}
        data={kiosks.kiosks}
        pagination={true}
        handlePageClick={handlePageClick}
        getRowProps={onRowClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(kiosks.totalCount / kiosks.limit)}
        limit={kiosks.limit}
      />
    </ContentWrap>
  );
};

export default KioskList;
