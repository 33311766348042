import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { colonStyleTime, convertOpeningTime, formatDate } from '../../lib/utils/format';
import { clearTimeRecordList, findTimeRecordList, setSelectedRowDatas } from '../../modules/timeCard';
import { useAlert } from 'react-alert';
import { Badge } from 'reactstrap';
import ModalPopup from '../../components/layout/ModalPopup';
import TimeRecordForm from './TimeRecordForm';

const TimeRecordList = ({ size }) => {
  const dispatch = useDispatch();
  const { timeCard, timeRecord, courses, error, loading } = useSelector(
    ({ timeCard, timeRecord, courses, error, loading }) => ({
      timeCard: timeCard,
      timeRecord: timeRecord,
      courses: courses,
      error: timeCard.error,
      loading: loading['timeCard/FIND_TIME_RECORD_LIST'],
    }),
  );
  const [selectRowIndex, setSelectRowIndex] = useState(-1);
  const [timeRecordDetailModal, setTimeRecordDetailModal] = useState(false);

  const alert = useAlert();

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        id: 'sequence',
        Header: '순번',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => {
          return <div>{props.row.index + 1}</div>;
        },
      },
      {
        id: 'lectureDate',
        Header: '강의일자',
        accessor: 'lectureDate',
        className: 'c',
        Cell: (props) => (
          <div>
            {props.row.original.cancelled ? (
              <Badge color="secondary">휴</Badge>
            ) : props.row.original.supplementary ? (
              <Badge color="info">보</Badge>
            ) : (
              ''
            )}{' '}
            {formatDate(props.value)}{' '}
          </div>
        ),
      },
      {
        id: 'lectureTime',
        Header: '강의시간',
        accessor: 'plannedTime',
        className: 'c',
        style: {
          width: '100px',
        },
        Cell: (props) => <div> {props.value ? convertOpeningTime(props.value) : null} </div>,
      },
      {
        id: 'startTime',
        Header: '입실시간',
        accessor: 'actualTime',
        className: 'c',
        style: {
          width: '100px',
        },
        Cell: (props) => (
          <div>{props.value ? colonStyleTime(props.value.startHour, props.value.startMinute) : null}</div>
        ),
      },
      {
        id: 'endTime',
        Header: '퇴실시간',
        accessor: 'actualTime',
        className: 'c',
        style: {
          width: '100px',
        },
        Cell: (props) => <div>{props.value ? colonStyleTime(props.value.endHour, props.value.endMinute) : null}</div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearTimeRecordList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (courses.selectedRowDatas.courseId) {
      dispatch(findTimeRecordList(courses.selectedRowDatas.courseId));
    } else {
      dispatch(clearTimeRecordList());
    }

    setSelectRowIndex(-1);
  }, [courses.selectedRowDatas]);

  useEffect(() => {
    if (courses.selectedRowDatas.courseId) {
      dispatch(findTimeRecordList(courses.selectedRowDatas.courseId));
    }
  }, [timeRecord.changedTime]);

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.original.lectureId) {
          setTimeRecordDetailModal(!timeRecordDetailModal);
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  return (
    <ContentWrap size={size} title={'출퇴근 이력'}>
      <PagingDataTable
        columns={columns}
        data={timeCard.timeRecords}
        selectRowIndex={selectRowIndex}
        pageIndex={0}
        getRowProps={onRowClick}
        limit={timeCard.limit}
      />

      <ModalPopup
        title="출퇴근 정보"
        inOpen={timeRecordDetailModal}
        toggleFn={() => {
          setTimeRecordDetailModal(!timeRecordDetailModal);
        }}
        width={500}
        hiddenBottomArea={true}
      >
        <TimeRecordForm
          callBackFn={() => {
            setTimeRecordDetailModal(!timeRecordDetailModal);
          }}
        />
      </ModalPopup>
    </ContentWrap>
  );
};

export default TimeRecordList;
