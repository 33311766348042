import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearUserList,
  findUserList,
  setOffset,
  changeField,
  setInitSearchValue,
  setSelectedRowDatas,
} from '../../modules/users';
import { roleKr, genderKr, statusKr, formatPhoneNo, formatDate } from '../../lib/utils/format';
import SearchWrap from '../../components/layout/SearchWrap';
import { Col, FormGroup, Label, Button } from 'reactstrap';
import Input from '../../components/common/Input';
import Row from 'reactstrap/es/Row';
import { fromJS } from 'immutable';
const UserList = ({ size, initSearchValue, disableSearchInputs, hiddenTitle, minHeight }) => {
  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { users, user, error, loading } = useSelector(({ users, user, error, loading }) => ({
    users: users,
    user: user,
    error: error,
    loading: loading['user/FIND_USER_LIST'],
  }));
  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'accountId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '구분',
        accessor: 'role',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {roleKr(props.value)} </div>,
      },
      {
        Header: 'ID',
        accessor: 'loginId',
      },
      {
        Header: '이름',
        accessor: 'userDetail.userName',
        className: 'c',
        style: {
          width: '200px',
        },
      },
      {
        Header: '생년월일',
        accessor: 'userDetail.birthDate',
        className: 'c',

        style: {
          width: '80px',
        },
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: '성별',
        accessor: 'userDetail.gender',
        className: 'c',
        style: {
          width: '70px',
        },
        Cell: (props) => <div> {genderKr(props.value)} </div>,
      },
      {
        Header: '휴대전화',
        accessor: 'userDetail.phoneNo',
        className: 'c',
        style: {
          width: '110px',
        },
        Cell: (props) => <div> {formatPhoneNo(props.value)} </div>,
      },
      {
        Header: '상태',
        accessor: 'status',
        className: 'c',
        style: {
          width: '150px',
        },
        Cell: (props) => <div> {statusKr(props.value)} </div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearUserList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (initSearchValue) {
      dispatch(setInitSearchValue(initSearchValue));
      dispatch(
        findUserList(users.offset, users.limit, {
          ...users.search,
          ...initSearchValue,
        }),
      );
    } else {
      dispatch(findUserList(users.offset, users.limit, users.search));
    }
  }, [user.changedTime]);

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(findUserList(selected * users.limit, users.limit, users.search));
      dispatch(setOffset(selected));
    }
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.values.accountId) {
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(users).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
  };

  return (
    <ContentWrap size={size} title={hiddenTitle ? '' : '사용자 목록'} minHeight={minHeight}>
      <SearchWrap>
        <Row>
          <Col sm={11}>
            <FormGroup row className={'mb10'}>
              <Label for="roleSV" sm={1}>
                구분
              </Label>
              <Col sm={3}>
                <Input
                  type="select"
                  name="search.role"
                  id="roleSV"
                  value={users.search.role}
                  onChange={onChange}
                  disabled={disableSearchInputs && disableSearchInputs['role'] ? 'disable' : null}
                >
                  <option value={''}>선택하세요</option>
                  <option value={'Student'}>수강생</option>
                  <option value={'Teacher'}>강사</option>
                  <option value={'Administrator'}>관리자</option>
                </Input>
              </Col>
              <Label for="statusSV" sm={1}>
                상태
              </Label>
              <Col sm={3}>
                <Input
                  type="select"
                  name="search.status"
                  id="statusSV"
                  value={users.search.status}
                  onChange={onChange}
                  disabled={disableSearchInputs && disableSearchInputs['status'] ? 'disable' : null}
                >
                  <option value={''}>선택하세요</option>
                  <option value={'Active'}>활성</option>
                  <option value={'Stop'}>비활성</option>
                  <option value={'PasswordReset'}>비밀번호초기화</option>
                </Input>
              </Col>
              <Label for="phoneNoSV" sm={1}>
                휴대전화
              </Label>
              <Col sm={3}>
                <Input
                  type="text"
                  name="search.phoneNo"
                  id="phoneNoSV"
                  value={users.search.phoneNo}
                  onChange={onChange}
                  placeholder="휴대전화"
                  disable={disableSearchInputs && disableSearchInputs['phoneNo'] ? 'disable' : null}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="loginIdSV" sm={1}>
                ID
              </Label>
              <Col sm={3}>
                <Input
                  type="text"
                  name="search.loginId"
                  id="loginIdSV"
                  value={users.search.loginId}
                  onChange={onChange}
                  placeholder="ID"
                  disabled={disableSearchInputs && disableSearchInputs['loginId'] ? 'disable' : null}
                />
              </Col>
              <Label for="userNameSV" sm={1}>
                이름
              </Label>
              <Col sm={3}>
                <Input
                  type="text"
                  name="search.userName"
                  id="userNameSV"
                  value={users.search.userName}
                  onChange={onChange}
                  placeholder="이름"
                  disabled={disableSearchInputs && disableSearchInputs['loginId'] ? 'disable' : null}
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={1} className={'button-col'}>
            <Button
              color={'success'}
              onClick={async (e) => {
                e.preventDefault();
                await setSelectPageIndex(0);
                await dispatch(setOffset(0));
                await dispatch(findUserList(0, users.limit, users.search));
              }}
            >
              검색
            </Button>
          </Col>
        </Row>
      </SearchWrap>
      <PagingDataTable
        columns={columns}
        data={users.users}
        pagination={true}
        handlePageClick={handlePageClick}
        getRowProps={onRowClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(users.totalCount / users.limit)}
        limit={users.limit}
      />
    </ContentWrap>
  );
};

export default UserList;
