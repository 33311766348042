import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, genderKr } from '../../lib/utils/format';
import { clearEnrollmentList, findEnrollmentList, setSelectedRowDatas } from '../../modules/enrollments';
import { useAlert } from 'react-alert';
import ModalPopup from '../../components/layout/ModalPopup';
import EnrollmentForm from './EnrollmentForm';
import UserList from '../user/UserList';
import { Row } from 'reactstrap';

const EnrollmentList = ({ size }) => {
  const dispatch = useDispatch();
  const { enrollments, enrollment, courses, error, loading } = useSelector(
    ({ enrollments, enrollment, courses, error, loading }) => ({
      enrollments: enrollments,
      enrollment: enrollment,
      courses: courses,
      error: error,
      loading: loading['course/FIND_LECTURE_LIST'],
    }),
  );
  const [selectRowIndex, setSelectRowIndex] = useState(-1);
  const [enrollmentDetailModal, setEnrollmentDetailModal] = useState(false);
  const [formType, setFormType] = useState('NEW_TYPE');

  const alert = useAlert();

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'studentId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '순번',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => {
          return <div>{props.row.index + 1}</div>;
        },
      },
      {
        Header: '이름',
        accessor: 'userName',
        className: 'c',
        Cell: (props) => <div> {props.value ? props.value : null} </div>,
      },
      {
        Header: '생년월일',
        accessor: 'birthDate',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: '성별',
        accessor: 'gender',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div> {props.value ? genderKr(props.value) : null} </div>,
      },
      {
        Header: '신청 일자',
        accessor: 'registrationDate',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearEnrollmentList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (courses.selectedRowDatas.courseId) {
      dispatch(findEnrollmentList(enrollments.offset, enrollments.limit, courses.selectedRowDatas.courseId));
    } else {
      dispatch(clearEnrollmentList());
    }
  }, [courses.selectedRowDatas, enrollment.changedTime]);

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '수강생 강좌 등록',
        onClickFn: (e) => {
          e.preventDefault();
          setEnrollmentDetailModal(!enrollmentDetailModal);
          setFormType('NEW_TYPE');
        },
      },
    ],
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.values.studentId) {
          setEnrollmentDetailModal(!enrollmentDetailModal);
          setSelectRowIndex(row.index);
          dispatch(setSelectedRowDatas(row));
          setFormType('DETAIL_TYPE');
        }
      },
    };
  };

  return (
    <ContentWrap
      size={size}
      title={'수강생 명단'}
      buttonDatas={courses.selectedRowDatas.courseId ? topButtonData : null}
    >
      <PagingDataTable
        columns={columns}
        data={enrollments.enrollments}
        selectRowIndex={selectRowIndex}
        pageIndex={0}
        getRowProps={onRowClick}
        limit={enrollments.limit}
      />

      <ModalPopup
        title={formType === 'NEW_TYPE' ? '수강생 등록' : formType === 'DETAIL_TYPE' ? '수강생 상세' : null}
        inOpen={enrollmentDetailModal}
        toggleFn={() => {
          setEnrollmentDetailModal(!enrollmentDetailModal);
        }}
        width={formType === 'NEW_TYPE' ? 1400 : 400}
        hiddenBottomArea={true}
      >
        <Row>
          {formType === 'NEW_TYPE' ? (
            <UserList
              size={9}
              disableSearchInputs={{ role: true }}
              initSearchValue={{ role: 'Student' }}
              hiddenTitle={true}
              minHeight={'0'}
            />
          ) : null}
          <EnrollmentForm
            size={formType === 'NEW_TYPE' ? 3 : 12}
            formType={formType}
            callBackFn={() => {
              setEnrollmentDetailModal(!enrollmentDetailModal);
            }}
          />
        </Row>
      </ModalPopup>
    </ContentWrap>
  );
};

export default EnrollmentList;
