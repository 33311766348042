import React, { useEffect, useState } from 'react';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { clearRollBookList, closeAttendance, findRollBookList, setSelectedRowDatas } from '../../modules/rollBooks';
import { convertOpeningTime, convertPercent, formatDate } from '../../lib/utils/format';
import { Badge } from 'reactstrap';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { clearLoading } from '../../modules/loading';
import { useAlert } from 'react-alert';

const RollBookList = ({}) => {
  const dispatch = useDispatch();
  const { rollBooks, rollBookCourses, rollBook, error, loading, closeAttendanceLoading } = useSelector(
    ({ rollBooks, rollBookCourses, rollBook, error, loading }) => ({
      rollBooks: rollBooks,
      rollBookCourses: rollBookCourses,
      rollBook: rollBook,
      error: rollBooks.error,
      loading: loading['rollBook/FIND_ROLL_BOOK_LIST'],
      closeAttendanceLoading: loading['rollBooks/CLOSE_ATTENDANCE'],
    }),
  );
  const alert = useAlert();

  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'courseId',
        style: {
          display: 'none',
        },
      },
      {
        Header: 'hidden',
        accessor: 'lectureId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '강의일자',
        accessor: 'lectureDate',
        className: 'c',
        Cell: (props) => (
          <div>
            {props.row.original.cancelled ? (
              <Badge color="secondary">휴</Badge>
            ) : props.row.original.supplementary ? (
              <Badge color="info">보</Badge>
            ) : (
              ''
            )}{' '}
            {formatDate(props.value)}{' '}
          </div>
        ),
      },
      {
        Header: '강의시간',
        accessor: 'lectureTime',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {props.value ? convertOpeningTime(props.value) : null} </div>,
      },
      {
        Header: '출석',
        accessor: 'attendanceSummary.present',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div>{!props.row.original.cancelled ? props.value : ''}</div>,
      },
      {
        Header: '지각',
        accessor: 'attendanceSummary.late',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div>{!props.row.original.cancelled ? props.value : ''}</div>,
      },
      {
        Header: '결석',
        accessor: 'attendanceSummary.absent',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div>{!props.row.original.cancelled ? props.value : ''}</div>,
      },
      {
        Header: '미입력',
        accessor: 'attendanceSummary.unmarked',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => <div>{!props.row.original.cancelled ? props.value : ''}</div>,
      },
      {
        Header: '출석율',
        accessor: 'attendanceSummary.attendanceRate',
        className: 'c',
        style: {
          width: '60px',
        },
        Cell: (props) => (
          <div>{!props.row.original.cancelled ? (props.value ? convertPercent(props.value) : '0%') : ''}</div>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearRollBookList());
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (closeAttendanceLoading && closeAttendanceLoading.completed) {
      if (rollBooks.result) {
        alert.success('선택하신 강좌의 출결 현황을 마감처리하였습니다.');
      } else {
        alert.error('출결 현황을 마감처리하는 중 오류가 발생하였습니다.');
      }

      dispatch(clearLoading(closeAttendanceLoading.type));
    }
  }, [closeAttendanceLoading]);

  useEffect(() => {
    setSelectRowIndex(-1);
    if (rollBookCourses.selectedRowDatas && rollBookCourses.selectedRowDatas.courseId) {
      dispatch(findRollBookList(rollBooks.offset, rollBooks.limit, rollBookCourses.selectedRowDatas.courseId));
    } else {
      dispatch(clearRollBookList());
    }
  }, [rollBookCourses.selectedRowDatas]);

  useEffect(() => {
    dispatch(findRollBookList(rollBooks.offset, rollBooks.limit, rollBookCourses.selectedRowDatas.courseId));
  }, [rollBook.changedTime]);

  const attendanceCloseBtn = {
    label: '출결마감',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('선택하신 강좌의 출결 현황을 마감처리하시겠습니까?')) {
        dispatch(closeAttendance(rollBookCourses.selectedRowDatas.courseId));
      }
    },
  };

  const btnData = {
    isHistoryBack: false,
    data: [attendanceCloseBtn],
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (!row.original.cancelled && row.values.lectureId) {
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  return (
    <>
      <PagingDataTable
        columns={columns}
        data={rollBooks.rollBooks}
        selectRowIndex={selectRowIndex}
        pageIndex={0}
        getRowProps={onRowClick}
        limit={rollBooks.limit}
      />
      {rollBookCourses.selectedRowDatas && rollBookCourses.selectedRowDatas.courseId ? (
        <FooterButtonGroup buttonData={btnData}></FooterButtonGroup>
      ) : null}
    </>
  );
};

export default RollBookList;
