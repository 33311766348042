import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as enrollmentAPI from '../lib/api/enrollment';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const [
  FIND_ENROLLMENT_LIST,
  FIND_ENROLLMENT_LIST_SUCCESS,
  FIND_ENROLLMENT_LIST_FAILURE,
] = createRequestActionTypes('enrollments/FIND_ENROLLMENT_LIST');

const CHANGE_FIELD = 'enrollments/CHANGE_FIELD';
const CLEAR_ENROLLMENT_LIST = createRequestActionTypes(
  'enrollments/CLEAR_ENROLLMENT_LIST',
);

const SET_SELETED_ROW_DATAS = 'enrollments/SET_SELETED_ROW_DATAS';

export const findEnrollmentList = createAction(
  FIND_ENROLLMENT_LIST,
  (offset, limit, courseId) => {
    return { offset, limit, courseId };
  },
);

export const clearEnrollmentList = createAction(CLEAR_ENROLLMENT_LIST);
export const setSelectedRowDatas = createAction(
  SET_SELETED_ROW_DATAS,
  (row) => row,
);

// saga 생성
const listEnrollmentsSaga = createRequestSaga(
  FIND_ENROLLMENT_LIST,
  enrollmentAPI.findEnrollmentList,
);

export function* enrollmentsSaga() {
  yield takeLatest(FIND_ENROLLMENT_LIST, listEnrollmentsSaga);
}

const initialState = {
  enrollments: [],
  error: null,
  offset: 0,
  limit: 1000,
  totalCount: 0,
  selectedRowDatas: {},
};

const enrollments = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_ENROLLMENT_LIST_SUCCESS]: (state, { payload: enrollments }) => {
      return {
        ...state,
        enrollments: enrollments,
        totalCount: enrollments.length,
        selectedRowDatas: {},
      };
    },

    [FIND_ENROLLMENT_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_ENROLLMENT_LIST]: () => initialState,

    [SET_SELETED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: { studentId: row.values['studentId'] },
      };
    },
  },
  initialState,
);

export default enrollments;
