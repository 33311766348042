import React from 'react';
import { Button } from 'reactstrap';
import Styled from 'styled-components';
/*
    jsonData로 버튼 생성
    buttonData = {
        isHistoryBack: false,
        data :[
            {label: text, type: "success", onClickFn: fn()}
            ,{label: text, onclick: fn()}
        ]
    }

    type = ['primary','secondary','success','info','warning','danger','link']
    isHistoryBack : true - 왼쪽에 목록으로 버튼 유무
 */

const ModalButtonGroupWrapper = Styled.div`
    padding: 0 12px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    .leftGroup .rightGroup{
        text-align: center;
        align-items: center;
    }
    .rightGroup{
        button{
        margin-left:12px;
        }
    }
`;

const ModalButtonGroup = ({ toggleFn, buttonData }) => {
  return (
    <ModalButtonGroupWrapper>
      <div className={'leftGroup'}>
        <button
          className={'btn btn-secondary'}
          color={'secondary'}
          onClick={toggleFn}
        >
          닫기
        </button>
      </div>
      <div className={'rightGroup'}>
        {buttonData
          ? Object.values(buttonData.data).map((btn, index) => {
              return (
                <Button
                  key={'ModalButton' + index}
                  className={'btn btn-' + btn.type}
                  color={btn.type}
                  onClick={btn.onClickFn}
                >
                  {btn.label}
                </Button>
              );
            })
          : null}
      </div>
    </ModalButtonGroupWrapper>
  );
};

export default ModalButtonGroup;
