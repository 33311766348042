import moment from 'moment';

const roleKr = (value) => {
  if (value === 'Administrator') return '관리자';
  else if (value === 'Student') return '수강생';
  else if (value === 'Teacher') return '강사';
  return '';
};

const statusKr = (value) => {
  if (value === 'Active') return '활성';
  else if (value === '') return '비활성';
  else if (value === 'PasswordReset') return '비밀번호초기화';
  return '';
};

const genderKr = (value) => {
  if (value === 'Man') return '남자';
  else if (value === 'Woman') return '여자';
  return '';
};

const weekKr = (value) => {
  if (value === 'Monday') return '월';
  else if (value === 'Tuesday') return '화';
  else if (value === 'Wednesday') return '수';
  else if (value === 'Thursday') return '목';
  else if (value === 'Friday') return '금';
  else if (value === 'Saturday') return '토';
  else if (value === 'Sunday') return '일';
  return '';
};

const weekDetailKr = (value) => {
  if (value === 'Monday') return '월요일';
  else if (value === 'Tuesday') return '화요일';
  else if (value === 'Wednesday') return '수요일';
  else if (value === 'Thursday') return '목요일';
  else if (value === 'Friday') return '금요일';
  else if (value === 'Saturday') return '토요일';
  else if (value === 'Sunday') return '일요일';
  return '';
};

//type week,weekKr,weekDetailKr
const getWeek = (date, type) => {
  const weeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  if (type === 'week') {
    return weeks[moment(date).day()];
  } else if (type === 'weekKr') {
    return weekKr(weeks[moment(date).day()]);
  } else if (type === 'weekKr') {
    return weekDetailKr(weeks[moment(date).day()]);
  }
  return weeks[moment(date).day()];
};

const openingTermKr = (value) => {
  if (value === 'FirstHalf') return '상반기';
  else if (value === 'SecondHalf') return '하반기';
  return '';
};

const confirmedKr = (value) => {
  if (value === 'Confirmed') return '확정';
  else if (value === 'Unconfirmed') return '미확정';
  return '';
};

const attendanceStatusKr = (value) => {
  if (value === 'Present') return '출석';
  else if (value === 'Absent') return '결석';
  else if (value === 'Late') return '지각';
  else if (value === 'Unmarked') return '';
  return '';
};

const convertOpeningTime = (openingTime) => {
  let openingTimeText = '';
  openingTimeText += zeroPadding(openingTime.startHour);
  openingTimeText += ':';
  openingTimeText += zeroPadding(openingTime.startMinute);
  openingTimeText += ' ~ ';
  openingTimeText += zeroPadding(openingTime.endHour);
  openingTimeText += ':';
  openingTimeText += zeroPadding(openingTime.endMinute);

  return openingTimeText;
};
const zeroPadding = (value) => {
  return value < 10 ? '0' + value : value;
};

const colonStyleTime = (hour, minutes) => {
  if (hour == null || minutes == null) {
    return '';
  }

  return zeroPadding(hour) + ':' + zeroPadding(minutes);
};

const formatPhoneNo = (phoneNoStr) => {
  if (phoneNoStr) {
    const _phoneNoStr = phoneNoStr.replace(/[^0-9]/g, '');
    return _phoneNoStr.replace(/[^0-9]/g, '').replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');
  }
  return phoneNoStr;
};

const formatDate = (dateStr) => {
  if (dateStr) {
    const _dateStr = dateStr.replace(/[^0-9]/g, '');
    if (_dateStr.length == 8) {
      return _dateStr.substr(0, 4) + '-' + _dateStr.substr(4, 2) + '-' + _dateStr.substr(6, 2);
    }
  }
  return dateStr;
};

const formatTimeInput = (time, previous) => {
  let _time = time.replace(/[^0-9]/g, '');
  if (_time.length > 4) {
    _time = _time.substr(0, 4);
  }

  let formattedTime = '';
  if (_time.length < 2) {
    formattedTime = _time;
  } else if (_time.length == 2) {
    if (previous.indexOf(':') > 0) {
      formattedTime = _time.substr(0, 2);
    } else {
      formattedTime = _time.substr(0, 2) + ':';
    }
  } else {
    formattedTime = _time.substr(0, 2) + ':' + _time.substr(2);
  }

  return formattedTime;
};

const convertCourseSchedule = (courseSchedule, isDetail) => {
  let schedule = '';
  isDetail
    ? (schedule = weekKr(courseSchedule.openingWeek) + ' ' + convertOpeningTime(courseSchedule.openingTime))
    : (schedule = weekDetailKr(courseSchedule.openingWeek) + ' ' + convertOpeningTime(courseSchedule.openingTime));
  return schedule;
};

const convertPercent = (data) => {
  return Math.floor(data * 100) + '%';
};

export {
  roleKr,
  statusKr,
  genderKr,
  weekKr,
  weekDetailKr,
  openingTermKr,
  attendanceStatusKr,
  convertOpeningTime,
  formatTimeInput,
  convertCourseSchedule,
  zeroPadding,
  colonStyleTime,
  confirmedKr,
  getWeek,
  formatPhoneNo,
  formatDate,
  convertPercent,
};
