import React, { useEffect } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import { useDispatch, useSelector } from 'react-redux';
import { findCourseSelectList, changeField, clearCourseSelectList } from '../../modules/coursesSelect';
import { Col, FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { fromJS } from 'immutable';
import { getSerachOpeningYearOptions } from '../../lib/utils/util';
import FormWrap from '../../components/layout/FormWrap';

const CourseSelectList = ({ size }) => {
  const dispatch = useDispatch();
  const { coursesSelect, error, loading } = useSelector(({ coursesSelect, error, loading }) => ({
    coursesSelect: coursesSelect,
    error: error,
    loading: loading['courseSelect/FIND_COURSE_SELECT_LIST'],
  }));

  useEffect(() => {
    dispatch(findCourseSelectList(coursesSelect.offset, coursesSelect.limit, coursesSelect.search));
    return () => {
      dispatch(clearCourseSelectList());
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(coursesSelect).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
    dispatch(findCourseSelectList(coursesSelect.offset, coursesSelect.limit, newSearch.toJS().search));
  };

  const selectChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(coursesSelect).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
  };

  return (
    <ContentWrap size={size} minHeight={'0'} marginBottom={'12px'}>
      <FormWrap ClassName={'formWrap'} form={coursesSelect}>
        <FormGroup row className={'mb0'}>
          <Label sm={1}>개설년도</Label>
          <Col sm={2}>
            <Input type="select" name="search.openingYear" value={coursesSelect.search.openingYear} onChange={onChange}>
              {Object.values(getSerachOpeningYearOptions()).map((option, index) => {
                return (
                  <option key={'coursesSelect_openingYear' + index} value={option}>
                    {option}
                  </option>
                );
              })}
            </Input>
          </Col>
          <Label sm={1}>개설시기</Label>
          <Col sm={2}>
            <Input type="select" name="search.openingTerm" value={coursesSelect.search.openingTerm} onChange={onChange}>
              <option value={'FirstHalf'}>상반기</option>
              <option value={'SecondHalf'}>하반기</option>
            </Input>
          </Col>
          <Label sm={1}>강좌명</Label>
          <Col sm={5}>
            <Input
              type="select"
              name="selectedDatas.courseId"
              value={coursesSelect.selectedDatas.courseId}
              onChange={selectChange}
            >
              {Object.values(coursesSelect.courses).map((course, index) => {
                return (
                  <option key={'coursesSelect_course' + index} value={course.courseId}>
                    {course.courseName}
                    {course.teacherName ? ` [ ${course.teacherName} 강사 ]` : null}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>
      </FormWrap>
    </ContentWrap>
  );
};

export default CourseSelectList;
