import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createCourse,
  editCourse,
  deleteCourse,
  clearCourse,
  changeField,
  setChangedTime,
  findCourse,
  setTeacher,
  assignTeacher,
} from '../../modules/course';
import { Col, InputGroupAddon, InputGroup } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { Button, FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import { fromJS } from 'immutable';
import { useAlert } from 'react-alert';
import ContentWrap from '../../components/layout/ContentWrap';
import { getCreateOpeningYearOptions } from '../../lib/utils/util';
import UserList from '../user/UserList';
import ModalPopup from '../../components/layout/ModalPopup';
import DatePicker from '../../components/dataPicker/DatePicker';
import { format } from 'date-fns';
import moment from 'moment';
import { colonStyleTime, formatTimeInput } from '../../lib/utils/format';
import { clearLoading } from '../../modules/loading';

const CourseForm = ({ history, size }) => {
  const dispatch = useDispatch();
  const {
    course,
    courses,
    users,
    error,
    createCourseLoading,
    deleteCourseLoading,
    updateCourseLoading,
    assignTeacherLoading,
  } = useSelector(({ course, courses, users, error, loading }) => ({
    course: course,
    courses: courses,
    users: users,
    error: course.error,
    createCourseLoading: loading['course/CREATE_COURSE'],
    deleteCourseLoading: loading['course/DELETE_COURSE'],
    updateCourseLoading: loading['course/UPDATE_COURSE'],
    assignTeacherLoading: loading['course/ASSIGN_TEACHER'],
  }));
  const alert = useAlert();
  const [teacherModal, setTeacherModal] = useState(false);
  //NEW_TYPE : 신규(초기화,신규)
  //DETAIL_TYPE:상세(삭제, 비밀번호 초기화, 수정)
  //EDIT_TYPE: 수정(취소,서장)
  const [formType, setFormType] = useState('NEW_TYPE');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');

  useEffect(() => {
    return () => {
      initCourse();
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (course.courseSchedule.datePeriod.startDate) {
      setStartDate(moment(course.courseSchedule.datePeriod.startDate, 'YYYYMMDD').toDate());
    }
    if (course.courseSchedule.datePeriod.endDate) {
      setEndDate(moment(course.courseSchedule.datePeriod.endDate, 'YYYYMMDD').toDate());
    }
  }, [course.courseSchedule.datePeriod]);

  useEffect(() => {
    const { startHour, startMinute, endHour, endMinute } = course.courseSchedule.openingTime;

    if (startHour !== null) {
      setStartTime(colonStyleTime(startHour, startMinute));
    }

    if (endHour !== null) {
      setEndTime(colonStyleTime(endHour, endMinute));
    }
  }, [course.courseSchedule.openingTime]);

  useEffect(() => {
    if (courses.selectedRowDatas.courseId) {
      dispatch(findCourse(courses.selectedRowDatas.courseId));
      setFormType('DETAIL_TYPE');
    } else {
      initCourse();
    }
  }, [courses.selectedRowDatas]);

  useEffect(() => {
    if (createCourseLoading && createCourseLoading.completed) {
      if (course.courseId) {
        checkAssignTeacher();
        alert.success('강좌를 등록 하였습니다.');
        dispatch(setChangedTime(new Date()));
        initCourse();
      } else {
        alert.error('강좌 등록에 실패하였습니다.');
      }
      dispatch(clearLoading(createCourseLoading.type));
    }
  }, [createCourseLoading]);

  useEffect(() => {
    if (updateCourseLoading && updateCourseLoading.completed) {
      checkAssignTeacher();
      alert.success('강좌 정보를 수정 하였습니다.');

      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(updateCourseLoading.type));
    }
  }, [updateCourseLoading]);

  useEffect(() => {
    if (assignTeacherLoading && assignTeacherLoading.completed) {
      setFormType('DETAIL_TYPE');
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(assignTeacherLoading.type));
    }
  }, [assignTeacherLoading]);

  useEffect(() => {
    if (deleteCourseLoading && deleteCourseLoading.completed) {
      alert.success('강좌를 삭제 하였습니다.');
      initCourse();

      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(deleteCourseLoading.type));
    }
  }, [deleteCourseLoading]);

  const checkAssignTeacher = async () => {
    if (course.selectedTeacherAccountId && course.teacherAccountId !== course.selectedTeacherAccountId) {
      dispatch(
        await assignTeacher({
          courseId: course.courseId,
          teacherAccountId: course.selectedTeacherAccountId,
        }),
      );
    } else {
      setFormType('DETAIL_TYPE');
      dispatch(setChangedTime(new Date()));
    }
  };

  const clearBtn = {
    label: '초기화',
    type: 'secondary',
    onClickFn: () => {
      initCourse();
    },
  };
  const createBtn = {
    label: '신규등록',
    type: 'success',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('등록하시겠습니까?')) {
        checkForm('NEW', async () => {
          dispatch(await createCourse(course));
        });
      }
    },
  };
  const deleteBtn = {
    label: '삭제',
    type: 'danger',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('삭제하시겠습니까?')) {
        dispatch(deleteCourse(course.courseId));
      }
    },
  };
  const editBtn = {
    label: '수정',
    type: 'info',
    onClickFn: (e) => {
      e.preventDefault();
      setFormType('EDIT_TYPE');
    },
  };
  const cancelBtn = {
    label: '취소',
    type: 'secondary',
    onClickFn: (e) => {
      e.preventDefault();
      setFormType('DETAIL_TYPE');
      dispatch(findCourse(courses.selectedRowDatas.courseId));
    },
  };
  const saveBtn = {
    label: '저장',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('저장하시겠습니까?')) {
        checkForm('EDIT', async () => {
          dispatch(await editCourse(course));
        });
      }
    },
  };

  const btnData = {
    isHistoryBack: false,
    NEW_TYPE: [clearBtn, createBtn],
    DETAIL_TYPE: [deleteBtn, editBtn],
    EDIT_TYPE: [cancelBtn, saveBtn],
  };

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '강좌등록',
        onClickFn: (e) => {
          e.preventDefault();
          initCourse();
          setFormType('NEW_TYPE');
        },
      },
    ],
  };

  const modalSelectBtn = {
    label: '선택',
    type: 'success',
    onClickFn: () => {
      dispatch(setTeacher(users.selectedRowDatas));
      setTeacherModal(!teacherModal);
    },
  };
  const modalButtonData = { data: [modalSelectBtn] };

  const checkForm = (type, callbackFn) => {
    //type NEW - 신규 EDIT - 수정
    if (!course.categoryName) {
      alert.error('분류는 필수값 입니다.');
      return false;
    }

    if (!course.courseName) {
      alert.error('강좌명은 필수값 입니다.');
      return false;
    }

    callbackFn();
  };

  const initCourse = () => {
    dispatch(clearCourse());
    setStartDate(null);
    setEndDate(null);
    setStartTime(0 + ':' + 0);
    setEndTime(0 + ':' + 0);
    setFormType('NEW_TYPE');
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    changeFields(e.target);
  };

  const changeFields = ({ name, value }) => {
    let names = name.split('.');
    const newCourse = fromJS(course).setIn(names, value);

    dispatch(changeField(newCourse.toJS()));
  };

  const onOpeningTimeBlur = (time, hourPropName, minutePropName) => {
    const value = time.replace(/[^0-9]/g, '');
    let hour = 0;
    let minutes = 0;
    if (value.length >= 2) {
      hour = parseInt(value.substr(0, 2), 10);
      if (value.length > 2) {
        minutes = parseInt(value.substr(2), 10);
      }
    }

    const newCourse = fromJS(course)
      .setIn(['courseSchedule', 'openingTime', hourPropName], hour)
      .setIn(['courseSchedule', 'openingTime', minutePropName], minutes);

    dispatch(changeField(newCourse.toJS()));
  };

  return (
    <ContentWrap
      size={size}
      title={
        formType === 'NEW_TYPE'
          ? '강좌등록'
          : formType === 'DETAIL_TYPE'
          ? '강좌상세'
          : formType === 'EDIT_TYPE'
          ? '강좌수정'
          : null
      }
      buttonDatas={formType !== 'NEW_TYPE' ? topButtonData : null}
    >
      <FormWrap ClassName={'formWrap'} form={course}>
        <FormGroup row>
          <Label for="openingYear" sm={3}>
            개설년도
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType !== 'NEW_TYPE' ? 'disabled' : null}
              type="select"
              name="courseSchedule.openingYear"
              id="openingYear"
              value={course.courseSchedule.openingYear}
              onChange={onChange}
            >
              {Object.values(getCreateOpeningYearOptions()).map((option, index) => {
                return (
                  <option key={'FORM_openingYear' + index} value={option}>
                    {option}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="openingYear" sm={3}>
            개설시기
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType !== 'NEW_TYPE' ? 'disabled' : null}
              type="select"
              name="courseSchedule.openingTerm"
              id="openingTerm"
              value={course.courseSchedule.openingTerm}
              onChange={onChange}
            >
              <option value={'FirstHalf'}>상반기</option>;<option value={'SecondHalf'}>하반기</option>;
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="categoryName" sm={3}>
            분류
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="categoryName"
              id="categoryName"
              placeholder="분류를 입력하세요."
              value={course.categoryName}
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for="courseName" sm={3}>
            강좌명
          </Label>
          <Col sm={9}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="courseName"
              id="courseName"
              placeholder="강좌명을 입력하세요."
              value={course.courseName}
              onChange={onChange}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>담당강사</Label>
          <Col sm={9}>
            <InputGroup>
              <Input
                type="hidden"
                name="teacherAccountId"
                id="teacherAccountId"
                value={course.teacherAccountId}
                onChange={onChange}
              />
              <Input
                type="hidden"
                name="selectedTeacherAccountId"
                id="selectedTeacherAccountId"
                value={course.selectedTeacherAccountId}
                onChange={onChange}
              />
              <Input
                type="text"
                name="teacherName"
                id="teacherName"
                value={course.teacherName}
                onChange={onChange}
                disabled="disabled"
              />
              {formType !== 'DETAIL_TYPE' ? (
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={() => {
                      setTeacherModal(!teacherModal);
                    }}
                  >
                    강사 검색
                  </Button>
                </InputGroupAddon>
              ) : null}
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>기간</Label>
          <Col sm={4}>
            <DatePicker
              selected={startDate}
              disabled={formType === 'DETAIL_TYPE' ? true : false}
              customInput={<Input type="text" />}
              onChange={(date) => {
                changeFields({
                  name: 'courseSchedule.datePeriod.startDate',
                  value: format(date, 'yyyyMMdd'),
                });

                setStartDate(date);
              }}
            />
          </Col>
          <Label sm={1}>~</Label>
          <Col sm={4}>
            <DatePicker
              selected={endDate}
              disabled={formType === 'DETAIL_TYPE' ? true : false}
              customInput={<Input type="text" readOnly />}
              onChange={(date) => {
                changeFields({
                  name: 'courseSchedule.datePeriod.endDate',
                  value: format(date, 'yyyyMMdd'),
                });

                setEndDate(date);
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>시간</Label>
          <Col sm={3}>
            <Input
              type="select"
              name="courseSchedule.openingWeek"
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              value={course.courseSchedule.openingWeek}
              onChange={onChange}
            >
              <option value="Monday">월</option>
              <option value="Tuesday">화</option>
              <option value="Wednesday">수</option>
              <option value="Thursday">목</option>
              <option value="Friday">금</option>
              <option value="Saturday">토</option>
              <option value="Sunday">일</option>
            </Input>
          </Col>
          <Col sm={3}>
            <Input
              type="text"
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              value={startTime}
              onBlur={(e) => {
                onOpeningTimeBlur(e.target.value, 'startHour', 'startMinute');
              }}
              onChange={(e) => {
                setStartTime(formatTimeInput(e.target.value, startTime));
              }}
            />
          </Col>
          <Col sm={3}>
            <Input
              type="text"
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              value={endTime}
              onBlur={(e) => {
                onOpeningTimeBlur(e.target.value, 'endHour', 'endMinute');
              }}
              onChange={(e) => {
                setEndTime(formatTimeInput(e.target.value, endTime));
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          {/*
          <Label for="categoryName" sm={3}>
            총 차시
          </Label>
          <Col sm={3}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="courseSchedule.totalLectures"
              placeholder="강의 총 차시"
              value={course.courseSchedule.totalLectures}
              onChange={onChange}
            />
          </Col>
          */}
          <Label for="categoryName" sm={3}>
            정원
          </Label>
          <Col sm={3}>
            <Input
              disabled={formType === 'DETAIL_TYPE' ? 'disabled' : null}
              type="text"
              name="quota.maximumStudents"
              placeholder="정원"
              value={course.quota.maximumStudents}
              onChange={onChange}
            />
          </Col>
        </FormGroup>
      </FormWrap>

      <FooterButtonGroup
        buttonData={{
          isHistoryBack: btnData.isHistoryBack,
          data: btnData[formType],
        }}
        history={history}
      />

      <ModalPopup
        title={'강사 검색'}
        inOpen={teacherModal}
        toggleFn={() => {
          setTeacherModal(!teacherModal);
        }}
        width={960}
        buttonData={modalButtonData}
      >
        <UserList
          initSearchValue={{ role: 'Teacher' }}
          size={12}
          disableSearchInputs={{ role: true }}
          hiddenTitle={true}
          minHeight={'0'}
        />
      </ModalPopup>
    </ContentWrap>
  );
};

export default CourseForm;
