import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseBoardAPI from '../lib/api/courseBoard';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const [FIND_BOARD_LIST, FIND_BOARD_LIST_SUCCESS, FIND_BOARD_LIST_FAILURE] = createRequestActionTypes(
  'posts/FIND_BOARD_LIST',
);

const [FIND_POST_LIST, FIND_POST_LIST_SUCCESS, FIND_POST_LIST_FAILURE] = createRequestActionTypes(
  'posts/FIND_POST_LIST',
);

const CHANGE_FIELD = 'posts/CHANGE_FIELD';
const CLEAR_POST_LIST = createRequestActionTypes('posts/CLEAR_POST_LIST');
const SET_OFFSET = 'posts/SET_OFFSET';
const SET_SELECTED_ROW_DATAS = createRequestActionTypes('posts/SET_SELECTED_ROW_DATAS');

export const findBoardList = createAction(FIND_BOARD_LIST, (openingYear, openingTerm) => {
  return {
    offset: 0,
    limit: 999,
    search: {
      openingYear,
      openingTerm,
    },
  };
});

export const changeField = createAction(CHANGE_FIELD, (posts) => posts);
export const findPostList = createAction(FIND_POST_LIST, (offset, limit, search) => {
  return { offset, limit, search };
});
export const clearPostList = createAction(CLEAR_POST_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setSelectedRowDatas = createAction(SET_SELECTED_ROW_DATAS, (row) => row);

// saga 생성
const findBoardListSaga = createRequestSaga(FIND_BOARD_LIST, courseBoardAPI.findBoardList);
const findPostListSaga = createRequestSaga(FIND_POST_LIST, courseBoardAPI.findPostList);

export function* postsSaga() {
  yield takeLatest(FIND_BOARD_LIST, findBoardListSaga);
  yield takeLatest(FIND_POST_LIST, findPostListSaga);
}

const initialState = {
  //
  boards: [],
  posts: [],
  error: null,
  offset: 0,
  limit: 15,
  totalCount: 0,
  search: {
    boardId: '',
    writerName: '',
    subjectKeyword: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const posts = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },
    [FIND_BOARD_LIST_SUCCESS]: (state, { payload: boards }) => {
      return { ...state, boards: boards };
    },

    [FIND_BOARD_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      boards: [],
      error,
    }),

    [FIND_POST_LIST_SUCCESS]: (state, { payload: posts, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (posts.length !== state.limit) {
        let addRowCount = state.limit - posts.length;
        for (let i = 0; i < addRowCount; i++) {
          posts.push({});
        }
      }

      return { ...state, posts: posts, selectedRowDatas: {} };
    },

    [FIND_POST_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      posts: [],
      error,
    }),

    [CLEAR_POST_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),

    [SET_SELECTED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          postId: row.original.postId,
        },
      };
    },
  },

  initialState,
);

export default posts;
