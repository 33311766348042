import React from 'react';
import Styled from 'styled-components';
import { Form } from 'reactstrap';

const FormWrapper = Styled(Form)`
  padding: 12px;
  width:100%;
  border: 1px solid #dfdfdf;
  background-color: #fafafa;
  border-radius: 6px;
  
  .row{
    min-height:42px;
    align-items: center;
  
    label{
      text-align: center;
      font-weight: bold;
    }
  }
`;

const FormWrap = ({ children, className }) => {
  return <FormWrapper className={className}>{children}</FormWrapper>;
};

export default FormWrap;
