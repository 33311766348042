import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';

//
export const findServicePreferencesList = () => {
  return client.get(`${api}/servicePreferences`);
};

export const savePreferredProperty = (serviceId, propertyKey, value) => {
  return client.put(`${api}/servicePreferences/${serviceId}/properties/${propertyKey}`, {
    value: value,
  });
};

export const removePreferredProperty = (serviceId, propertyKey) => {
  return client.delete(`${api}/servicePreferences/${serviceId}/properties/${propertyKey}`);
};
