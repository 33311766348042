import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import * as classnames from 'classnames';

import * as preferencesAPI from '../../lib/api/preferences';
import PropertyList from './PropertyList';

const Preferences = ({ callBackFn }) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  //
  const [servicePreferencesList, setServicePreferencesList] = useState([]);

  useEffect(() => {
    preferencesAPI.findServicePreferencesList().then((response) => {
      setServicePreferencesList(response.data);
    });
  }, []);

  useEffect(() => {
    if (servicePreferencesList.length > 0) {
      setActiveTab(servicePreferencesList[0].namespace);
    }
  }, [servicePreferencesList]);

  return (
    <>
      <Nav tabs>
        {servicePreferencesList.map((preferences, i) => (
          <NavItem key={i}>
            <NavLink
              className={classnames({
                active: activeTab === preferences.namespace,
              })}
              onClick={() => {
                toggle(preferences.namespace);
              }}
            >
              {preferences.serviceName}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {servicePreferencesList.map((preferences, i) => (
          <TabPane key={i} tabId={preferences.namespace}>
            <PropertyList
              namespace={preferences.namespace}
              serviceId={preferences.serviceId}
              properties={preferences.properties}
            />
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};

export default Preferences;
