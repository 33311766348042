import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseBoardAPI from '../lib/api/courseBoard';
import createRequestSaga, { createRequestActionTypes } from '../lib/createRequestSaga';

const [FIND_COMMENT_LIST, FIND_COMMENT_LIST_SUCCESS, FIND_COMMENT_LIST_FAILURE] = createRequestActionTypes(
  'comments/FIND_COMMENT_LIST',
);
const CLEAR_COMMENT_LIST = createRequestActionTypes('comments/CLEAR_COMMENT_LIST');
const SET_OFFSET = 'comments/SET_OFFSET';
const SET_SELECTED_ROW_DATAS = createRequestActionTypes('comments/SET_SELECTED_ROW_DATAS');

export const findCommentList = createAction(FIND_COMMENT_LIST, (offset, limit, search) => {
  return { offset, limit, search };
});
export const clearCommentList = createAction(CLEAR_COMMENT_LIST);
export const setOffset = createAction(SET_OFFSET);
export const setSelectedRowDatas = createAction(SET_SELECTED_ROW_DATAS, (row) => row);

// saga 생성
const findCommentListSaga = createRequestSaga(FIND_COMMENT_LIST, courseBoardAPI.findCommentList);

export function* commentsSaga() {
  yield takeLatest(FIND_COMMENT_LIST, findCommentListSaga);
}

const initialState = {
  //
  comments: [],
  error: null,
  offset: 0,
  limit: 15,
  totalCount: 0,
  search: {
    postId: '',
    totalCountYn: 'Y',
  },
  selectedRowDatas: {},
};

const comments = handleActions(
  {
    [FIND_COMMENT_LIST_SUCCESS]: (state, { payload: comments, meta: res }) => {
      state.totalCount = parseInt(res.headers['x-sams-total-count']);
      if (comments.length !== state.limit) {
        let addRowCount = state.limit - comments.length;
        for (let i = 0; i < addRowCount; i++) {
          comments.push({});
        }
      }

      return { ...state, comments: comments, selectedRowDatas: {} };
    },

    [FIND_COMMENT_LIST_FAILURE]: (state, { payload: error }) => ({
      ...state,
      comments: [],
      error,
    }),

    [CLEAR_COMMENT_LIST]: () => initialState,

    [SET_OFFSET]: (state, { payload: selectPageIndex }) => ({
      ...state,
      offset: state.limit * selectPageIndex,
    }),

    [SET_SELECTED_ROW_DATAS]: (state, { payload: row }) => {
      return {
        ...state,
        selectedRowDatas: {
          commentId: row.original.commentId,
        },
      };
    },
  },

  initialState,
);

export default comments;
