import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as rollBookAPI from '../lib/api/rollBook';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const CHANGE_FIELD = 'rollBook/CHANGE_FIELD';

const [
  FIND_ROLL_BOOK,
  FIND_ROLL_BOOK_SUCCESS,
  FIND_ROLL_BOOK_FAILURE,
] = createRequestActionTypes('rollBook/FIND_ROLL_BOOK');

const [
  SAVE_CHECKIN_MARKING,
  SAVE_CHECKIN_MARKING_SUCCESS,
  SAVE_CHECKIN_MARKING_FAILURE,
] = createRequestActionTypes('rollBook/SAVE_CHECKIN_MARKING');

const SET_CHANGED_TIME = 'rollBook/SET_CHANGED_TIME';

const CLEAR_ROLL_BOOK = 'rollBook/CLEAR_ROLL_BOOK'; // rollBook 데이터 비우기

export const findRollBook = createAction(
  FIND_ROLL_BOOK,
  (courseId, lectureId) => {
    return { courseId, lectureId };
  },
);

export const saveCheckinMarking = createAction(
  SAVE_CHECKIN_MARKING,
  ({
    courseId,
    lectureId,
    attendeeRole,
    attendeeIds,
    channel,
    attendanceStatus,
  }) => {
    return {
      courseId,
      lectureId,
      attendeeRole,
      attendeeIds,
      channel,
      attendanceStatus,
    };
  },
);

export const clearRollBook = createAction(CLEAR_ROLL_BOOK);
export const setChangedTime = createAction(SET_CHANGED_TIME);

// saga 생성
const findRollBookSaga = createRequestSaga(
  FIND_ROLL_BOOK,
  rollBookAPI.findRollBook,
);

const saveCheckinMarkingSaga = createRequestSaga(
  SAVE_CHECKIN_MARKING,
  rollBookAPI.saveCheckinMarking,
);

export function* rollBookSaga() {
  yield takeLatest(FIND_ROLL_BOOK, findRollBookSaga);
  yield takeLatest(SAVE_CHECKIN_MARKING, saveCheckinMarkingSaga);
}

const initialState = {
  rollBook: [],
  error: null,
  offset: 0,
  limit: 1000,
  totalCount: 0,
  changedTime: new Date(),
};

const rollBook = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_ROLL_BOOK_SUCCESS]: (state, { payload: rollBook }) => {
      console.log(rollBook);
      return {
        ...state,
        rollBook: rollBook,
        totalCount: rollBook.length,
      };
    },

    [FIND_ROLL_BOOK_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SAVE_CHECKIN_MARKING_SUCCESS]: (state, { payload: data }) => {
      return {
        ...state,
      };
    },

    [SAVE_CHECKIN_MARKING_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [CLEAR_ROLL_BOOK]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default rollBook;
