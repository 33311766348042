import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearEnrollment,
  changeField,
  findEnrollment,
  editEnrollment,
  deleteEnrollment,
  createEnrollment,
  setCourseId,
  setChangedTime,
  setStudent,
} from '../../modules/enrollment';
import { Col, InputGroup } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { fromJS } from 'immutable';
import { useAlert } from 'react-alert';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import DatePicker from '../../components/dataPicker/DatePicker';
import { format } from 'date-fns';
import moment from 'moment';
import ContentWrap from '../../components/layout/ContentWrap';
import { clearLoading } from '../../modules/loading';

const EnrollmentForm = ({ history, size, formType, callBackFn }) => {
  const dispatch = useDispatch();
  const {
    enrollment,
    enrollments,
    courses,
    users,
    error,
    createEnrollmentLoading,
    findEnrollmentLoading,
    deleteEnrollmentLoading,
    updateEnrollmentLoading,
  } = useSelector(({ enrollment, enrollments, courses, users, error, loading }) => ({
    enrollment: enrollment,
    enrollments: enrollments,
    courses: courses,
    users: users,
    error: enrollment.error,
    createEnrollmentLoading: loading['enrollment/CREATE_ENROLLMENT'],
    findEnrollmentLoading: loading['enrollment/FIND_ENROLLMENT'],
    updateEnrollmentLoading: loading['enrollment/UPDATE_ENROLLMENT'],
    deleteEnrollmentLoading: loading['enrollment/DELETE_ENROLLMENT'],
  }));
  const alert = useAlert();

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const [enrollmentDate, setEnrollmentDate] = useState('');

  useEffect(() => {
    return () => {
      initEnrollment();
    };
  }, [dispatch]);

  useEffect(() => {
    if (formType === 'NEW_TYPE') {
      initEnrollment();
      //setEnrollmentDate(moment(new Date(), 'YYYYMMDD').toDate())
    }
  }, [formType]);

  useEffect(() => {
    if (courses.selectedRowDatas.courseId) {
      dispatch(setCourseId(courses.selectedRowDatas.courseId));
    } else {
      initEnrollment();
    }
  }, [courses]);

  useEffect(() => {
    if (enrollments.selectedRowDatas && formType !== 'NEW_TYPE') {
      dispatch(findEnrollment(enrollments.selectedRowDatas.studentId));
    }
  }, [enrollments.selectedRowDatas.studentId]);

  useEffect(() => {
    if (users.selectedRowDatas && formType === 'NEW_TYPE') {
      dispatch(setStudent(users.selectedRowDatas));
    }
  }, [users.selectedRowDatas]);

  useEffect(() => {
    if (findEnrollmentLoading && findEnrollmentLoading.completed) {
      if (enrollment.registrationDate) {
        setEnrollmentDate(moment(enrollment.registrationDate, 'YYYYMMDD').toDate());
      }
      dispatch(clearLoading(findEnrollmentLoading.type));
    }
  }, [findEnrollmentLoading]);

  useEffect(() => {
    if (createEnrollmentLoading && createEnrollmentLoading.completed) {
      if (enrollment.studentId) {
        alert.success('수강 신청을 등록 하였습니다.');
        callBackFn();
        dispatch(setChangedTime(new Date()));
      }
      dispatch(clearLoading(createEnrollmentLoading.type));
    }
  }, [createEnrollmentLoading]);

  useEffect(() => {
    if (updateEnrollmentLoading && updateEnrollmentLoading.completed) {
      alert.success('수강 신청을 수정 하였습니다.');
      callBackFn();
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(updateEnrollmentLoading.type));
    }
  }, [updateEnrollmentLoading]);

  useEffect(() => {
    if (deleteEnrollmentLoading && deleteEnrollmentLoading.completed) {
      if (!enrollment.studentId) {
        alert.success('수강 신청을 삭제 하였습니다.');
        callBackFn();
        dispatch(setChangedTime(new Date()));
      }
      dispatch(clearLoading(deleteEnrollmentLoading.type));
    }
    return () => {
      initEnrollment();
    };
  }, [deleteEnrollmentLoading]);

  const createBtn = {
    label: '신규등록',
    type: 'success',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('등록하시겠습니까?')) {
        checkForm(async () => {
          dispatch(await createEnrollment(enrollment));
        });
      }
    },
  };
  const deleteBtn = {
    label: '삭제',
    type: 'danger',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('삭제하시겠습니까?')) {
        checkForm(async () => {
          dispatch(deleteEnrollment(enrollment.studentId));
        });
      }
    },
  };

  const saveBtn = {
    label: '저장',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('저장하시겠습니까?')) {
        checkForm(async () => {
          dispatch(await editEnrollment(enrollment));
        });
      }
    },
  };

  const buttonData = {
    NEW_TYPE: [createBtn],
    DETAIL_TYPE: [deleteBtn, saveBtn],
  };

  const checkForm = (callbackFn) => {
    callbackFn();
  };

  const initEnrollment = () => {
    dispatch(clearEnrollment());
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    changeFields(e.target);
  };

  const changeFields = ({ name, value }) => {
    let names = name.split('.');
    let newEnrollment;

    newEnrollment = fromJS(enrollment).setIn(names, value);

    dispatch(changeField(newEnrollment.toJS()));
  };

  return (
    <ContentWrap minHeight={'0'} size={size} title={''}>
      <FormWrap ClassName={'formWrap'} form={enrollment}>
        <FormGroup row>
          <Label sm={12}>{courses.selectedRowDatas.courseName}</Label>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>강사</Label>
          <Label sm={8} className={'l'}>
            {courses.selectedRowDatas.teacherName}
          </Label>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>강의시간</Label>
          <Label sm={8} className={'l'}>
            {courses.selectedRowDatas.courseSchedule}
          </Label>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>수강생</Label>
          <Col sm={8} className={'l'}>
            <InputGroup>
              <Input type="text" name={'userName'} disabled="disable" value={enrollment.userName} />
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>생년월일</Label>
          <Col sm={8} className={'l'}>
            <Input type="text" name={'birthDate'} disabled="disable" value={enrollment.birthDate} />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>성별</Label>
          <Col sm={8} className={'l'}>
            <Input type="select" name={'gender'} disabled="disable" value={enrollment.gender}>
              <option value={''}></option>
              <option value={'Man'}>남자</option>
              <option value={'Woman'}>여자</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>신청 일자</Label>
          <Col sm={8}>
            <DatePicker
              selected={enrollmentDate}
              disabled={enrollment.courseStatus === 'Confirmed' ? 'disabled' : ''}
              customInput={<Input type="text" cssModule={{ width: '100%' }} />}
              onChange={(time) => {
                changeFields({
                  name: 'registrationDate',
                  value: time ? format(time, 'yyyyMMdd') : null,
                });

                setEnrollmentDate(time);
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>신청 경로</Label>
          <Col sm={8}>
            <Input
              type="select"
              name={'registrationPath'}
              value={enrollment.registrationPath || ''}
              onChange={onChange}
            >
              <option value=""></option>
              <option value="홈페이지">홈페이지</option>
              <option value="방문">방문</option>
            </Input>
          </Col>
        </FormGroup>
      </FormWrap>

      <FooterButtonGroup
        buttonData={{
          isHistoryBack: false,
          data: buttonData[formType],
        }}
      />
    </ContentWrap>
  );
};

export default EnrollmentForm;
