import React, { useState } from 'react';
import { Collapse, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import Styled from 'styled-components';

const MenuGroupTitle = Styled.p`
    color: #666;
    padding: 12px 0 12px 15px;
    margin: 0;
    background: linear-gradient(90deg, rgba(200,200,200,1) 0%, rgba(208,208,208,1) 98%, rgba(193,193,193,1) 100%);
    font-weight: bold;
`;

const LinkTitle = Styled.p`
    color: #666666 !important;
    padding: 8px 0 8px 25px;
    border-top: 1px dashed #cdcdcd;
    margin: 0;
`;
const MenuGroup = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <MenuGroupTitle onClick={toggle}>{title}</MenuGroupTitle>
      <Collapse isOpen={isOpen}>
        <Nav vertical>
          {Object.values(items).map((item, index) => {
            return (
              <NavItem key={'navItem-' + index}>
                <Link to={item.link}>
                  <LinkTitle>{item.title}</LinkTitle>
                </Link>
              </NavItem>
            );
          })}
        </Nav>
      </Collapse>
      <hr />
    </div>
  );
};

export default MenuGroup;
