import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';
//

export const findRollBookCourseList = ({ offset, limit, search }) => {
  const {
    totalCountYn,
    openingYear,
    openingTerm,
    courseNameKeyword,
    teacherName,
  } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    openingYear: openingYear,
    openingTerm: openingTerm,
    courseNameKeyword: courseNameKeyword || undefined,
    teacherName: teacherName || undefined,
  });
  return client.get(`${api}/rollBooks?${queryString}`);
};

export const findRollBookList = ({ courseId }) => {
  return client.get(`${api}/rollBooks/${courseId}/lectures`);
};

export const findRollBook = ({ courseId, lectureId }) => {
  return client.get(
    `${api}/rollBooks/${courseId}/lectures/${lectureId}/students`,
  );
};

export const rollBookDownload = ({ courseId }) => {
  return client.get(`${api}/rollBooks/${courseId}/excel`, {
    responseType: 'blob',
  });
};

export const closeAttendance = ({ courseId }) => {
  return client.post(`${api}/attendance/close?courseId=${courseId}`);
};

export const saveCheckinMarking = ({
  courseId,
  lectureId,
  attendeeRole,
  attendeeIds,
  channel,
  attendanceStatus,
}) => {
  return client.post(`${api}/attendance/checkin`, {
    courseId,
    lectureId,
    attendeeRole,
    attendeeIds,
    channel,
    attendanceStatus,
  });
};
