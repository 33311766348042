import client from '../utils/client';
import qs from 'qs';

const api = '/api/a/v1';

export const findKioskList = ({ offset, limit, search }) => {
  const { totalCountYn, managedNameKeyword } = search;
  const queryString = qs.stringify({
    offset,
    limit,
    totalCountYn,
    managedNameKeyword: managedNameKeyword || undefined,
  });
  return client.get(`${api}/kioskDevices?${queryString}`);
};

export const findKiosk = (kioskDeviceId) => {
  if (kioskDeviceId) {
    return client.get(`${api}/kioskDevices/${kioskDeviceId}`);
  } else {
    return { data: {} };
  }
};

export const createKiosk = (kioskData) => {
  return client.post(`${api}/kioskDevices`, kioskData);
};

export const updateKiosk = ({
  kioskDeviceId,
  managedName,
  password,
  locked,
  kioskNotice,
}) =>
  client.put(`${api}/kioskDevices/${kioskDeviceId}`, {
    managedName,
    password,
    locked,
    kioskNotice,
  });

export const deleteKiosk = (kioskDeviceId) =>
  client.delete(`${api}/kioskDevices/${kioskDeviceId}`);
