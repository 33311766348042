import React from 'react';
import Styled from 'styled-components';
import DatePicker, { registerLocale } from 'react-datepicker';
import ko from 'date-fns/locale/ko';
registerLocale('ko', ko);

const StyledDatePicker = Styled.div`
.react-datepicker { 
  font-size: 1.2rem !important; 
} 
.react-datepicker__current-month { font-size: 1.3rem !important; } 
.react-datepicker__header { padding-top: 6px !important; } 
.react-datepicker__navigation { top: 13px !important; } 
.react-datepicker__day-name, .react-datepicker__day { margin: 0.5rem !important; } 
.saturday { color: rgb(0, 0, 255) !important; } 
.sunday { color: rgb(255, 0, 0) !important; } 
.react-datepicker__day--disabled { color: #cccccc !important; }
`;

const CustomDatePicker = ({ selected, onChange, customInput, disabled }) => {
  return (
    <StyledDatePicker>
      <DatePicker
        locale={'ko'}
        selected={selected}
        onChange={onChange}
        disabled={disabled}
        customInput={customInput}
        dateFormat={'yyyy-MM-dd'}
        popperPlacement="bottom-end"
      ></DatePicker>
    </StyledDatePicker>
  );
};

export default CustomDatePicker;
