import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { clearCourseList, findEnrollmentCourseList, setOffset } from '../../modules/enrollmentCourses';
import { openingTermKr, convertCourseSchedule } from '../../lib/utils/format';

const CourseList = ({ size }) => {
  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { enrollmentCourses, enrollmentUsers, error, loading } = useSelector(
    ({ enrollmentCourses, enrollmentUsers, error, loading }) => ({
      enrollmentCourses: enrollmentCourses,
      enrollmentUsers: enrollmentUsers,
      error: error,
      loading: loading['enrollmentCourses/FIND_COURSE_LIST'],
    }),
  );

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'courseId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '연도',
        accessor: 'courseSchedule.openingYear',
        className: 'c',
        style: {
          width: '65px',
        },
      },
      {
        Header: '시기',
        accessor: 'courseSchedule.openingTerm',
        className: 'c',
        style: {
          width: '65px',
        },
        Cell: (props) => <div> {props.value ? openingTermKr(props.value) : null} </div>,
      },
      {
        Header: '분류',
        accessor: 'categoryName',
        style: {
          width: '150px',
        },
      },
      {
        Header: '강좌명',
        accessor: 'courseName',
      },
      {
        Header: '강사',
        accessor: 'teacherName',
        className: 'c',
        style: {
          width: '80px',
        },
      },
      {
        Header: '강의시간',
        accessor: 'courseSchedule',
        className: 'c',
        style: {
          width: '140px',
        },
        Cell: (props) => <div>{props.value ? convertCourseSchedule(props.value, true) : null}</div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearCourseList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (enrollmentUsers.selectedRowDatas.userAccountId) {
      dispatch(
        findEnrollmentCourseList(
          enrollmentCourses.offset,
          enrollmentCourses.limit,
          enrollmentUsers.selectedRowDatas.userAccountId,
        ),
      );
    }
  }, [enrollmentUsers.selectedRowDatas]);

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(
        findEnrollmentCourseList(
          selected * enrollmentCourses.limit,
          enrollmentCourses.limit,
          enrollmentUsers.selectedRowDatas.userAccountId,
        ),
      );
      dispatch(setOffset(selected));
    }
  };

  return (
    <ContentWrap size={size} title={'강좌 신청 이력'}>
      <PagingDataTable
        columns={columns}
        data={enrollmentCourses.courses}
        pagination={true}
        handlePageClick={handlePageClick}
        //getRowProps={onRowClick}
        //selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(enrollmentCourses.totalCount / enrollmentCourses.limit)}
        limit={enrollmentCourses.limit}
      />
    </ContentWrap>
  );
};

export default CourseList;
