import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { clearCourseList, findCourseList, setOffset, changeField, setSelectedRowDatas } from '../../modules/courses';
import { openingTermKr, convertCourseSchedule } from '../../lib/utils/format';
import SearchWrap from '../../components/layout/SearchWrap';
import { Col, FormGroup, Label, Button } from 'reactstrap';
import Input from '../../components/common/Input';
import Row from 'reactstrap/es/Row';
import { fromJS } from 'immutable';
import { getSerachOpeningYearOptions } from '../../lib/utils/util';

const CourseList = ({ size }) => {
  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const dispatch = useDispatch();
  const { courses, course, enrollment, error, loading } = useSelector(
    ({ courses, course, enrollment, error, loading }) => ({
      courses: courses,
      course: course,
      enrollment: enrollment,
      error: error,
      loading: loading['course/FIND_COURSE_LIST'],
    }),
  );
  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'courseId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '연도',
        accessor: 'courseSchedule.openingYear',
        className: 'c',
        style: {
          width: '65px',
        },
      },
      {
        Header: '시기',
        accessor: 'courseSchedule.openingTerm',
        className: 'c',
        style: {
          width: '65px',
        },
        Cell: (props) => <div> {props.value ? openingTermKr(props.value) : null} </div>,
      },
      {
        Header: '분류',
        accessor: 'categoryName',
        style: {
          width: '150px',
        },
      },
      {
        Header: '강좌명',
        accessor: 'courseName',
      },
      {
        Header: '신청',
        accessor: 'numberOfStudents',
        className: 'c',
        style: {
          width: '45px',
        },
      },
      {
        Header: '정원',
        accessor: 'quota.maximumStudents',
        className: 'c',
        style: {
          width: '45px',
        },
      },
      {
        Header: '강사',
        accessor: 'teacherName',
        className: 'c',
        style: {
          width: '80px',
        },
      },
      {
        Header: '강의시간',
        accessor: 'courseSchedule',
        className: 'c',
        style: {
          width: '140px',
        },
        Cell: (props) => <div>{props.value ? convertCourseSchedule(props.value, true) : null}</div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearCourseList());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(findCourseList(courses.offset, courses.limit, courses.search));
  }, [course.changedTime, enrollment.changedTime]);

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(findCourseList(selected * courses.limit, courses.limit, courses.search));
      dispatch(setOffset(selected));
    }
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.values.courseId) {
          dispatch(setSelectedRowDatas(row));
          setSelectRowIndex(row.index);
        }
      },
    };
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newSearch = fromJS(courses).setIn(names, value);

    dispatch(changeField(newSearch.toJS()));
  };

  return (
    <ContentWrap size={size} title={'강좌목록'}>
      <SearchWrap>
        <Row>
          <Col sm={10}>
            <FormGroup row className={'mb10'}>
              <Label for="openingYearSV" sm={2}>
                개설년도
              </Label>
              <Col sm={2}>
                <Input
                  type="select"
                  name="search.openingYear"
                  id="openingYearSV"
                  value={courses.search.openingYear}
                  onChange={onChange}
                >
                  <option value={''}>선택</option>
                  {Object.values(getSerachOpeningYearOptions()).map((option, index) => {
                    return (
                      <option key={'SEARCH_openingYear' + index} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Label for="openingTermSV" sm={2}>
                개설시기
              </Label>
              <Col sm={2}>
                <Input
                  type="select"
                  name="search.openingTerm"
                  id="openingTermSV"
                  value={courses.search.openingTerm}
                  onChange={onChange}
                >
                  <option value={''}>선택</option>
                  <option value={'FirstHalf'}>상반기</option>
                  <option value={'SecondHalf'}>하반기</option>
                </Input>
              </Col>

              <Label for="teacherNameSV" sm={2}>
                강사명
              </Label>
              <Col sm={2}>
                <Input
                  type="text"
                  name="search.teacherName"
                  id="teacherNameSV"
                  value={courses.search.teacherName}
                  onChange={onChange}
                  placeholder="강사명"
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="courseNameKeywordSV" sm={2}>
                강좌명
              </Label>
              <Col sm={10}>
                <Input
                  type="text"
                  name="search.courseNameKeyword"
                  id="courseNameKeywordSV"
                  value={courses.search.courseNameKeyword}
                  onChange={onChange}
                  placeholder="강좌명"
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={2} className={'button-col'}>
            <Button
              color={'success'}
              onClick={async (e) => {
                e.preventDefault();
                await setSelectPageIndex(0);
                await dispatch(setOffset(0));
                await dispatch(findCourseList(0, courses.limit, courses.search));
              }}
            >
              검색
            </Button>
          </Col>
        </Row>
      </SearchWrap>
      <PagingDataTable
        columns={columns}
        data={courses.courses}
        pagination={true}
        handlePageClick={handlePageClick}
        getRowProps={onRowClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(courses.totalCount / courses.limit)}
        limit={courses.limit}
      />
    </ContentWrap>
  );
};

export default CourseList;
