import React, { useEffect, useState } from 'react';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { clearCommentList, findCommentList } from '../../modules/comments';
import { useAlert } from 'react-alert';
import { format } from 'date-fns';
import { findPostList, setOffset } from '../../modules/posts';

const CommentList = ({ size }) => {
  const dispatch = useDispatch();
  const { comments, post, error, loading } = useSelector(({ comments, post, error, loading }) => ({
    comments: comments,
    post: post,
    error: error,
    loading: loading['comments/FIND_COMMENT_LIST'],
  }));

  const [selectPageIndex, setSelectPageIndex] = useState(0);
  const [selectRowIndex, setSelectRowIndex] = useState(-1);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const alert = useAlert();

  const columns = React.useMemo(
    () => [
      {
        Header: '작성일시',
        accessor: 'commentedAt',
        className: 'c',
        style: {
          width: '120px',
        },
        Cell: (props) => {
          return <div>{props.value ? format(props.value, 'yyyy-MM-dd HH:mm') : null}</div>;
        },
      },
      {
        Header: '내용',
        accessor: 'content',
        className: 'l',
      },
      {
        Header: '작성자',
        accessor: 'commenter.userName',
        className: 'c',
        style: {
          width: '80px',
        },
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearCommentList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (post.postId) {
      dispatch(
        findCommentList(comments.offset, comments.limit, {
          postId: post.postId,
          totalCountYn: 'Y',
        }),
      );
    } else {
      dispatch(clearCommentList());
    }
  }, [post.postId]);

  const handlePageClick = ({ selected }) => {
    if (selected !== selectPageIndex) {
      setSelectPageIndex(selected);
      dispatch(findPostList(selected * comments.limit, comments.limit, comments.search));
      dispatch(setOffset(selected));
    }
  };

  return (
    <>
      <PagingDataTable
        columns={columns}
        data={comments.comments}
        pagination={true}
        handlePageClick={handlePageClick}
        selectRowIndex={selectRowIndex}
        pageIndex={selectPageIndex}
        pageCount={Math.ceil(comments.totalCount / comments.limit)}
        limit={comments.limit}
      />
    </>
  );
};

export default CommentList;
