import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import CourseList from '../../containers/course/CourseList';
import TimeRecordList from "../../containers/timeCard/TimeRecordList";

const TimeCardPage = () => {
    return (
        <PageWrap title={'강사 출퇴근 관리'}>
            <CourseList size={8} />
            <TimeRecordList size={4} />
        </PageWrap>
    );
};

export default TimeCardPage;
