import React from 'react';
import Styled from 'styled-components';
import { Button, ButtonGroup, Col } from 'reactstrap';

const ContentWrapper = Styled.div`
    padding: 12px;
    width:100%;
    border: 1px solid #dfdfdf;
    background-color: #efefef;
    border-radius: 6px;
    
    min-height: ${(props) => props.minHeight || '710'}px;
        margin-bottom: 12px;
    
    .mb0 {margin-bottom : 0;}
`;
const ContentHeader = Styled.div`
    padding: 10px;
    width:100%;
    display: flex;
    justify-content: space-between;
`;
const ContentBody = Styled.div`
    width:100%;
`;

const ContentWrap = ({ children, title, size, buttonDatas, minHeight, marginBottom }) => {
  return (
    <Col md={size}>
      <ContentWrapper minHeight={minHeight} marginBottom={marginBottom}>
        {title || buttonDatas ? (
          <ContentHeader>
            {title ? (
              <div>
                <h5>{title}</h5>
              </div>
            ) : null}
            <div>
              {buttonDatas ? (
                <ButtonGroup size={buttonDatas.size ? buttonDatas.size : false}>
                  {Object.values(buttonDatas.datas).map((button, index) => {
                    return (
                      <Button
                        key={'topContentButton' + index}
                        size="sm"
                        color={button.color}
                        onClick={button.onClickFn}
                      >
                        {button.title}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              ) : null}
            </div>
          </ContentHeader>
        ) : null}
        <ContentBody>{children}</ContentBody>
      </ContentWrapper>
    </Col>
  );
};

export default ContentWrap;
