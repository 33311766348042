import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { changeField, login, logout } from '../../modules/auth';
import AuthForm from '../../components/auth/AuthForm';
import { fromJS } from 'immutable';
import ModalPopup from '../../components/layout/ModalPopup';
import NewPasswordForm from './NewPasswordForm';

const LoginForm = ({ history }) => {
  const [error, setError] = useState(null);
  const [successPage, setSuccessPage] = useState(null);
  const [passwordChangeModal, setPasswordChangeModal] = useState(false);
  const dispatch = useDispatch();
  const { authState, auth, authError, logoutLoading } = useSelector(({ auth, loading }) => ({
    authState: auth,
    auth: auth.auth,
    authError: auth.authError,
    logoutLoading: loading['auth/LOGOUT'],
  }));

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    const { value, name } = e.target;
    let names = name.split('.');
    const newAuth = fromJS(authState).setIn(names, value);

    dispatch(changeField(newAuth.toJS()));
  };

  // 폼 등록 이벤트 핸들러
  const onSubmit = (e) => {
    e.preventDefault();
    const { loginId, password } = authState.login;
    if (!loginId) {
      setError('아이디를 입력하세요');
      return;
    }

    if (!password) {
      setError('비밀번호를 입력하세요.');
      return;
    }

    dispatch(login(loginId, password));
  };

  useEffect(() => {
    if (authError) {
      setError('로그인 정보가 올바르지 않습니다.');
    }
    if (auth) {
      if (auth.status === 'Active') {
        moveMainPage();
      } else if (auth.status === 'PasswordReset') {
        setPasswordChangeModal(true);
      }
    }
    return () => {
      setSuccessPage(null);
    };
  }, [auth, authError, dispatch]);

  useEffect(() => {
    if (logoutLoading && logoutLoading.completed) {
      window.location.replace('/');
    }
  }, [logoutLoading]);

  const moveMainPage = () => {
    setSuccessPage(<Redirect to="/main/user/list" />);
  };

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <AuthForm type="login" form={authState.login} onChange={onChange} onSubmit={onSubmit} error={error} />
      {successPage}

      <ModalPopup
        title="비밀번호 재설정"
        inOpen={passwordChangeModal}
        toggleFn={() => {
          setPasswordChangeModal(!passwordChangeModal);
        }}
        width={500}
        hiddenBottomArea={true}
      >
        <NewPasswordForm mode="reset" callBackFn={onLogout} />
      </ModalPopup>
    </>
  );
};

export default withRouter(LoginForm);
