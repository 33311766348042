import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as userAPI from '../lib/api/user';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const CHANGE_FIELD = 'user/CHANGE_FIELD';

const [
  FIND_USER,
  FIND_USER_SUCCESS,
  FIND_USER_FAILURE,
] = createRequestActionTypes('user/FIND_USER');
const [
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
] = createRequestActionTypes('user/CREATE_USER');
const [
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
] = createRequestActionTypes('user/DELETE_USER');
const [
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
] = createRequestActionTypes('user/UPDATE_USER');

const [ID_CHECK, ID_CHECK_SUCCESS, ID_CHECK_FAILURE] = createRequestActionTypes(
  'user/ID_CHECK',
);
const [
  PASSWORD_RESET,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
] = createRequestActionTypes('user/PASSWORD_RESET');

const CLEAR_USER = 'user/CLEAR_USER'; // user 데이터 비우기
const SET_AVAILABLE_ID = 'user/SET_AVAILABLE_ID'; // 사용가능 아이디 등록
const SET_CHANGED_TIME = 'user/SET_CHANGED_TIME'; // 상세 데이타 변경

export const changeField = createAction(CHANGE_FIELD, (user) => user);

export const findUser = createAction(FIND_USER, (accountId) => accountId);
export const createUser = createAction(CREATE_USER, (user) => user);
export const deleteUser = createAction(DELETE_USER, (accountId) => accountId);
export const editUser = createAction(UPDATE_USER, (user) => {
  return {
    ...user.userDetail,
    accountId: user.accountId,
    role: user.role,
    state: user.state,
  };
});
export const clearUser = createAction(CLEAR_USER);
export const setAvailableId = createAction(SET_AVAILABLE_ID);
export const setChangedTime = createAction(SET_CHANGED_TIME);
export const idCheck = createAction(ID_CHECK, (loginId) => {
  return loginId;
});

export const passwordReset = createAction(PASSWORD_RESET, (user) => {
  return {
    ...user.userDetail,
    loginId: user.userCredential.loginId,
  };
});

// saga 생성
const findUserSaga = createRequestSaga(FIND_USER, userAPI.findUser);
const createUserSaga = createRequestSaga(CREATE_USER, userAPI.createUser);
const updateUserSaga = createRequestSaga(UPDATE_USER, userAPI.updateUser);
const deleteUserSaga = createRequestSaga(DELETE_USER, userAPI.deleteUser);
const idCheckSaga = createRequestSaga(ID_CHECK, userAPI.idCheck);
const passwordResetSaga = createRequestSaga(
  PASSWORD_RESET,
  userAPI.passwordReset,
);

export function* userSaga() {
  yield takeLatest(FIND_USER, findUserSaga);
  yield takeLatest(CREATE_USER, createUserSaga);
  yield takeLatest(UPDATE_USER, updateUserSaga);
  yield takeLatest(DELETE_USER, deleteUserSaga);
  yield takeLatest(ID_CHECK, idCheckSaga);
  yield takeLatest(PASSWORD_RESET, passwordResetSaga);
}

const initialState = {
  accountId: '',
  userDetail: {
    userName: '',
    birthDate: '',
    gender: 'Man',
    phoneNo: '',
  },
  userCredential: {
    loginId: '',
    password: '',
  },
  role: 'Student',
  status: 'Active',
  originalUserId: null,
  error: null,
  isAvailable: null,
  availableId: '',
  passwordReset: true,
  smsNotification: false,
  changedTime: new Date(),
};

const user = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_USER_SUCCESS]: (state, { payload: user }) => ({
      ...initialState,
      accountId: user.accountId,
      role: user.role,
      status: user.status,
      userDetail: user.userDetail,
      userCredential: { loginId: user.loginId },
    }),

    [FIND_USER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CREATE_USER_SUCCESS]: (state, { payload: data }) => {
      return { ...state, accountId: data.accountId };
    },

    [CREATE_USER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [UPDATE_USER_SUCCESS]: (state, { payload: data }) => {
      return { ...state };
    },

    [UPDATE_USER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [DELETE_USER_SUCCESS]: (state, { payload: data }) => ({
      ...state,
    }),

    [DELETE_USER_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [ID_CHECK_SUCCESS]: (state, { payload: res }) => {
      return { ...state, isAvailable: res.available };
    },

    [ID_CHECK_FAILURE]: (state, { payload: error }) => {
      return { ...state, error };
    },

    [PASSWORD_RESET_SUCCESS]: (state, { payload: data }) => {
      return { ...state };
    },

    [PASSWORD_RESET_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_AVAILABLE_ID]: (state, { payload: availableId }) => ({
      ...state,
      availableId: availableId,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [CLEAR_USER]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default user;
