import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as courseAPI from '../lib/api/course';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
//

const CHANGE_FIELD = 'lecture/CHANGE_FIELD';

const [
  FIND_LECTURE,
  FIND_LECTURE_SUCCESS,
  FIND_LECTURE_FAILURE,
] = createRequestActionTypes('lecture/FIND_LECTURE');
const [
  CREATE_LECTURE,
  CREATE_LECTURE_SUCCESS,
  CREATE_LECTURE_FAILURE,
] = createRequestActionTypes('lecture/CREATE_LECTURE');
const [
  DELETE_LECTURE,
  DELETE_LECTURE_SUCCESS,
  DELETE_LECTURE_FAILURE,
] = createRequestActionTypes('lecture/DELETE_LECTURE');
const [
  UPDATE_LECTURE,
  UPDATE_LECTURE_SUCCESS,
  UPDATE_LECTURE_FAILURE,
] = createRequestActionTypes('lecture/UPDATE_LECTURE');

const CLEAR_LECTURE = 'lecture/CLEAR_LECTURE'; // lecture 데이터 비우기
const SET_CHANGED_TIME = 'lecture/SET_CHANGED_TIME'; // 상세 데이타 변경
const SET_COURSE_ID = 'lecture/SET_COURSE_ID'; //신규등록시 courseId 저장용도

export const changeField = createAction(CHANGE_FIELD, (lecture) => lecture);
export const findLecture = createAction(FIND_LECTURE, (lectureId) => lectureId);
export const createLecture = createAction(CREATE_LECTURE, (lecture) => lecture);
export const deleteLecture = createAction(
  DELETE_LECTURE,
  (lectureId) => lectureId,
);
export const editLecture = createAction(UPDATE_LECTURE, (lecture) => {
  return {
    ...lecture,
  };
});

export const clearLecture = createAction(CLEAR_LECTURE);
export const setChangedTime = createAction(SET_CHANGED_TIME);
export const setCourseId = createAction(SET_COURSE_ID);

// saga 생성
const findLectureSaga = createRequestSaga(FIND_LECTURE, courseAPI.findLecture);
const createLectureSaga = createRequestSaga(
  CREATE_LECTURE,
  courseAPI.createLecture,
);
const updateLectureSaga = createRequestSaga(
  UPDATE_LECTURE,
  courseAPI.updateLecture,
);
const deleteLectureSaga = createRequestSaga(
  DELETE_LECTURE,
  courseAPI.deleteLecture,
);

export function* lectureSaga() {
  yield takeLatest(FIND_LECTURE, findLectureSaga);
  yield takeLatest(CREATE_LECTURE, createLectureSaga);
  yield takeLatest(UPDATE_LECTURE, updateLectureSaga);
  yield takeLatest(DELETE_LECTURE, deleteLectureSaga);
}

const initialState = {
  lectureId: '',
  courseId: '',
  courseStatus: 'Unconfirmed',
  lectureDate: '',
  lectureTime: {
    startHour: 0,
    startMinute: 0,
    endHour: 0,
    endMinute: 0,
  },
  lectureWeek: 'Monday',
  cancelled: false,
  supplementaryLecture: {
    lectureDate: '',
    lectureTime: {
      startHour: 0,
      startMinute: 0,
      endHour: 0,
      endMinute: 0,
    },
    lectureWeek: 'Monday',
  },
  teacherId: '',
  changedTime: new Date(),
};

const lecture = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [FIND_LECTURE_SUCCESS]: (state, { payload: lecture }) => {
      return { ...initialState, ...lecture };
    },

    [FIND_LECTURE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CREATE_LECTURE_SUCCESS]: (state, { payload: data }) => {
      return { ...state, lectureId: data.lectureId };
    },

    [CREATE_LECTURE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [UPDATE_LECTURE_SUCCESS]: (state, { payload: data }) => {
      return {
        ...state,
      };
    },

    [UPDATE_LECTURE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [DELETE_LECTURE_SUCCESS]: (state, { payload: data }) => ({
      ...state,
      lectureId: '',
    }),

    [DELETE_LECTURE_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [SET_CHANGED_TIME]: (state, { payload: time }) => ({
      ...state,
      changedTime: time,
    }),

    [SET_COURSE_ID]: (state, { payload: courseId }) => ({
      ...state,
      courseId: courseId,
    }),

    [CLEAR_LECTURE]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default lecture;
