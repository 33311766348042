import { createAction, handleActions } from 'redux-actions';
import { takeLatest } from 'redux-saga/effects';
import * as enrollmentAPI from '../lib/api/enrollment';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import { fileDownload } from '../lib/utils/util';
//

const CHANGE_FIELD = 'enrollmentExcel/CHANGE_FIELD';

const [
  TEMPLATE_FILE_DOWNLOAD,
  TEMPLATE_FILE_DOWNLOAD_SUCCESS,
  TEMPLATE_FILE_DOWNLOAD_FAILURE,
] = createRequestActionTypes('enrollmentExcel/TEMPLATE_FILE_DOWNLOAD');
const [
  ENROLLMENT_EXCEL_UPLOAD,
  ENROLLMENT_EXCEL_UPLOAD_SUCCESS,
  ENROLLMENT_EXCEL_UPLOAD_FAILURE,
] = createRequestActionTypes('enrollmentExcel/ENROLLMENT_EXCEL_UPLOAD');
const [
  FAIL_RESULT_EXCEL_DOWNLOAD,
  FAIL_RESULT_EXCEL_DOWNLOAD_SUCCESS,
  FAIL_RESULT_EXCEL_DOWNLOAD_FAILURE,
] = createRequestActionTypes('enrollmentExcel/FAIL_RESULT_EXCEL_DOWNLOAD');

const CLEAR_ENROLLMENT_EXCEL = 'enrollmentExcel/CLEAR_ENROLLMENT_EXCEL';

export const changeField = createAction(
  CHANGE_FIELD,
  (enrollmentExcel) => enrollmentExcel,
);
export const templateExcelDownload = createAction(
  TEMPLATE_FILE_DOWNLOAD,
  ({ openingYear, openingTerm }) => {
    return {
      openingYear,
      openingTerm,
    };
  },
);
export const enrollmentExcelUpload = createAction(
  ENROLLMENT_EXCEL_UPLOAD,
  (formData) => formData,
);
export const failResultExcelDownload = createAction(
  FAIL_RESULT_EXCEL_DOWNLOAD,
  ({ result, openingYear, openingTerm }) => {
    return {
      invalidFormId: result.invalidFormId,
      openingYear,
      openingTerm,
    };
  },
);
export const clearEnrollmentExcel = createAction(CLEAR_ENROLLMENT_EXCEL);

// saga 생성
const templateExcelDownloadSaga = createRequestSaga(
  TEMPLATE_FILE_DOWNLOAD,
  enrollmentAPI.templateExcelDownload,
);
const enrollmentExcelUploadSaga = createRequestSaga(
  ENROLLMENT_EXCEL_UPLOAD,
  enrollmentAPI.enrollmentExcelUpload,
);
const failResultExcelDownloadSaga = createRequestSaga(
  FAIL_RESULT_EXCEL_DOWNLOAD,
  enrollmentAPI.failResultExcelDownload,
);

export function* enrollmentExcelSaga() {
  yield takeLatest(TEMPLATE_FILE_DOWNLOAD, templateExcelDownloadSaga);
  yield takeLatest(ENROLLMENT_EXCEL_UPLOAD, enrollmentExcelUploadSaga);
  yield takeLatest(FAIL_RESULT_EXCEL_DOWNLOAD, failResultExcelDownloadSaga);
}

const initialState = {
  openingYear: new Date().getFullYear(),
  openingTerm: 'FirstHalf',
  formData: null,
  result: '',
  // {
  //     uploadSucceeded: '',
  //     validForm: '',
  //     invalidFormId: 'asd',
  //   },
  error: '',
};

const enrollmentExcel = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: data }) => {
      return { ...state, ...data };
    },

    [TEMPLATE_FILE_DOWNLOAD_SUCCESS]: (state, { meta: res }) => {
      fileDownload(res);
      return { ...state };
    },

    [TEMPLATE_FILE_DOWNLOAD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [ENROLLMENT_EXCEL_UPLOAD_SUCCESS]: (state, { payload: data }) => {
      return { ...state, result: data };
    },

    [ENROLLMENT_EXCEL_UPLOAD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [FAIL_RESULT_EXCEL_DOWNLOAD_SUCCESS]: (state, { meta: res }) => {
      fileDownload(res);
      return { ...state };
    },

    [FAIL_RESULT_EXCEL_DOWNLOAD_FAILURE]: (state, { payload: error }) => ({
      ...state,
      error,
    }),

    [CLEAR_ENROLLMENT_EXCEL]: (state) => {
      return { ...initialState, changedTime: state.changedTime };
    },
  },
  initialState,
);

export default enrollmentExcel;
