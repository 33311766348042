import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import CourseList from '../../containers/course/CourseList';
import EnrollmentList from '../../containers/enrollment/EnrollmentList';

const EnrollmentPage = () => {
  return (
    <PageWrap title={'수강생 등록'}>
      <CourseList size={8} />
      <EnrollmentList size={4} />
    </PageWrap>
  );
};

export default EnrollmentPage;
