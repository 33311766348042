import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import UserList from '../../containers/user/UserList';
import UserForm from '../../containers/user/UserForm';

const UserPage = () => {
  return (
    <PageWrap title={'사용자 목록'}>
      <UserList size={8} />
      <UserForm size={4} />
    </PageWrap>
  );
};

export default UserPage;
