import React from 'react';
import Styled from 'styled-components';
import MenuGroup from '../../components/layout/MenuGroup';

const LeftMenuArea = Styled.nav`
  position: absolute;
  top: 56px;
  width: 200px;
  height: 100%;
  font-size: 14px;
  color: #666666 !important;
  background-color: #f4f4f4;
  border-right: 1px solid #efefef;
  
  div{
    cursor: pointer;
  }
}
`;

const LeftMenu = () => {
  const menuDatas = [
    {
      title: '사용자 관리',
      items: [
        {
          title: '사용자 목록',
          link: '/main/user/list',
        },
      ],
    },
    {
      title: '강좌 관리',
      items: [
        {
          title: '강좌 목록',
          link: '/main/course/list',
        },
        {
          title: '수업 일정',
          link: '/main/course/lecture',
        },
      ],
    },
    {
      title: '수강생 관리',
      items: [
        {
          title: '수강생 등록',
          link: '/main/enrollment/list',
        },
        {
          title: '수강생 일괄 등록',
          link: '/main/enrollment/excel',
        },
        {
          title: '강좌 신청 이력',
          link: '/main/enrollment/history',
        },
      ],
    },
    {
      title: '출결 관리',
      items: [
        {
          title: '출석부 관리',
          link: '/main/rollbook/rollbook',
        },
        {
          title: '강사 출퇴근 관리',
          link: '/main/rollbook/timeCard',
        },
      ],
    },
    {
      title: '키오스크 관리',
      items: [
        {
          title: '키오스크 관리',
          link: '/main/kiosk/list',
        },
      ],
    },
    {
      title: '소통 관리',
      items: [
        {
          title: '강좌별 게시판',
          link: '/main/courseBoard/list',
        },
      ],
    },
  ];

  return (
    <LeftMenuArea>
      {Object.values(menuDatas).map((menuData, index) => {
        return <MenuGroup key={'menuGroup-' + index} title={menuData.title} items={menuData.items} />;
      })}
    </LeftMenuArea>
  );
};

export default LeftMenu;
