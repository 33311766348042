import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearLecture,
  changeField,
  findLecture,
  editLecture,
  deleteLecture,
  createLecture,
  setCourseId,
  setChangedTime,
} from '../../modules/lecture';
import { Col } from 'reactstrap';
import FormWrap from '../../components/layout/FormWrap';
import { FormGroup, Label } from 'reactstrap';
import Input from '../../components/common/Input';
import { fromJS } from 'immutable';
import { useAlert } from 'react-alert';
import {
  colonStyleTime,
  formatTimeInput,
  getWeek,
  zeroPadding,
} from '../../lib/utils/format';
import FooterButtonGroup from '../../components/buttonGroup/FooterButtonGroup';
import DatePicker from '../../components/dataPicker/DatePicker';
import { format } from 'date-fns';
import moment from 'moment';
import { clearLoading } from '../../modules/loading';

const LectureForm = ({ history, size, formType, callBackFn }) => {
  const dispatch = useDispatch();
  const {
    lecture,
    lectures,
    coursesSelect,
    course,
    error,
    createLectureLoading,
    findLectureLoading,
    deleteLectureLoading,
    updateLectureLoading,
  } = useSelector(
    ({ lecture, lectures, coursesSelect, course, error, loading }) => ({
      lecture: lecture,
      lectures: lectures,
      coursesSelect: coursesSelect,
      course: course,
      error: lecture.error,
      createLectureLoading: loading['lecture/CREATE_LECTURE'],
      findLectureLoading: loading['lecture/FIND_LECTURE'],
      updateLectureLoading: loading['lecture/UPDATE_LECTURE'],
      deleteLectureLoading: loading['lecture/DELETE_LECTURE'],
    }),
  );
  const alert = useAlert();

  const [lectureDate, setLectureDate] = useState(null);
  const [supplementaryDate, setSupplementaryDate] = useState(null);
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('00:00');
  const [supplementaryStartTime, setSupplementaryStartTime] = useState('');
  const [supplementaryEndTime, setSupplementaryEndTime] = useState('');

  useEffect(() => {
    return () => {
      initLecture();
    };
  }, [dispatch]);

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage =
        errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    if (formType === 'NEW_TYPE') {
      initLecture();
    }
  }, [formType]);

  useEffect(() => {
    if (coursesSelect.selectedDatas.courseId) {
      dispatch(setCourseId(coursesSelect.selectedDatas.courseId));
    }
  }, [coursesSelect]);

  useEffect(() => {
    if (lectures.selectedRowDatas && formType !== 'NEW_TYPE') {
      dispatch(findLecture(lectures.selectedRowDatas.lectureId));
    }
  }, [lectures.selectedRowDatas.lectureId]);

  useEffect(() => {
    const { startHour, startMinute, endHour, endMinute } = lecture.lectureTime;

    if (startHour !== null) {
      setStartTime(colonStyleTime(startHour, startMinute));
    }

    if (endHour !== null) {
      setEndTime(colonStyleTime(endHour, endMinute));
    }
  }, [lecture.lectureTime]);

  useEffect(() => {
    if (findLectureLoading && findLectureLoading.completed) {
      if (lecture.lectureTime.startHour) {
        setStartTime(
          zeroPadding(lecture.lectureTime.startHour) +
            ':' +
            zeroPadding(lecture.lectureTime.startMinute),
        );
      }
      if (lecture.lectureTime.endHour) {
        setEndTime(
          zeroPadding(lecture.lectureTime.endHour) +
            ':' +
            zeroPadding(lecture.lectureTime.endMinute),
        );
      }
      if (lecture.supplementaryLecture) {
        if (lecture.supplementaryLecture.lectureTime) {
          if (lecture.supplementaryLecture.lectureTime.startHour) {
            setSupplementaryStartTime(
              zeroPadding(lecture.supplementaryLecture.lectureTime.startHour) +
                ':' +
                zeroPadding(
                  lecture.supplementaryLecture.lectureTime.startMinute,
                ),
            );
          }
        }
        if (lecture.supplementaryLecture.lectureTime) {
          if (lecture.supplementaryLecture.lectureTime.endHour) {
            setSupplementaryEndTime(
              zeroPadding(lecture.supplementaryLecture.lectureTime.endHour) +
                ':' +
                zeroPadding(lecture.supplementaryLecture.lectureTime.endMinute),
            );
          }
        }
      }
      if (lecture.lectureDate) {
        setLectureDate(moment(lecture.lectureDate, 'YYYYMMDD').toDate());
      }
      if (lecture.supplementaryLecture) {
        if (lecture.supplementaryLecture.lectureDate) {
          setSupplementaryDate(
            moment(
              lecture.supplementaryLecture.lectureDate,
              'YYYYMMDD',
            ).toDate(),
          );
        }
      }

      dispatch(clearLoading(findLectureLoading.type));
    }
  }, [findLectureLoading]);

  useEffect(() => {
    if (createLectureLoading && createLectureLoading.completed) {
      if (lecture.lectureId) {
        alert.success('강의일정을 등록 하였습니다.');
        callBackFn();
        dispatch(setChangedTime(new Date()));

        dispatch(clearLoading(createLectureLoading.type));
      }
    }
  }, [createLectureLoading]);

  useEffect(() => {
    if (updateLectureLoading && updateLectureLoading.completed) {
      alert.success('강의 일정을 수정 하였습니다.');
      callBackFn();
      dispatch(setChangedTime(new Date()));

      dispatch(clearLoading(updateLectureLoading.type));
    }
  }, [updateLectureLoading]);

  useEffect(() => {
    if (deleteLectureLoading && deleteLectureLoading.completed) {
      alert.success('강의 일정을 삭제 하였습니다.');

      callBackFn();
      dispatch(setChangedTime(new Date()));
      dispatch(clearLoading(deleteLectureLoading.type));
    }
    return () => {
      initLecture();
    };
  }, [deleteLectureLoading]);

  useEffect(() => {
    if (lectureDate) {
      changeFields({
        name: 'lectureWeek',
        value: getWeek(lectureDate, 'week'),
      });
    }
  }, [lectureDate]);

  useEffect(() => {
    if (supplementaryDate) {
      changeFields({
        name: 'supplementaryLecture.lectureWeek',
        value: getWeek(supplementaryDate, 'week'),
      });
    }
  }, [supplementaryDate]);

  const createBtn = {
    label: '신규등록',
    type: 'success',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('등록하시겠습니까?')) {
        checkForm('NEW', async () => {
          dispatch(await createLecture(lecture));
        });
      }
    },
  };
  const deleteBtn = {
    label: '삭제',
    type: 'danger',
    onClickFn: (e) => {
      e.preventDefault();
      if (window.confirm('삭제하시겠습니까?')) {
        dispatch(deleteLecture(lecture.lectureId));
      }
    },
  };

  const saveBtn = {
    label: '저장',
    type: 'success',
    onClickFn: async (e) => {
      e.preventDefault();
      if (window.confirm('저장하시겠습니까?')) {
        checkForm('EDIT', async (lecture) => {
          console.log(lecture);
          dispatch(await editLecture(lecture));
        });
      }
    },
  };

  const buttonData = {
    NEW_TYPE: [createBtn],
    DETAIL_TYPE: [saveBtn],
    EDIT_TYPE: [deleteBtn, saveBtn],
  };

  const checkForm = (type, callbackFn) => {
    if (!lecture.lectureDate) {
      alert.error('강의 일자는 필수입니다.');
      return false;
    }

    if (startTime.localeCompare(endTime) >= 0) {
      alert.error('강의 종료 시간은 시작 시간 이후여야 합니다.');
      return false;
    }

    if (
      (lecture.supplementaryLecture.lectureDate &&
        lecture.supplementaryLecture.lectureTime.startHour === undefined) ||
      (lecture.supplementaryLecture.lectureDate &&
        lecture.supplementaryLecture.lectureTime.startMinute === undefined) ||
      (lecture.supplementaryLecture.lectureDate &&
        lecture.supplementaryLecture.lectureTime.endHour === undefined) ||
      (lecture.supplementaryLecture.lectureDate &&
        lecture.supplementaryLecture.lectureTime.endMinute === undefined)
    ) {
      alert.error('보강 일자 등록시 시간을 필수로 입력해야 합니다.');
      return false;
    }

    if (
      (supplementaryStartTime && !lecture.supplementaryLecture.lectureDate) ||
      (supplementaryEndTime && !lecture.supplementaryLecture.lectureDate)
    ) {
      alert.error('보강 시간 등록시 일자를 필수로 입력해야 합니다.');
      return false;
    }
    if (lecture.cancelled) {
      callbackFn(lecture);
    } else {
      const newLecture = clearSupplementaryLecture();
      callbackFn(newLecture);
    }
  };

  const initLecture = () => {
    dispatch(clearLecture());
    setLectureDate(null);
    setSupplementaryDate(null);

    if (course.courseSchedule.openingTime) {
      if (course.courseSchedule.openingTime.startHour) {
        setStartTime(
          zeroPadding(course.courseSchedule.openingTime.startHour) +
            ':' +
            zeroPadding(course.courseSchedule.openingTime.startMinute),
        );
      }
    }
    if (course.courseSchedule.openingTime.endHour) {
      if (course.courseSchedule.openingTime.endHour) {
        setEndTime(
          zeroPadding(course.courseSchedule.openingTime.endHour) +
            ':' +
            zeroPadding(course.courseSchedule.openingTime.endMinute),
        );
      }
    }
  };

  // 인풋 변경 이벤트 핸들러
  const onChange = (e) => {
    changeFields(e.target);
  };
  const changeChecked = (e) => {
    changeFields(e.target, true);
  };

  const changeFields = ({ name, value }, isCheckbox) => {
    let names = name.split('.');
    let newLecture;
    if (isCheckbox) {
      JSON.parse(value);
      newLecture = fromJS(lecture).setIn(names, !JSON.parse(value));
    } else {
      newLecture = fromJS(lecture).setIn(names, value);
    }

    dispatch(changeField(newLecture.toJS()));
  };

  const onLectureTimeBlur = (time, hourPropName, minutePropName) => {
    const value = time.replace(/[^0-9]/g, '');
    let hour = 0;
    let minutes = 0;
    if (value.length >= 2) {
      hour = parseInt(value.substr(0, 2), 10);
      if (value.length > 2) {
        minutes = parseInt(value.substr(2), 10);
      }
    }

    const newLecture = fromJS(lecture)
      .setIn(['lectureTime', hourPropName], hour)
      .setIn(['lectureTime', minutePropName], minutes);

    dispatch(changeField(newLecture.toJS()));
  };

  const clearSupplementaryLecture = () => {
    setSupplementaryStartTime('');
    setSupplementaryEndTime('');
    const newLecture = fromJS(lecture).setIn(['supplementaryLecture'], {});
    console.log(newLecture.toJS());
    dispatch(changeField(newLecture.toJS()));
    return newLecture.toJS();
  };

  const onSupplementaryLectureTimeBlur = (
    time,
    hourPropName,
    minutePropName,
  ) => {
    const value = time.replace(/[^0-9]/g, '');
    let hour;
    let minutes;
    if (value) {
      hour = 0;
      minutes = 0;
    }
    if (value.length >= 2) {
      hour = parseInt(value.substr(0, 2), 10);
      if (value.length > 2) {
        minutes = parseInt(value.substr(2), 10);
      }
    }

    const newLecture = fromJS(lecture)
      .setIn(['supplementaryLecture', 'lectureTime', hourPropName], hour)
      .setIn(['supplementaryLecture', 'lectureTime', minutePropName], minutes);
    dispatch(changeField(newLecture.toJS()));
  };

  return (
    <>
      <FormWrap ClassName={'formWrap'} form={lecture}>
        <FormGroup row>
          <Label sm={3}>강의 일자</Label>
          <Col sm={4}>
            <DatePicker
              selected={lectureDate}
              disabled={lecture.courseStatus === 'Confirmed' ? 'disabled' : ''}
              customInput={<Input type="text" />}
              onChange={(time) => {
                changeFields({
                  name: 'lectureDate',
                  value: format(time, 'yyyyMMdd'),
                });

                setLectureDate(time);
              }}
            />
          </Col>
          <Col sm={5}>
            <Input
              type="select"
              disabled="disabled"
              name={'lectureWeek'}
              value={lecture.lectureWeek}
              onChange={onChange}
            >
              <option value="Monday">월요일</option>
              <option value="Tuesday">화요일</option>
              <option value="Wednesday">수요일</option>
              <option value="Thursday">목요일</option>
              <option value="Friday">금요일</option>
              <option value="Saturday">토요일</option>
              <option value="Sunday">일요일</option>
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>시간</Label>
          <Col sm={4}>
            <Input
              type="text"
              disabled={lecture.courseStatus === 'Confirmed' ? 'disabled' : ''}
              value={startTime}
              onBlur={(e) => {
                onLectureTimeBlur(e.target.value, 'startHour', 'startMinute');
              }}
              onChange={(e) => {
                setStartTime(formatTimeInput(e.target.value, startTime));
              }}
            />
          </Col>
          <Label sm={1}>~</Label>
          <Col sm={4}>
            <Input
              type="text"
              disabled={lecture.courseStatus === 'Confirmed' ? 'disabled' : ''}
              value={endTime}
              onBlur={(e) => {
                onLectureTimeBlur(e.target.value, 'endHour', 'endMinute');
              }}
              onChange={(e) => {
                setEndTime(formatTimeInput(e.target.value, endTime));
              }}
            />
          </Col>
        </FormGroup>
        {formType !== 'NEW_TYPE' && lecture.courseStatus === 'Confirmed' ? (
          <>
            <FormGroup row>
              <Label sm={3}>휴강 여부</Label>
              <Col sm={9}>
                <Label check sm>
                  <Input
                    type="checkbox"
                    name={'cancelled'}
                    value={lecture.cancelled}
                    checked={lecture.cancelled}
                    onChange={changeChecked}
                  />
                  휴강
                </Label>
              </Col>
            </FormGroup>

            {lecture.cancelled ? (
              <>
                <FormGroup row>
                  <Label sm={3}>보강 일자</Label>
                  <Col sm={4}>
                    <DatePicker
                      selected={supplementaryDate}
                      customInput={<Input type="text" />}
                      onChange={(time) => {
                        changeFields({
                          name: 'supplementaryLecture.lectureDate',
                          value: format(time, 'yyyyMMdd'),
                        });

                        setSupplementaryDate(time);
                      }}
                    />
                  </Col>
                  <Col sm={5}>
                    <Input
                      type="select"
                      name={'supplementaryLecture.lectureWeek'}
                      disabled="disabled"
                      value={lecture.supplementaryLecture.lectureWeek}
                      onChange={onChange}
                    >
                      <option value="Monday">월요일</option>
                      <option value="Tuesday">화요일</option>
                      <option value="Wednesday">수요일</option>
                      <option value="Thursday">목요일</option>
                      <option value="Friday">금요일</option>
                      <option value="Saturday">토요일</option>
                      <option value="Sunday">일요일</option>
                    </Input>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Label sm={3}>보강 시간</Label>
                  <Col sm={4}>
                    <Input
                      type="text"
                      value={supplementaryStartTime}
                      onBlur={(e) => {
                        onSupplementaryLectureTimeBlur(
                          e.target.value,
                          'startHour',
                          'startMinute',
                        );
                      }}
                      onChange={(e) => {
                        setSupplementaryStartTime(
                          formatTimeInput(
                            e.target.value,
                            supplementaryStartTime,
                          ),
                        );
                      }}
                    />
                  </Col>
                  <Label sm={1}>~</Label>
                  <Col sm={4}>
                    <Input
                      type="text"
                      value={supplementaryEndTime}
                      onBlur={(e) => {
                        onSupplementaryLectureTimeBlur(
                          e.target.value,
                          'endHour',
                          'endMinute',
                        );
                      }}
                      onChange={(e) => {
                        setSupplementaryEndTime(
                          formatTimeInput(e.target.value, supplementaryEndTime),
                        );
                      }}
                    />
                  </Col>
                </FormGroup>
              </>
            ) : null}
          </>
        ) : null}
      </FormWrap>

      <FooterButtonGroup
        buttonData={{
          isHistoryBack: false,
          data: buttonData[formType],
        }}
      />
    </>
  );
};

export default LectureForm;
