import React from 'react';
import Styled from 'styled-components';
/*
    jsonData로 버튼 생성
    buttonData = {
        isHistoryBack: false,
        data :[
            {label: text, type: "success", onClickFn: fn()}
            ,{label: text, onclick: fn()}
        ]
    }

    type = ['primary','secondary','success','info','warning','danger','link']
    isHistoryBack : true - 왼쪽에 목록으로 버튼 유무
 */

const FooterButtonGroupWrapper = Styled.div`
    padding: 12px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    
    .leftGroup .rightGroup{
        text-align: center;
        align-items: center;
    }
    .rightGroup{
        button{
        margin-left:12px;
        }
    }
`;

const FooterButtonGroup = ({ history, buttonData }) => {
  return (
    <FooterButtonGroupWrapper>
      <div className={'leftGroup'}>
        {buttonData.isHistoryBack ? (
          <button className={'btn btn-secondary'} color={'secondary'} onClick={history.goBack}>
            뒤로
          </button>
        ) : null}
      </div>
      <div className={'rightGroup'}>
        {Object.values(buttonData.data).map((btn, index) => {
          return (
            <button
              key={'FooterButton' + index}
              className={'btn btn-' + btn.type}
              color={btn.type}
              onClick={btn.onClickFn}
            >
              {btn.label}
            </button>
          );
        })}
      </div>
    </FooterButtonGroupWrapper>
  );
};

export default FooterButtonGroup;
