import React, { useEffect, useState } from 'react';
import ContentWrap from '../../components/layout/ContentWrap';
import PagingDataTable from '../../components/Table/PagingDataTable';
import { useDispatch, useSelector } from 'react-redux';
import { convertOpeningTime, formatDate, weekDetailKr } from '../../lib/utils/format';
import { clearLectureList, findLectureList, setSelectedRowDatas } from '../../modules/lectures';
import { useAlert } from 'react-alert';
import ModalPopup from '../../components/layout/ModalPopup';
import LectureForm from './LectureForm';

const LectureList = ({ size }) => {
  const dispatch = useDispatch();
  const { lectures, lecture, coursesSelect, course, error, loading } = useSelector(
    ({ lectures, lecture, coursesSelect, course, error, loading }) => ({
      lectures: lectures,
      lecture: lecture,
      coursesSelect: coursesSelect,
      course: course,
      error: error,
      loading: loading['course/FIND_LECTURE_LIST'],
    }),
  );
  const [selectRowIndex, setSelectRowIndex] = useState(-1);
  const [lectureDetailModal, setLectureDetailModal] = useState(false);
  const [formType, setFormType] = useState('NEW_TYPE');

  // TODO: 리팩토링 대상, 중복제어 어찌할지 고민이 필요....
  useEffect(() => {
    if (error) {
      const status = error.response.status;
      let errorMessage = '';
      if (status == 409 && error.response.data) {
        errorMessage = error.response.data.message;
      }

      errorMessage = errorMessage || '처리 중 알 수 없는 오류가 발생하였습니다.';
      alert.error(errorMessage);
    }
  }, [error]);

  const alert = useAlert();

  const columns = React.useMemo(
    () => [
      {
        Header: 'hidden',
        accessor: 'lectureId',
        style: {
          display: 'none',
        },
      },
      {
        Header: '차시',
        className: 'c',
        style: {
          width: '65px',
        },
        Cell: (props) => {
          return <div>{props.row.index + 1}</div>;
        },
      },
      {
        Header: '강의 일자',
        accessor: 'lectureDate',
        className: 'c',
        style: {
          width: '100px',
        },
        Cell: (props) => <div> {formatDate(props.value)} </div>,
      },
      {
        Header: '강의 요일',
        accessor: 'lectureWeek',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {props.value ? weekDetailKr(props.value) : null} </div>,
      },
      {
        Header: '강의 시간',
        accessor: 'lectureTime',
        className: 'c',
        style: {
          width: '130px',
        },
        Cell: (props) => <div> {props.value ? convertOpeningTime(props.value) : null} </div>,
      },
      {
        Header: '상태',
        accessor: 'cancelled',
        className: 'c',
        Cell: (props) => <div> {props.value ? '휴강' : null} </div>,
      },
      {
        Header: '보강 일자',
        accessor: 'supplementaryLecture.lectureDate',
        className: 'c',
        style: {
          width: '100px',
        },
        Cell: (props) => <div> {props.value ? props.value : null} </div>,
      },
      {
        Header: '보강 요일',
        accessor: 'supplementaryLecture.lectureWeek',
        className: 'c',
        style: {
          width: '80px',
        },
        Cell: (props) => <div> {props.value ? weekDetailKr(props.value) : null} </div>,
      },
      {
        Header: '보강 시간',
        accessor: 'supplementaryLecture.lectureTime',
        className: 'c',
        style: {
          width: '130px',
        },
        Cell: (props) => <div>{props.value ? convertOpeningTime(props.value) : null}</div>,
      },
    ],
    [],
  );

  useEffect(() => {
    return () => {
      dispatch(clearLectureList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (coursesSelect.selectedDatas.courseId) {
      dispatch(findLectureList(lectures.offset, lectures.limit, coursesSelect.selectedDatas.courseId));
    } else {
      dispatch(clearLectureList());
    }
  }, [coursesSelect.selectedDatas.courseId]);

  useEffect(() => {
    if (coursesSelect.selectedDatas.courseId) {
      dispatch(findLectureList(lectures.offset, lectures.limit, coursesSelect.selectedDatas.courseId));
    }
  }, [lecture.changedTime]);

  const topButtonData = {
    size: '1',
    datas: [
      {
        color: 'secondary',
        title: '강의 일정 추가',
        onClickFn: (e) => {
          e.preventDefault();
          setLectureDetailModal(!lectureDetailModal);
          setFormType('NEW_TYPE');
        },
      },
    ],
  };

  const onRowClick = (row) => {
    return {
      onClick: (e) => {
        e.preventDefault();
        if (row.values.lectureId) {
          setLectureDetailModal(!lectureDetailModal);
          setSelectRowIndex(row.index);
          dispatch(setSelectedRowDatas(row));

          if (course.status === 'Confirmed') {
            setFormType('DETAIL_TYPE');
          } else {
            setFormType('EDIT_TYPE');
          }
        }
      },
    };
  };

  return (
    <>
      {coursesSelect && coursesSelect.selectedDatas.courseId ? (
        <ContentWrap
          size={size}
          title={'강의 일정'}
          buttonDatas={course.status === 'Unconfirmed' ? topButtonData : null}
        >
          <PagingDataTable
            columns={columns}
            data={lectures.lectures}
            selectRowIndex={selectRowIndex}
            pageIndex={0}
            getRowProps={onRowClick}
            limit={lectures.limit}
          />

          <ModalPopup
            title={
              formType === 'NEW_TYPE'
                ? '강의 일정 등록'
                : formType === 'EDIT_TYPE'
                ? '강의 일정 수정'
                : formType === 'DETAIL_TYPE'
                ? '휴강 정보 저장'
                : null
            }
            inOpen={lectureDetailModal}
            toggleFn={() => {
              setLectureDetailModal(!lectureDetailModal);
            }}
            width={500}
            hiddenBottomArea={true}
          >
            <LectureForm
              formType={formType}
              callBackFn={() => {
                setLectureDetailModal(!lectureDetailModal);
              }}
            />
          </ModalPopup>
        </ContentWrap>
      ) : (
        <></>
      )}
    </>
  );
};

export default LectureList;
