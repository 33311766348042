import React from 'react';
import PageWrap from '../../components/layout/PageWrap';
import CourseList from '../../containers/course/CourseList';
import CourseForm from '../../containers/course/CourseForm';

const LecturePage = () => {
  return (
    <PageWrap title={'강좌 목록'}>
      <CourseList size={8} />
      <CourseForm size={4} />
    </PageWrap>
  );
};

export default LecturePage;
